import React from 'react'
import Help from '../components/help'
import DashboardLayout from '../layout/layout'

const HelpWrapper = () => {
  return (
    <DashboardLayout title={"Get Help"} sideBarMenu={true}>
        <Help/>
    </DashboardLayout>
  )
}

export default HelpWrapper