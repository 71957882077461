import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import RecordMonthlyExpanses from '../components/meditationCenter/meditationPlace/recordMonthlyexpanses'
import DashboardLayout from '../layout/layout'

const RecordMonthlyExpansesWrapper = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id)
    }, [id, navigate])
    return (
        <DashboardLayout
            title={"Monthly Expenses"}
            routeTo={`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`}
            // showListIcon={true}
            listRoute={`/recordMonthlyexpanses/${selectedId?.id}/${selectedId?.activeCenterId}`}>
            <RecordMonthlyExpanses />
        </DashboardLayout>
    )
}

export default RecordMonthlyExpansesWrapper