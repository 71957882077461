import React, { useEffect, useState } from 'react'
import SelectedTimeList from '../components/meditationCenter/newMeditationCenter/timeSelection/selectedTimelist'
import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";

const TimingList = () => {
    const [route, setRoute] = useState()
    const isActiveCenter = lStorage.get("isActiveCenter");
  const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        const centerId = lStorage.get("center-id")
        const activeMenuName = lStorage.get('tabName')
        const selectedActiveCenterId = lStorage.get("selectedActiveCenterId")

        const existingTimeList = lStorage.get("medetationDateTime");
        if (existingTimeList?.length > 0 && isActiveCenter === false) {
          if (activeMenuName === "MymediCenters") {
            setRoute(`/MymediCenters`)
          }else{
            setRoute(`/meditationCenter/${centerId}`)
          }
        }
        if (isActiveCenter === true) {
            setRoute(`/meditationPlace/${centerId}/${selectedActiveCenterId}`)
            
        }
    }, [isActiveCenter])

      useEffect(() => {
        window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
      }, []);

    return (
        <DashboardLayout title={isActiveCenter ? "Add Meditation Timing" : "Meditation Timings"} 
            routeTo={route ? route : "/timeSelection"}
            isSecondaryHeader={isActiveCenter ? false : true}
            isMedTimeActive={isActiveCenter ? false : true}
            showFooter={false}
        >
            <div style={{display:'flex', justifyContent:"space-between", padding: "10px 20px 0px", }}>
            <div style={{paddingLeft:isMobile? "25px" :"40px",}}>
              <b><h5 style={{color:"rgb(44, 180, 255)"}}>Time</h5></b>
            </div>
            <div style={{paddingRight:isMobile? "0px" :"20px"}}>
              <b><h5 style={{color:"rgb(44, 180, 255)"}}>Actions</h5></b>
            </div>
          </div>
            <SelectedTimeList />
        </DashboardLayout>
    )
}

export default TimingList