import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Map from "../map";
import "../../../assets/styles/pages/meditationPlace.scss";
import { lStorage } from "../../../utils/storage";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadScript } from "@react-google-maps/api";
import config from "../../../assets/config";


const MeditationPlace = () => {
  const navigate = useNavigate();
  const [mapCoordinates, setMapCoordinates] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showRecord, setShowRecord] = useState(true);
  const [showCreation, setShowCreation] = useState(false);
  // const defaultMedetationDateTime = [];
  

  useEffect(() => {
    const positionType = lStorage.get("positionType");
    if (positionType === "CC" || positionType === "ZC") {
      setShowCreation(true);
    }

    setMapCoordinates(JSON.parse(lStorage.get("selectedCenterCoordinates")));
  }, []);

  const id = useParams();

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  const goToLocation = () => {
    lStorage.set("isActiveCenter", true);
    navigate(`/newMeditationCenter/${selectedId?.id}`);
  };

  const goToContact = () => {
    lStorage.set("isActiveCenter", true);
    navigate(`/addNewMeditationCenter/${selectedId?.id}`);
  };

  useEffect(() => {
    if(lStorage.get("successMessage") !== undefined && lStorage.get("successMessage")!==""){
      toast.success(lStorage.get("successMessage"));
      lStorage.set("successMessage","")
    }

    const defaultData = lStorage.get("formData");
    const newData = lStorage.get("formDatadraft");
    const storageData =
      Object.keys(newData).length !== 0 ? newData : defaultData;

    const currentData = moment(new Date()).format("YYYY-MM-DD");
    const activeSinceDate = storageData?.active_since;

    if (activeSinceDate > moment(currentData).format("YYYY-MM-DD")) {
      setShowRecord(false);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="meditation-place-container">
        <div>
        <LoadScript googleMapsApiKey={config.gMapKey} libraries={config.lib}>
          <Map mapCoordinates={mapCoordinates} />
          </LoadScript>
        </div>

        <div className="meditation-place-inner-container">
          <div
            className="flex-container"
            style={{ paddingBottom: !showRecord && "18px" }}
          >
            <div className="flex-container-title-container">
              <h5 className="container-title">Change</h5>
            </div>
            <div className="flex-inner-container">
              <div className="grid-item"  style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }} onClick={() => goToLocation()}>
                Location
              </div>
              <div className="grid-item"  style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }} onClick={() => goToContact()}>
                Contact
              </div>
              <div
                className="grid-item"
                style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }}
                onClick={() => {
                  lStorage.set("isActiveCenter", true);
                  navigate(`/addMeditationTiming/${selectedId?.id}`);
                }}
              >
                Timing
              </div>
            </div>
          </div>
{/*
          {showRecord && (
            <div className="flex-container">
              <div className="flex-container-title-container">
                <h5 className="container-title">Record</h5>
              </div>
              <div className="flex-inner-container">
                <div
                  className="grid-item"
                  style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/recordAttendence/${selectedId?.id}/${selectedId?.activeCenterId}`
                    )
                  }
                >
                  Attendance
                </div>
                <div
                  className="grid-item mg-left50"
                  style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/recordMeals/${selectedId?.id}/${selectedId?.activeCenterId}`
                    )
                  }
                >
                  Meals
                </div>
                <div
                  className="grid-item"
                  style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/recordMonthlyExpenses/${selectedId?.id}/${selectedId?.activeCenterId}`
                    )
                  }
                >
                  Monthly Expenses
                </div>
               
              </div>
            </div>
          )}
                */}
                 {/* <div style={{ color: 'white' }} >Timing</div> */}

          <div className="flex-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="flex-container-title-container">
                <h5 className="container-title">Coordinator</h5>
              </div>
              <div
                style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/coordinator/${selectedId?.id}`);
                  lStorage.set("isCoordinatorActive", true);
                }}
              >
                <b>VIEW</b>
              </div>
              <div
                style={{ color: "rgb(44, 180, 255)", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/addCoordinator/${selectedId?.id}`);
                  lStorage.set("isCoordinatorActive", true);
                }}
              >
                {showCreation && <b>ADD</b>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeditationPlace;
