import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NewMeditationCenterDetailsForm from '../components/meditationCenter/newMeditationCenter/addMeditation'
import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";

const NewCenterDetailsForm = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();
    const [route, setRoute] = useState()

    const isActiveCenter = lStorage.get("isActiveCenter");
    const activeCenterId = lStorage.get("selectedActiveCenterId");
    const activeMenuName = lStorage.get('tabName');

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id?.id)
    }, [id, navigate])


    useEffect(() => {
        const centerId = lStorage.get("center-id")
        const existingTimeList = lStorage.get("medetationDateTime");
        if (existingTimeList?.length > 0) {
            if (activeMenuName === "MymediCenters") {
                setRoute(`/MymediCenters`)
            }else{
                setRoute(`/meditationCenter/${centerId}`)
            }
        }
    }, [])

    const getRouteTo = () => {
        if (activeMenuName === "MymediCenters") {
        //   return "/MymediCenters";
        return isActiveCenter ?  `/meditationPlace/${selectedId}/${activeCenterId}` : route ? route : `/locationSelection/${selectedId}`;
        } 
        return isActiveCenter ?  `/meditationPlace/${selectedId}/${activeCenterId}` : route ? route : `/locationSelection/${selectedId}`
      };

    return (
        <DashboardLayout
            title={isActiveCenter ? "Edit Contact Details" : "New Meditation Place"}
            routeTo={getRouteTo()}
            isSecondaryHeader={isActiveCenter ? false : true}
            isContactActive={true}
        >
            <NewMeditationCenterDetailsForm />
        </DashboardLayout>
    )
}

export default NewCenterDetailsForm