import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/styles/pages/home.scss";
import { Modal, Button } from "react-bootstrap";
import CenterSearch from "./centerSearch";
import { getMyMeditationCenters } from "../../services/meditationCenter/centerList";
import {
    rejectCenterCreation,
    approveCenterCreation,
    getMeditationTimings,
    checkingCoordinators,
} from "../../services/meditationCenter/newMeditationCenter";
import { lStorage } from "../../utils/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../spinner";
import Pagination from "react-js-pagination";
import PrivateProperty from "../../assets/images/PrivatePlace.png";
import ResidentialPropertyImg from "../../assets/images/home-img.png";
// import DraftCardImg from "../../assets/images/draft-card-img.png";


const MyMediCenters = () => {
    const id = useParams();
    const [isTabChanged, setIsTabChanged] = useState(lStorage.get("activeTab"));

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCity, setSelectedCity] = useState("");
    const [mediCenters, setMediCenters] = useState("");
    const [activeTab, setActiveTab] = useState(
        isTabChanged ? isTabChanged : "Approved");
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);
    const [showDraftSubmit, setShowDraftSubmit] = useState(false);
    const [showDraftCancel, setShowDraftCancel] = useState(false);
    const [centerDraftId, setCenterDraftId] = useState();
    const [disableButton, setDisableButton] = useState(false);
    const [centerCount, setCenterCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isCitySelected, setIsCitySelected] = useState(false);
    const [checkCCExist, setcheckCCExist] = useState(true);

    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleCancel = () => {
        setcheckCCExist(true);
        setIsModalVisible(false);
        setIsCitySelected(false);
    }

    const handleCitySelect = (city) => {
        if(city){
            setSelectedCity(city);
            checkingCoordinators(city.c_id)
            .then((res) => {
                if(res.data.detail === true){
                    setIsCitySelected(true);
                    setcheckCCExist(true);
                }else{
                    setIsCitySelected(false);
                    setcheckCCExist(false);
                }
            });
        }else{
            setcheckCCExist(true);
        }

    };

    const handleAdd = () => {
        if (selectedCity) {
            lStorage.set("draftData", {});
            lStorage.set("draftId", "");
            lStorage.set("isActiveCenter", false);
            lStorage.set("meditationDay", []);
            lStorage.set("selectedActiveCenterId","");
            lStorage.set("formData", {});
            lStorage.set("medetationDateTime", []);
            lStorage.set("tempCityName", {});
            lStorage.set("formDatadraft", {});
            lStorage.set("center-id", selectedCity.c_id);
            navigate(`/newMeditationCenter/${selectedCity.c_id}`);
            setIsModalVisible(false);
        }
    };

    const routToSelectedActiveCenter = (item) => {
        const cityData = {
            c_id: item?.city_id?.id,
            c_name: item?.city_id?.name,
            country_id: item?.country_id?.id,
            name: item?.city_id?.name,
            state_id: item?.state_id?.id,
        };

        const obj = {};
        if (lStorage.get("draftId") === '' || lStorage.get("draftId") === undefined) {
            //lStorage.set("formData", obj);       
            // lStorage.set("cityName", obj);
            //lStorage.set("medetationDateTime", []);    
        } else {
            lStorage.set("formData", obj);
            lStorage.set("cityName", obj);
            lStorage.set("medetationDateTime", []);
            lStorage.set("formDatadraft", obj);
        }

        
        const selectedDraftId = item?.id;
        if(selectedDraftId!== '' && selectedDraftId!==undefined)
        getMeditationTimings(selectedDraftId)
        .then((res) => {
            lStorage.set("medetationDateTime", res?.data?.results);
            lStorage.set("meditationDay", res?.data?.results);
        })
        .catch((err) => {
            toast.error("Something went wrong please try again later");
        });
        
        lStorage.set("centerName", item?.srcm_group_id?.name);
        lStorage.set("cityName", cityData);
        lStorage.set("formData", item);
        lStorage.set("draftId", "");
        lStorage.set("formDatadraft", {});
        const selectedCenterLat = item?.latitude;
        const selectedCenterLong = item?.longitude;
        const selectedCenterCoordinates = { selectedCenterLat, selectedCenterLong };
        const jsonObj = JSON.stringify(selectedCenterCoordinates);
        lStorage.set("selectedCenterCoordinates", `${jsonObj}`);
        const selectedID = item?.id;
        lStorage.set("selectedActiveCenterId", `${selectedID}`);
        window.scrollTo(0, 0)
        navigate(`/meditationPlace/${item?.srcm_group_id?.id}/${item?.id}`, {
            state: { activeCenterData: item },
        });
    };

    useEffect(() => {
        setSelectedId(id?.id);
    }, [id]);

     // eslint-disable-next-line
    useEffect(() => {
        lStorage.set("positionType", "");
        lStorage.set("tabName", "MymediCenters");
        const selectedActiveTab = lStorage.get("activeTab");
        if (selectedActiveTab !== undefined || null) {
          setIsTabChanged(selectedActiveTab);
        }
        if(lStorage.get("newCenterCration") !== undefined && lStorage.get("newCenterCration")===1){
            toast.success("Meditation place created");
            lStorage.set("newCenterCration","")
            setActiveTab("Draft");
          }else if(lStorage.get("newCenterCration") !== undefined && lStorage.get("newCenterCration")===2){
            toast.success("Meditation place created and Submitted For Approval");
            setActiveTab("Pending");
            lStorage.set("newCenterCration","")
          }
    });

    useEffect(() => {
        if (isModalVisible) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
          document.body.style.overflow = 'auto';
          document.body.style.position = 'static';
        }
        return () => {
          document.body.style.overflow = 'auto';
          document.body.style.position = 'static';
        };
      }, [isModalVisible]);

    const editDraft = (item) => {
        console.log("item", item);
        lStorage.set("center-id", item?.srcm_group_id?.id);
        lStorage.set("selectedActiveCenterId", "");
        lStorage.set("isActiveCenter", false);

        const selectedDraftId = item?.id;
        const obj = {};
        lStorage.set("cityName", obj);

        lStorage.set("draftId", item?.id);
        lStorage.set("formData", item);
        lStorage.set("formDatadraft", obj);

        getMeditationTimings(selectedDraftId)
            .then((res) => {
                lStorage.set("medetationDateTime", res?.data?.results);
                navigate(`/newMeditationCenter/${item?.srcm_group_id?.id}`, {
                    state: { data: item },
                });
            })
            .catch((err) => {
                toast.error("Something went wrong please try again later");
            });
    };

    const cancelDraft = (draftCenterId) => {
        setLoading(true);
        trackDraftCancel();
        setDisableButton(true);
        rejectCenterCreation(Number(selectedId), draftCenterId)
            .then((res) => {
                setLoading(false);
                // setShowDelete(false);
                setDisableButton(false);
                toast.success("Meditation Place Cancelled");
                setCenterDraftId("");
                trackDraftCancel();
                // myCenterListCall();
                handlePageChange(currentPage);
                setActiveTab("Draft");
            })
            .catch((err) => {
                setLoading(false);
                setDisableButton(false);
                toast.error("Something went wrong please try again later");
            });
    };

    const approveDraft = (draftCenterId) => {
        setLoading(true);
        setDisableButton(true);
        trackDraftSubmit();
        approveCenterCreation(Number(selectedId), draftCenterId)
            .then((res) => {
                toast.success("Meditation Place Submitted For Approval");
                setCenterDraftId("");
                trackDraftSubmit();
                // myCenterListCall();
                setActiveTab("Pending");
                setDisableButton(false);
            })
            .catch((err) => {
                setLoading(false);
                setDisableButton(false);
                toast.error("Something went wrong please try again later");
            });
    };

    const page = 1;
    const page_size = 20;

   useEffect(() => {
        setLoading(true);
        setCurrentPage(page);
        const centerType = activeTab?.toLowerCase();
        getMyMeditationCenters(page, page_size, centerType).then((res) => {
            setMediCenters(res?.data?.results);
            setCenterCount(res?.data?.count);
            setLoading(false);
        }).catch((err) => {
            toast.error("Something went wrong please try again later");
            navigate("/home");
        });
    }, [activeTab]);

    const handlePageChange = (pageNumber) => {
        setLoading(true);
        setCurrentPage(pageNumber);
        const centerType = activeTab?.toLowerCase();
        getMyMeditationCenters(pageNumber, page_size, centerType).then((res) => {
            setMediCenters(res?.data?.results);
            setCenterCount(res?.data?.count);
            setLoading(false);
        });
    }
    const ViewPending = (item) => {
        navigate(`/ViewMyMeditationCenter/${item.id}`, {
            state: { data: item },
        });
    };

    const trackDraftSubmit = () => {
        setShowDraftSubmit(false);
    };
    const trackDraftCancel = () => {
        setShowDraftCancel(false);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      };

    const centerDataToMap = Array.isArray(mediCenters)
        ? mediCenters.filter((item) => item?.state === activeTab?.toLowerCase())
        : [];
    
    const approvedItems = centerDataToMap.filter(item => item?.state === "approved" && activeTab === "Approved");
    const pendingItems = centerDataToMap.filter(item => item?.state === "pending" && activeTab === "Pending");
    const draftItems = centerDataToMap.filter(item => item?.state === "draft" && activeTab === "Draft");

        const renderApprovedItems = () => {
            return approvedItems.length > 0 ? (
                approvedItems.map((item) => (
                    <div className="active-center-card-container" key={item.id}>
                    <div className="active-center-card-inner-container">
                      <div className="active-center-card-container-wrapper">
                        <div style={{ display: "flex" }}>
                            <div className="active-center-card-container-para-width">
                                <span>{item?.name}</span>
                                <p className="active-center-card-container-para">
                                {item?.street} {item?.street2 && `, ${item?.street2}`}{item?.street3 && `, ${item?.street3}`}{item?.postal_code && `, ${item?.postal_code}`}
                                </p>
                                <p className="active-center-card-container-para">
                                    SRCM Center - {item?.srcm_group_id?.name}
                                </p>
                            </div>
                            <div
                                className="active-center-navigate-arrow"
                                onClick={() =>
                                    routToSelectedActiveCenter(item)
                                }
                                style={{ marginLeft: "auto" }}
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-chevron-right"
                                    viewBox="0 0 16 16"
                                    style={{ cursor: "pointer" }}
                                >
                                    <path
                                    fill-rule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="active-center-card-container-wrapper-first">
                          <div className="active-center-card-icon-container">
                            <img
                              src={
                                item.property_type === "residential"
                                  ? ResidentialPropertyImg
                                  : PrivateProperty
                              }
                              alt="PrivateProperty"
                              className="card-icon-new"
                            />
                          </div>

                          <div style={{ color: "#808688" }}>
                            <p style={{marginTop: "1em", marginBottom: "0em" }}>Contact Person : {item.contact_person_name}</p>
                            <p>Create Date : {formatDate(item.create_date)}</p>
                          </div>
                          <div className="status-container" style={{ marginTop: "40px" }}>
                            <h6
                              style={{
                                color:
                                  item.active === true
                                    ? "#8ccb47"
                                    : "#d41a33",
                              }}
                            >
                              <b>
                                {item.active === true
                                  ? "Active"
                                  : "Inactive"}
                              </b>{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
                <div className="add-timing-main-container">
                    <div className="add-timing-inner-container">
                        <div className="add-timing-container">
                            <p style={{ fontSize: "16px" }}>No Result Found</p>
                        </div>
                    </div>
                </div>
            );
        };

        const renderPendingItems = () => {
            return pendingItems.length > 0 ? (
                pendingItems.map((item) => (
                     <div className="draft-main-container"  key={item.id}>
                     <div className="draft-card-container">
                       <div className="active-center-card-container-para-width">
                         <span>{item?.name}</span>
                         <p className="active-center-card-container-para">
                         {item?.street} {item?.street2 && `, ${item?.street2}`}{item?.street3 && `, ${item?.street3}`}{item?.postal_code && `, ${item?.postal_code}`}
                         </p>
                         <p className="active-center-card-container-para">
                            SRCM Center - {item?.srcm_group_id?.name}
                        </p>
                       </div>
                       <div className="draft-card-inner-container-box">
                         <div className="draft-inner-card-img-container">
                           <div>
                             <img
                               // src={DraftCardImg}
                               src={
                                 item.property_type === "residential"
                                   ? ResidentialPropertyImg
                                   : PrivateProperty
                               }
                               alt="draft"
                               className="draft-card-png"
                             />
                            <div style={{ color: "#808688" }}>
                                <p style={{ margin: "0px"}}>Contact Person : {item.contact_person_name}</p>
                                <p style={{ margin: "0px"}}>Create Date : {formatDate(item.create_date)}</p>
                            </div>
                           </div>
                         </div>
                         <div className="draft-cancel-approve-container">
                            <div title="View" onClick={() => ViewPending(item)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16" style={{ color: "#2cb4ff", marginRight: "10px", cursor: "pointer" }}>
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                            </div>
                         </div>
                       </div>
                     </div>
                   </div>
                ))
            ) : (
                <div className="add-timing-main-container">
                    <div className="add-timing-inner-container">
                    {!loading && (
                        <div className="add-timing-container">
                            <p style={{ fontSize: "16px" }}>No Result Found</p>
                        </div>
                    )}
                    </div>
                </div>
            );
        };

        const renderDraftItems = () => {
            return draftItems.length > 0 ? (
                draftItems.map((item) => {
                    const draftCenterId = item?.id;
                    const groupId = item?.srcm_group_id?.id;
                    return (
                        <div className="draft-main-container" key={item.id}>
                        <div className="draft-card-container">
                          <div  className="active-center-card-container-para-width" style={{ marginTop: "10px" }}>
                            <span>{item?.name}</span>
                            <p className="active-center-card-container-para">
                            {item?.street} {item?.street2 && `, ${item?.street2}`}{item?.street3 && `, ${item?.street3}`}{item?.postal_code && `, ${item?.postal_code}`}
                            </p>
                            <p className="active-center-card-container-para">
                            SRCM Center - {item?.srcm_group_id?.name}
                            </p>
                          </div>
                          <div className="draft-card-inner-container-box">
                            <div className="draft-inner-card-img-container">
                              <div>
                                <img
                                   src={
                                    item.property_type === "residential"
                                      ? ResidentialPropertyImg
                                      : PrivateProperty
                                  }
                                  alt="draft"
                                  className="draft-card-png"
                                />
                                <div style={{ color: "#808688" }}>
                                <p style={{ margin: "0px"}}>Contact Person : {item.contact_person_name}</p>
                                <p style={{ margin: "0px"}}>Create Date : {formatDate(item.create_date)}</p>
                                </div>
                              </div>
                            </div>
                            <div className="draft-cancel-approve-container">
                            <div title="Edit" onClick={() => editDraft(item)} >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    className="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                    style={{
                                      color: "#2cb4ff",
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                            </div>
                            <div title="Submit Draft" onClick={() => { setCenterDraftId(draftCenterId); setSelectedId(groupId); setShowDraftSubmit(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16" style={{ color: "#69f493", marginRight: "10px", cursor: "pointer" }}>
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                </svg>
                            </div>
                            <div title="Cancel Draft" onClick={() => { setCenterDraftId(draftCenterId); setSelectedId(groupId); setShowDraftCancel(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16" style={{ color: "#f24d40", cursor: "pointer" }}>
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </div>
                            </div>
                          </div>
                        </div>
                        {item?.remark && (
                          <p>
                            <b>Reason of Rejection : {item?.remark}</b>
                          </p>
                        )}
                      </div>
                    );
                })
            ) : (
                <div className="add-timing-main-container">
                    <div className="add-timing-inner-container">
                        <div className="add-timing-container">
                            <p style={{ fontSize: "16px" }}>No Result Found</p>
                        </div>
                    </div>
                </div>
            );
        };

    return (
        <>
         <ToastContainer />
            {loading && <Spinner />}
            {!loading && (
                <>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "16px 30px",
                        }}
                    >
                        <div
                            style={{
                                padding: "0.5em",
                                borderRadius: "5px",
                                border:
                                    activeTab === "Approved"
                                        ? "3px solid rgb(44, 180, 255)"
                                        : "3px solid #808688",
                                color: activeTab === "Approved" ? "rgb(44, 180, 255)" : "black",
                                cursor: "pointer",
                                margin: "0px 10px 0px 0px"
                            }}
                            onClick={() => {
                                setActiveTab("Approved");
                                lStorage.set("activeTab", "Approved");
                            }}
                        >
                            <span>
                                <b>Active</b>
                            </span>
                        </div>

                        <div
                            style={{
                                padding: "0.5em",
                                borderRadius: "5px",
                                border:
                                    activeTab === "Pending"
                                        ? "3px solid rgb(44, 180, 255)"
                                        : "3px solid #808688",
                                color:
                                    activeTab === "Pending" ? "rgb(44, 180, 255)" : "black",
                                cursor: "pointer",
                                margin: "0px 10px 0px 0px"
                            }}
                            onClick={() => {
                                setActiveTab("Pending");
                                lStorage.set("activeTab", "Pending");
                            }}
                        >
                            {" "}
                            <span>
                                <b>Pending Approval</b>
                            </span>
                        </div>
                        <div
                            style={{
                                padding: "0.5em",
                                borderRadius: "5px",
                                border:
                                    activeTab === "Draft"
                                        ? "3px solid rgb(44, 180, 255)"
                                        : "3px solid #808688",
                                color: activeTab === "Draft" ? "rgb(44, 180, 255)" : "black",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setActiveTab("Draft");
                                lStorage.set("activeTab", "Draft");
                            }}
                        >
                            <span>
                                <b>Draft</b>
                            </span>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            style={{
                                padding: "0.5em",
                                borderRadius: "5px",
                                border: "3px solid rgb(0 163 253)",
                                color: "#fff",
                                cursor: "pointer",
                                margin: "0px 10px 0px 0px",
                                fontWeight: 700,
                                backgroundColor:"#2cb4ff"
                            }}
                            onClick={showModal}>
                            Create Meditation Place
                        </button>
                    </div>       
                    <div className="main-wrapper-div">
                        <div className="main-div">
                        <div className="main-container">
                            {activeTab === "Approved" && renderApprovedItems()}
                            {activeTab === "Pending" && renderPendingItems()}
                            {activeTab === "Draft" && renderDraftItems()}
                        </div>
                        <div>
                  <div className="mt-1 mb-3" style={{ textAlign:"center"}}>
                    <b>Current Page : </b>
                    <span className="ml-2">{currentPage}/{Math.ceil(centerCount/page_size)}</span>
                  </div>

                  <div className="mg-left-50" style={{display:"flex", justifyContent:"center"}}>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={page_size}
                      totalItemsCount={centerCount}
                      pageRangeDisplayed={6}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
                        </div>
                    </div>
                   
                </>
            )}

            <Modal show={isModalVisible} centered
                dialogClassName={isModalVisible ? "custom-modal" : ""}
            >
                <Modal.Header>
                <Modal.Title>Search Center</Modal.Title>
                <Button variant="close" onClick={handleCancel} aria-label="Close" />
                </Modal.Header>
                <Modal.Body>
                <CenterSearch
                    onCitySelect={handleCitySelect}
                    onSearchValueChange={(ev) => {
                        setcheckCCExist(true);
                    }}
                />
                {!checkCCExist && (
                <span style={{ color: "red", fontSize: "12px" }}>
                    A Coordinator has not been assigned to this location. Please contact IT Help at <a href="mailto:it.help@heartfulness.org">it.help@heartfulness.org</a> with the necessary details to resolve this.
                </span>
                )}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAdd} disabled={!isCitySelected} >Proceed</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDraftCancel} centered>
                <Modal.Header>
                    <Modal.Title>Cancel Meditation Place</Modal.Title>
                    <Button variant="close" onClick={trackDraftCancel} aria-label="Close" />
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="impul-label">Are you sure, you want to Cancel ?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="whiteColor"
                        style={{ color: "black" }}
                        onClick={trackDraftCancel}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary"
                        disabled={disableButton} onClick={() => cancelDraft(centerDraftId)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDraftSubmit} centered>
                <Modal.Header>
                    <Modal.Title>Submit Meditation Place</Modal.Title>
                    <Button variant="close" onClick={trackDraftSubmit} aria-label="Close" />
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="impul-label">Are you sure, you want to Submit ?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="whiteColor"
                        style={{ color: "black" }}
                        onClick={trackDraftSubmit}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={disableButton} onClick={() => approveDraft(centerDraftId)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default MyMediCenters;
