import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "antd";
import '../../../../assets/styles/pages/addWebsiteDetails.scss'
import { lStorage } from "../../../../utils/storage";

const { TextArea } = Input;

const AddWebsiteDetails = () => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)

  const getDefaultValue = () => {
    const updatedData = lStorage.get('formData');
    return {
      web_about: updatedData ? updatedData?.web_about : "",
      directions: updatedData ? updatedData?.directions : "",
      facilities: updatedData ? updatedData?.facilities : "facilities",
    }
  }

  const { control, handleSubmit } = useForm({
    defaultValues: getDefaultValue()
  });

  const requiredFields = [
    "directions",
  ]

  const inlineErrorValidation = (fieldName) => {
    setErrors(errors?.filter((errorField) => errorField !== fieldName))
  }
  const validateData = (data) => {
    const errors = requiredFields?.filter(reqField => !Boolean(data[reqField]))
    setErrors(errors)
    return errors
  }

  const onSubmit = (data) => {
    setIsSubmit(true);
    if (validateData(data)?.length === 0) {
      const localStoreData = lStorage.get("formData");
      const CombinedFormData = { ...data, ...localStoreData };
      const jsonObj = CombinedFormData;
      lStorage.set("formData", jsonObj);
      navigate(`/addMeditationTiming/${selectedId}`);
    }
  };

  const [selectedId, setSelectedId] = useState();

  const id = useParams()

  useEffect(() => {
    setSelectedId(id?.id)
  }, [id])

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="imput-field-container">
          <p className="impul-label">About the place</p>
          <Controller
            name="web_about"
            control={control}
            render={({ field }) => <TextArea {...field} placeholder="" />}
          />
        </div>
        <div className="imput-field-container">
          <p className="impul-label" style={{ display: 'inline-block' }}>Direction</p>{errors?.indexOf('directions') > -1 && isSubmit && <span style={{ color: 'red', paddingLeft: "5px" }}> * Field Required</span>}
          <Controller
            name="directions"
            control={control}
            render={({ field }) => <TextArea {...field} onKeyDown={() => inlineErrorValidation('directions')} placeholder="" />}
          />
        </div>
        <div className="imput-field-container">
          <p className="impul-label">Facilities</p>
          <Controller
            name="facilities"
            control={control}
            render={({ field }) => <TextArea {...field} placeholder="" />}
          />
        </div>
        <div className="button-div-container-website button-div-container-website-details">
          <div onClick={() => navigate(`/addPropertyDetails/${selectedId}`)}>
            <button className="next-button">
              <b>Previous</b>
            </button>
          </div>
          <div type="submit">
            <button className="next-button">
              <b>Next</b>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddWebsiteDetails;
