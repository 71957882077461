import React, { useState, useEffect } from "react";
import {
  getRecordAttendenceList,
  editSelectedAttendance,
} from "../../../../../services/meditationCenter/recordAttendence";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import '../../../../../assets/styles/pages/attendanceList.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { lStorage } from "../../../../../utils/storage";
import Spinner from '../../../../spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { Input } from "antd";
import Pagination from "react-js-pagination";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import "../../../../../assets/styles/pages/timeSelection.scss";

const AttendanceList = ({ setIsEdit }) => {
  const navigate = useNavigate();
  const { Option } = Select;

  const page = 1;
  const page_size = 10;
  // const requiredFields = ["count_not_scanned"];
  const [show, setShow] = useState(true);
  const [recordAttendenceListData, setRecordAttendenceListData] = useState();
  const [selectedAttendance, setSelectedAttendance] = useState();
  const [time, setTime] = useState();
  // const [showDatePicker, setShowDatePicker] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(true);
  const [day, setDay] = useState(moment(new Date()).format('ddd').toLocaleLowerCase())
  const [meditationTimings, setMeditationTimings] = useState()
  const [dropdownOption, setDropdownOption] = useState()
  const [SelectedTime, setSelectedTime] = useState()
  const [isDropDownSelected, setIsDropDownSelected] = useState(false)
  const [attendanceTimeSlected, setAttendanceTimeSlected] = useState();
  const [isDateSelected, setIsDateSelected] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [listCount , setListCount] = useState();
  const [emptyError, setEmptyError] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [manualValue, setManualValue] = useState(moment(new Date()));
  const [manualSelection, setManualSelection] = useState(false);
  const [changeHandler, setChangeHandler] = useState();
  const [attendanceVal, setAttendanceVal] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const recordPerPage = 10;

  const totalRecords = listCount;

  const pageRange = Math.ceil(totalRecords / 10);

  // const validateData = (data) => {
  //   const emptyError = requiredFields?.filter(
  //     (reqField) => !Boolean(data[reqField])
  //   );
  //   setEmptyError(emptyError);
  //   return emptyError;
  // };
  const callAttendanceList = () =>{    
    setDisableButton(false);
    getRecordAttendenceList(page, page_size, selectedId?.activeCenterId).then((res) => {
      setLoading(false);
      setRecordAttendenceListData(res?.data?.results);
      setListCount(res?.data?.count)
      if (res?.data?.count === 0) {
      //  toast.error("No Data Found")
        setIsEmpty(true);
      }
    }).catch((err) => {
      setLoading(false);
      toast.error("Something went please try again later");
      setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
    });
  }

  useEffect(() => {
    if (selectedAttendance !== undefined) {
      setDay(moment(selectedAttendance?.meditation_datetime).format("ddd").toLocaleLowerCase());
    }
  }, [selectedAttendance])

  const medCenterId = selectedAttendance;
  const { control, handleSubmit ,formState: { errors },} = useForm({
    defaultValues: {
      meditation_center_id: "",
      meditation_datetime: "",
      count_not_scanned: selectedAttendance?.count_not_scanned,

    },
  });
  useEffect(() => {
    setDay(moment(new Date()).format('ddd').toLocaleLowerCase())
  }, [])

  useEffect(() => {
    if (isDropDownSelected === false) {
      setAttendanceTimeSlected(new moment(moment(new Date()).format("YYYY:MM:DD") + "T" + new Date().toLocaleTimeString(),"YYYY-MM-DDTHH:mm").utc()?._d)
    }
    if (isDropDownSelected === true) {
      setAttendanceTimeSlected(new moment(moment(time).format("YYYY:MM:DD") + "T" + SelectedTime, "YYYY-MM-DDTHH:mm").utc()?._d)
    }
    if (isDateSelected === true && isDropDownSelected === false) {
      setAttendanceTimeSlected(new moment(moment(time).format("YYYY:MM:DD") + "T" + moment(selectedAttendance?.meditation_datetime).format("ddd HH:mm A"), "YYYY-MM-DDTHH:mm").utc()?._d)
    }
    if (isDateSelected === false && isDropDownSelected === false) {
      setAttendanceTimeSlected(selectedDateTime)
    }
  }, [isDropDownSelected, SelectedTime, time, isDateSelected, selectedDateTime, selectedAttendance])

  
  const onSubmit = (data) => {
    setIsSubmit(true);
    const utcStart = attendanceTimeSlected;
    setDisableButton(true);
    const payloadToSend = {
      count_not_scanned : attendanceVal ? attendanceVal : selectedAttendance?.count_not_scanned ,
      meditation_datetime: utcStart,
      meditation_center_id: medCenterId?.meditation_center_id,
    }
   
     var pattern = new RegExp(/^[1-9][0-9]*$/);
     if (attendanceVal === undefined || attendanceVal<=0) {
              toast.error("Please enter attendance count")
              setDisableButton(false);
              
            } else {
      
              if (!pattern.test(attendanceVal)) {
      toast.error("Please enter valid attendance count")
                setDisableButton(false);
    //  setEmptyError(true);
  }else{
    setLoading(true);
    editSelectedAttendance(payloadToSend, selectedAttendance?.id).then((res) => {
      setIsEdit(false)
      toast.success("Record Updated")
      setTimeout(() => setLoading(false), 2000);
      callAttendanceList()
    }).catch((err) => {
      toast.error("Something went please try again later")
      setDisableButton(false);
      setTimeout(() => setLoading(false), 2000);
    });
    setShow(true);
  }
}
  };


  useEffect(() => {
    if (selectedId) {
      getRecordAttendenceList(page, page_size, selectedId?.activeCenterId).then((res) => {
        setLoading(false);
        setRecordAttendenceListData(res?.data?.results);
        setListCount(res?.data?.count)
        if (res?.data?.count === 0) {
         // toast.error("No Data Found")
          setIsEmpty(true);
        }
      }).catch((err) => {
        setLoading(false);
        toast.error("Something went please try again later");
        setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
      });
    }
  },
   [selectedId, navigate],
  [currentPage]
  );

  const editAttendance = (item) => {
    setSelectedDateTime(new moment(moment(item?.meditation_datetime).format("YYYY-MM-DD") + "T" + moment(item?.meditation_datetime).format("HH:mm A"), "YYYY-MM-DDTHH:mm").utc()?._d)
    setIsEdit(true);
    setSelectedAttendance(item);
    setAttendanceVal(item.count_not_scanned);
    setTime((new moment(moment(item?.meditation_datetime).format("YYYY-MM-DD") + "T" + moment(item?.meditation_datetime).format("HH:mm A"), "YYYY-MM-DDTHH:mm").utc()?._d));
    setShow(false);
  };

  useEffect(() => {
    const SelectedAttendanceId = selectedAttendance?.id;
    lStorage.set("SelectedAttendanceId", `${SelectedAttendanceId}`);
  }, [selectedAttendance]);

  // const datePicker = () => {
  //   setShowDatePicker(true);
  // };

  const id = useParams()

  useEffect(() => {
    setSelectedId(id)
  }, [id])

  useEffect(() => {
    setMeditationTimings(lStorage.get("medetationDateTime"));
  }, [])

  const handleChange = (value) => {
    setIsDropDownSelected(true);
    setChangeHandler(value);
  };

  const options = dropdownOption?.map((d) => {
    return (
      <Option key={d.meditation_time}
      >
        {`${d?.day_of_week + " " + moment(new moment(moment(new Date()).format("YYYY:MM:DD") + "T" + d?.meditation_time, "YYYY-MM-DDThh:mm").utc()?._d).format("hh:mm A")}`}
      </Option>
    )
  });

  useEffect(() => {
    var sorted = {};
    for (var i = 0, max = meditationTimings?.length; i < max; i++) {
      if (sorted[meditationTimings[i]?.day_of_week] === undefined) {
        sorted[meditationTimings[i]?.day_of_week] = [];
      }
      sorted[meditationTimings[i]?.day_of_week]?.push(meditationTimings[i]);
    }
    setDropdownOption(sorted[day])
  }, [day, meditationTimings])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getRecordAttendenceList(pageNumber, page_size, selectedId?.activeCenterId).then((res) => {
      setLoading(false);
      setRecordAttendenceListData(res?.data?.results);
      setListCount(res?.data?.count)
      if (res?.data?.count === 0) {
      //  toast.error("No Data Found")
        setIsEmpty(true);
      }
    }).catch((err) => {
      setLoading(false);
      toast.error("Something went please try again later");
      setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
    });
  };

  const inlineErrorValidation = (fieldName) => {
    setEmptyError(emptyError?.filter((errorField) => errorField !== fieldName));
  };


  useEffect(() => {
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, [attendanceVal, selectedAttendance]);

  useEffect(() => {
    const timedDropdownValue = changeHandler?.value;
    const manualTimeValue = moment(manualValue).format("HH:mm:ss");
    var selectedTimeValue = timedDropdownValue !== "other" ? manualTimeValue : timedDropdownValue;
    if (timedDropdownValue === "other") {
      selectedTimeValue = manualTimeValue;
    } else if (timedDropdownValue !== "other") {
      selectedTimeValue = timedDropdownValue;
    }

    if (changeHandler?.key === "other") {
      setManualSelection(true);
    } else if (changeHandler?.key !== "other") {
      setManualSelection(false);
    }
    // setIsDropDownSelected(true);
    setSelectedTime(
      moment(
        new moment(
          moment(new Date()).format("YYYY:MM:DD") + "T" + selectedTimeValue,
          "YYYY-MM-DDTHH:mm"
        ).utc()?._d
      ).format("HH:mm A")
    );
  }, [changeHandler, manualValue]);

const editDate = new moment(moment(moment(selectedAttendance?.meditation_datetime).format("DD-MMM-YYYY")).format("YYYY:MM:DD") + "T" + new Date().toLocaleTimeString(),"YYYY-MM-DDTHH:mm").utc()?._d

  return (
    <>
      <ToastContainer />

      {loading && <Spinner />}
      {!loading && (
        <>
          {isEmpty && (
            <div className="add-timing-main-container">
              <div className="add-timing-inner-container">
                <div className="add-timing-container">
                  <p style={{ fontSize: "16px" }}>No Records Found</p>
                </div>
              </div>
            </div>
          )}
          {!isEmpty && (
            <div>
              {show && (
                <>
                  <div className="attendance-list-container">
                    <h5 className="attendance-list-title">Recorded</h5>

                    {recordAttendenceListData?.map((item) => {
                      const attendanceTime = moment(
                        item?.meditation_datetime
                      ).format("DD MMM YYYY hh:mm A");
                      return (
                        <div className="attendance-list-inner-container">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h6>{attendanceTime}</h6>
                            </div>
                            <div>
                              {" "}
                              <div className="pencil-icon-container"
                                title="Edit Attendance"
                              >
                                <span onClick={() => editAttendance(item)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="rgb(44, 180, 255)"
                                    class="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                          <p style={{ color: "#a1a4a7" }}>
                            {" "}
                            Attendance Count: {item?.count_not_scanned}
                          </p>
                        </div>
                      );
                    })}
                    <div>
                      <div className="mt-1 mb-3" style={{ textAlign:"center"}}>
                        <b>Current Page : </b>
                        <span className="ml-2">{currentPage}/{Math.ceil(totalRecords/recordPerPage)}</span>
                      </div>

                      <div className="mg-left-50" style={{display:"flex", justifyContent:"center"}}>
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPage}
                          itemsCountPerPage={recordPerPage}
                          totalItemsCount={totalRecords}
                          pageRangeDisplayed={pageRange}
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                    <div
                      className="attendance-list-container-add-icon"
                      onClick={() =>
                        navigate(
                          `/recordAttendence/${selectedId?.id}/${selectedId?.activeCenterId}`
                        )
                      }
                      title="Add New Attendance"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="#2cb4ff"
                        class="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>
                    </div>
                  </div>
                </>
              )}
              {!show && (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="second-main-container">
                      <p className="para-tag">RECORDING ATTENDANCE ON</p>
                      <div className="dt-picker">
                        <div className="dt-picker-inner-container">
                          <div className="date-picker-wrapper-edit-attendance">
                            {/* {!showDatePicker && (
                              <div
                                onClick={() => datePicker()}
                                style={{ borderBottom: "1px solid #ccc" }}
                              >
                                {moment(
                                  selectedAttendance?.meditation_datetime
                                ).format("DD-MMM-YYYY")}
                              </div>
                            )} */}
                            {/* {showDatePicker && ( */}
                              <DatePicker
                                wrapperClassName="datepicker"
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                maxDate={new Date()}
                                onChange={(date) => {
                                  setIsDateSelected(true);

                                  setTime(new Date(date));
                                  setDay(
                                    moment(date)
                                      .format("ddd")
                                      .toLocaleLowerCase()
                                  );
                                }}
                                selected={time ? time : editDate || new Date()}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      <div className="test-className">
                        <p className="para-tag"  style={{ display: "inline-block" }}>ATTENDANCE COUNT <span style={{ color: "red"}}>*</span></p>
                        {emptyError?.indexOf("count_not_scanned") > -1 && isSubmit && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {" "}
                    Field Required
                  </span>
                )}
                {errors?.count_not_scanned?.type === "pattern" && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {errors?.count_not_scanned?.message}
                  </span>
                )}
                                {/* {errors?.count_not_scanned?.type === "required" && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {errors?.count_not_scanned?.message}
                  </span>
                )} */}
                        <Controller
                          name="count_not_scanned"
                          control={control}
                          rules={{
                            // required: {
                            //   value: true,
                            //   message: " * Field Required",
                            // } ,
                            pattern: {
                              value: /^[1-9][0-9]*$/,
                              message: "Numeric values only",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field.field}
                              // placeholder={
                              //   selectedAttendance
                              //     ? selectedAttendance?.count_not_scanned
                              //     : "Select Timing"
                              // }
                              defaultValue={selectedAttendance
                                ? selectedAttendance?.count_not_scanned
                                : attendanceVal}
                              // value="56"
                              style={{
                                borderBottom: "1px solid lightgray",
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                              }}
                              // id="inputID"
                              autoComplete="nope"
                              onChange={(ev) => setAttendanceVal(ev.target.value)}
                              onKeyDown={() =>
                                inlineErrorValidation("count_not_scanned")
                              }
                            />
                          )}
                        />
                      </div>

                      <div
                        className="input-field-container"
                        style={{ marginTop: "15px", color: "#8d7a7a" }}
                      >
                        <p className="input-label">SELECT TIMING</p>
                        <Select
                          labelInValue
                          placeholder={
                            selectedAttendance
                              ? moment(
                                  selectedAttendance?.meditation_datetime
                                ).format("ddd HH:mm A")
                              : "Select Timing"
                          }
                          style={{
                            width: "100%",
                          }}
                          onChange={handleChange}
                        >
                          {options}
                          <Option key="other">
                    {options !== undefined
                      ? "Other"
                      : "No Timings found, Add new"}
                  </Option>
                        </Select>
                      </div>

                      {manualSelection && (
                <div className="time-selection-main-container-attendance">
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticTimePicker
                        ampm
                        orientation={isMobile ? "portrait" : "landscape"}
                        // value={
                        //   isTimeSelected ? manualSelection : moment(new Date())
                        // }
                        value={manualValue}
                        onChange={(e) => {
                          // setIsTimeSelected(true);
                          setIsDropDownSelected(true);
                          setManualValue(e);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}

                    </div>
                    <button
                      className="footer-save-rec-att"
                      style={{ border: "none" }}
                      type="submit"
                      disabled={disableButton} 
                    >
                      Save
                    </button>
                  </form>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AttendanceList;
