import React, { useCallback, useRef,useState,useEffect } from "react";
import { HfnFirebaseAuth } from "@heartfulnessinstitute/react-hfn-profile";
import { getCenterId } from "../../services/meditationCenter/centerList";
import { useNavigate } from "react-router-dom";
import HfLogo from "../../assets/images/hfn-logo.svg";
import "../../assets/styles/pages/login.scss";
import { lStorage } from "../../utils/storage";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { signOut } from "@heartfulnessinstitute/react-hfn-profile";

const Login = () => {
  const navigate = useNavigate();
  const [heartsApp, setHeartsApp] = useState();
  
  useEffect(() => {
    setHeartsApp(lStorage.get("heartsApp"))
    if(heartsApp===1){
    navigate("/launch/");
    }
    
  }, [heartsApp,navigate])

  const firebaseRef = useRef(null);
  const hasAPICall = useRef(false);

  // const logout = () => {
  //   toast.error("Group id not found");
  //   signOut();
  //   localStorage.clear();
  //   navigate("/");
  // };

  const navigateCall = (infoData) => {
    getCenterId(1, 100)
      .then((res) => {
        const results = res?.data?.results || [];
        const matchCount = results.filter(item => item.srcm_group_detail?.group_type === "center").length;
        lStorage.set("centersCount", matchCount);
        if(matchCount === 0){
          navigate("/MymediCenters");
        }else{
          navigate("/home");
        }
      })
    // if (infoData?.srcm_group?.id !== undefined || null) {
    //   lStorage.set("srcm_group_id", infoData?.srcm_group?.id);
    // navigate("/home");
    // } else if (infoData?.srcm_group?.id === undefined || null) {
    //   logout();
    // }
  };

  const processLogin = useCallback(({ myInfo }) => {
    if (myInfo && firebaseRef.current) {
      firebaseRef.current.authFn.onAuthStateChanged((authState) => {
        authState?.getIdToken().then(async (srcmToken) => {
          if (!hasAPICall.current && myInfo.id) {
            lStorage.set("authToken", `${srcmToken}`);
            lStorage.set("heartsApp", 0);
            hasAPICall.current = true;
            setTimeout(() => {
              navigateCall(myInfo);
            }, 1000);
          }
        });
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="login-container">
        <div className="inner-login-container">
          <div className="login-logo-container">
            <img src={HfLogo} alt="hfn-logo" className="hfn-logo" />
          </div>
          <div className="text-container">
            <p className="HeartFulness-text">Heartfulness</p>
            <p className="meditation-text">Meditation</p>
            <p className="center-text-login">Centre</p>
            {heartsApp !==1 &&
            <div className="login-with-container">
              <div className="lext-space"></div>
              <p className="login-with-text">LOGIN WITH</p>
              <div className="lext-space"></div>
            </div>
}
          </div>
          {heartsApp !==1 &&
          <div className="hfn-login-component-container">
            <HfnFirebaseAuth
              ref={firebaseRef}
              doLogin={processLogin}
              titleText=""
            />
          </div>
}
        </div>
      </div>
    </>
  );
};

export default Login;
