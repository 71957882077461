import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCenterId,
  getFilterCenterLists,
} from "../../services/meditationCenter/centerList";
// import AddBtn from "../../assets/images/addButton.svg";
import { lStorage } from "../../utils/storage";
import { signOut } from "@heartfulnessinstitute/react-hfn-profile";
import Spinner from "../spinner";
import "../../assets/styles/pages/home.scss";
import Pagination from "react-js-pagination";
import { useForm } from "react-hook-form";

const Home = () => {
  const navigate = useNavigate();

  const [centerAttendanceData, setCenterAttendanceData] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [positionType, setPositionType] = useState();
  const [centerCount, setCenterCount] = useState();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [filteredRoleData, setFilteredRoleData] = useState([]);

  useEffect(() => {
    if (centerAttendanceData) {
      setPositionType(centerAttendanceData?.[0]?.position_type_code);
    }
  }, [centerAttendanceData]);

  const page = 1;
  const page_size = 12;

  // current page
  const [currentPage, setCurrentPage] = useState(1);

  // total records per page to display
  const recordPerPage = 20;
  const totalRecords = centerCount;
  const pageRange = Math.ceil(totalRecords / 300);

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    getCenterId(pageNumber, page_size)
      .then((res) => {
        if (res?.status === 200 || res?.data?.results) {
          if (res?.data?.results?.length === 0) {
            setIsEmpty(true);
            setLoading(false);
          } else {
            setLoading(false);
            lStorage.set("center-id", res?.data?.results?.[0]?.srcm_group?.id);
            setCenterCount(res?.data?.count);
            setCenterAttendanceData(res?.data?.results);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 403) {
          signOut();
          alert("You do not have permission to perform this action");
          const heartsApp = lStorage.get("heartsApp");  
          localStorage.clear();
          lStorage.set("heartsApp", heartsApp);  
          navigate("/");
        }else{
          signOut();
          const heartsApp = lStorage.get("heartsApp");  
          localStorage.clear();
          lStorage.set("heartsApp", heartsApp); 
          navigate("/");
        }
      });
  };

  useEffect(() => {
    // getCenterId(page, page_size)
    //   .then((res) => {
    //     if (res?.status === 200 || res?.data?.results) {
    //       if (res?.data?.results?.length === 0) {
    //         setIsEmpty(true);
    //         setLoading(false);
    //       } else {
    //         setLoading(false);
    //         lStorage.set("center-id", res?.data?.results?.[0]?.srcm_group?.id);
    //         setCenterCount(res?.data?.count);
    //         setCenterAttendanceData(res?.data?.results);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response.status === 403) {
    //       signOut();
    //       alert("You do not have permission to perform this action");
    //       localStorage.clear();
    //       navigate("/");
    //     }
    //   });
    handlePageChange(Number(1));
    // eslint-disable-next-line
  }, []);

  const resetCall = () => {
    setIsSearchActive(false);
    setIsEmpty(true);
    handlePageChange(Number(1));
    reset({
      srcm_group_name: "",
    });
  };

  const navigateToActiveCenter = (item) => {
    lStorage.set("centerName", item?.srcm_group?.name);
    const centerId = item.srcm_group.id;
    lStorage.set("center-id", `${centerId}`);
    // lStorage.set("positionType", `${positionType}`);
    lStorage.set("positionType", item.position_type_code);
    const posType = {
      id: item.id,
      position_type_code: item.position_type_code,
    };
    lStorage.set("position-data", posType);
    navigate(`/meditationCenter/${item.srcm_group.id}`, {
      state: { positionType: positionType },
    });
  };

  const navigateToZoneCenter = (item) => {
    lStorage.set("centerName", item?.srcm_group?.name);
    const centerId = item.srcm_group.id;
    lStorage.set("center-id", `${centerId}`);
    // lStorage.set("positionType", `${positionType}`);
    lStorage.set("positionType", item.position_type_code);
    const posType = {
      id: item.id,
      position_type_code: item.position_type_code,
    };
    lStorage.set("position-data", posType);
    navigate(`/ZoneMeditationCenter/${item.srcm_group_detail.id}`, {
      state: { positionType: positionType },
    });
  };

  const navigateToCenterCoordinator = (item) => {
    const posType = {
      id: item.id,
      position_type_code: item.position_type_code,
    };
    lStorage.set("position-data", posType);
    lStorage.set("positionType", item?.position_type_code);
    lStorage.set("centerName", item?.srcm_group?.name);
    const centerId = item.srcm_group.id;
    lStorage.set("center-id", `${centerId}`);
    navigate(`/coordinator/${item.srcm_group.id}`);
  };

  useEffect(() => {
    if (centerAttendanceData?.count === 0 || undefined || null) {
      setIsEmpty(true);
    }else if(centerAttendanceData?.count !== 0){
      setIsSearchActive(false);
      setIsEmpty(false);
    }
  }, [centerAttendanceData]);

  useEffect(() => {
    lStorage.set("tabName", "ListCenters");
    lStorage.remove("activeTab");
   // lStorage.remove("cityName");
    lStorage.remove("isActiveCenter");
    lStorage.remove("meditationDay");
    lStorage.remove("selectedActiveCenterId");
   // lStorage.remove("formData");
    lStorage.remove("draftId");
    // lStorage.remove("medetationDateTime");
    lStorage.remove("formDatadraft");
  }, []);


  const centerAttendanceDataToMap = centerAttendanceData?.filter((item) => {
    return item?.srcm_group_detail?.group_type === "center" && (item?.position_type_code === "CC" || item?.position_type_code ==="MCC");
});

  useEffect(() => {
    if (centerAttendanceDataToMap?.length === 0) {
        setIsEmpty(true);
    } else {
      if(centerAttendanceDataToMap?.length > 0){
        const attendanceDetails = centerAttendanceDataToMap?.map((item) => {
          return {
              position_type_code: item.position_type_code,
              srcm_group_id: Number(item.srcm_group_detail.id),
          };
        });
        localStorage.setItem('userRoleCenterId', JSON.stringify(attendanceDetails));
      }
        setIsEmpty(false);
    }
}, [centerAttendanceDataToMap]);

 

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    setIsSearchActive(true);
    const srcm_group__name__iexact = data.srcm_group_name;
    getFilterCenterLists(page, page_size, srcm_group__name__iexact)
      .then((res) => {
        if (res?.status === 200 || res?.data?.results) {
          if (res?.data?.results?.length === 0) {
            setIsEmpty(true);
            setLoading(false);
          } else {
            setLoading(false);
            lStorage.set("center-id", res?.data?.results?.[0]?.srcm_group?.id);
            setCenterCount(res?.data?.count);
            setCenterAttendanceData(res?.data?.results);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          signOut();
          alert("You do not have permission to perform this action");
          const heartsApp = lStorage.get("heartsApp");  
          localStorage.clear();
          lStorage.set("heartsApp", heartsApp); 
          navigate("/");
        }
      });
  };

  return (
    <>
      {loading && <Spinner />}
      {!isEmpty && !loading  && (
      <div className="search-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register("srcm_group_name", {
              required: false,
              maxLength: 20,
            })}
            className="input_field"
            autoComplete="off"
          />
          <input type="submit" value="Search" className="submit_btn" />
          <button className="submit_btn" onClick={() => resetCall()}>
            Reset
          </button>
        </form>
      </div>
      )}
      {isEmpty && !loading && !isSearchActive && (
        <div className="add-timing-main-container">
          <div className="add-timing-inner-container">
            <div className="add-timing-container">
              <p style={{ fontSize: "16px" }}>
                Your Meditation Center list is empty
              </p>
              {/* <img
                src={AddBtn}
                alt="add"
                className="add-btn"
                onClick={() => {
                  lStorage.set("cityName", obj);
                  navigate(`/newMeditationCenter/${srcmGroupId}`);
                }}
              />
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "#28b3ff",
                }}
              >
                No action can be done
              </p> */}
            </div>
          </div>
        </div>
      )}

      {isEmpty && !loading &&  isSearchActive && (
        <div className="add-timing-main-container">
          <div className="add-timing-inner-container">
            <div className="add-timing-container">
              <p style={{ fontSize: "16px" }}>
                No Record Found
              </p>
            </div>
          </div>
        </div>
      )}

      {!isEmpty && (
        <>
          <div className="main-wrapper-div">
            <div className="main-div">
              {centerAttendanceDataToMap?.map((item) => {
                return (
                  <div className="main-container">
                    <div className="inner-container">
                      <div className="first-line">
                        <p>{item?.srcm_group?.name} - {item?.srcm_group_detail?.group_type?.toUpperCase()}</p>
                        <div
                          className="navigate-arrow"
                          onClick={() => 
                            item?.srcm_group_detail?.group_type?.toUpperCase() === 'ZONE' 
                              ? navigateToZoneCenter(item) 
                              : navigateToActiveCenter(item)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-chevron-right"
                            viewBox="0 0 16 16"
                            style={{ cursor: "pointer", color: "white" }}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="second-row">
                      <div>
                        <p>Position - {item?.position_type_code === "CC" && "Center Coordinator"}
                        {item?.position_type_code === "MCC" && "Meditation Place Coordinator"}</p>
                      </div>
                    </div>
                      <div style={{ display: "flex" }}>
                        {item?.srcm_group_detail?.group_type !== "zone" && (
                        <div
                          onClick={() => {
                            navigateToCenterCoordinator(item);
                            lStorage.set("isCoordinatorActive", false);
                          }}
                          style={{ cursor: "pointer", color: "#2cb4ff" }}
                        >
                          Meditation place coordinators
                        </div>
                        )}
                        <div style={{ marginLeft: "auto", marginRight: "5px" }}>
                          <h6
                            style={{
                              color:
                                item.active === true ? "#8ccb47" : "#d41a33",
                            }}
                          >
                            <b>
                              {item.active === true ? "Active" : "Inactive"}
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {centerAttendanceDataToMap?.length !== 0 && !loading && (
                <div>
                  <div className="mt-1 mb-3" style={{ textAlign:"center"}}>
                    <b>Current Page : </b>
                    <span className="ml-2">{currentPage}/{Math.ceil(totalRecords/recordPerPage)}</span>
                  </div>

                  <div className="mg-left-50" style={{display:"flex", justifyContent:"center"}}>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
