import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../../../assets/images/home.png";
import {
  getMeditationCenterCoordinator,
  updateAbhyasi,
} from "../../../services/meditationCenter/centerList";
import "../../../assets/styles/pages/abhyasi.scss";
import Spinner from "../../spinner";
import "antd/dist/antd.css";
import AddIcon from "../../../assets/images/add-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-js-pagination";
import { Modal, Button } from "react-bootstrap";
import { lStorage } from "../../../utils/storage";

const ActiveCenter = () => {
  const navigate = useNavigate();
  const id = useParams();

  const [selectedId, setSelectedId] = useState();
  const [medCenterCoordinator, setMedCenterCoordinator] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatedAbhyasi, setUpdatedAbhyasi] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [abhyasiDetails, setAbhyasiDetails] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showCreation, setShowCreation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const coordinatorData = medCenterCoordinator?.results?.map((userData) => {
    const { assign_active } = userData;
    return {
      ...userData,
      assign_active: assign_active === true ? "Active" : "In Active",
    };
  });

  //const page = 1;
  const page_size = 15;

  // current page
  const [currentPage, setCurrentPage] = useState(1);

  // total records per page to display
  const recordPerPage = 15;

  // total number of the records
  const totalRecords = medCenterCoordinator?.count;

  // range of pages in paginator
  const pageRange = Math.ceil(totalRecords / 15);

  // handle change event
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    getMeditationCenterCoordinator(pageNumber, page_size, selectedId)
      .then((res) => {
        setLoading(false);
        setMedCenterCoordinator(res?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later");
        // alert(err);
        navigate("/home");
      });
  };

  const updateAbhyasiDetails = (abhyasiData) => {
    setDisableButton(true);
    const payload = {
      start_date: abhyasiData.start_date,
      end_date: abhyasiData.end_date,
      partner: abhyasiData.partner.id,
      position: abhyasiData.position.id,
      assignment_type: abhyasiData.assignment_type,
      srcm_group: abhyasiData.srcm_group,
      assign_active: abhyasiData?.assign_active === "Active" ? false : true,
    };
    updateAbhyasi(payload, selectedId, abhyasiData?.id)
      .then((res) => {
        setLoading(false);
        setShowDelete(false);
        toast.success(
          abhyasiData?.assign_active === "Active"
            ? "Coordinator deactivated successfully"
            : "Coordinator activated successfully"
        );
        setDisableButton(false);
        setUpdatedAbhyasi(res);
      })
      .catch((err) => {
        setDisableButton(false);
        toast.error("Something went wrong please try again later");
        navigate("/home");
      });
  };

  useEffect(
    () => {
      var page = currentPage?currentPage:1;
      
      if (selectedId) {
        getMeditationCenterCoordinator(page, page_size, selectedId)
          .then((res) => {
            setLoading(false);
            setMedCenterCoordinator(res?.data);
          })
          .catch((err) => {
            toast.error("Something went wrong please try again later");
            // alert(err);
            navigate("/home");
          });
      }
    },
    [selectedId, navigate, updatedAbhyasi,currentPage]
  );

  useEffect(() => {
    setSelectedId(id?.id);
  }, [id]);

  useEffect(() => {
    if (coordinatorData?.length === 0) {
      setIsEmpty(true);
    }
  }, [coordinatorData]);

  useEffect(() => {
    if(lStorage.get("successMessage") !== undefined && lStorage.get("successMessage")!==""){
      toast.success(lStorage.get("successMessage"));
      lStorage.set("successMessage","")
    }
    const positionType = lStorage.get("positionType");
    if (positionType === "CC" || positionType === "ZC" ) {
      setShowCreation(true);
    }
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  const trackClose = () => {
    setShowDelete(false);
  };

  return (
    <>
      <ToastContainer />

      {loading && <Spinner />}

      {isEmpty && (
        <div className="add-timing-main-container">
          <div className="add-timing-inner-container">
            <div className="add-timing-container">
              <p style={{ fontSize: "16px" }}>
                No Coordinator Found
              </p>
              {showCreation && (
                <>
                <img
                  src={AddIcon}
                  alt="add"
                  className="add-btn"
                  onClick={() => navigate(`/addCoordinator/${selectedId}`)}
                />
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    color: "#28b3ff",
                  }}
                >
                  Add Coordinator
                </p>
              </>
              )}
            </div>
          </div>
        </div>
      )}

      {!isEmpty && (
        <div className="main-wrapper-div-coordinator">
          <div className="outer-container" style={{ marginTop: "20px" }}>
            <div>
              {coordinatorData?.map((item) => {
                return (
                  <div className="active-center-card-container">
                    <div className="active-center-card-inner-container">
                      <div className="active-center-card-container-wrapper">
                        <div style={{ display: "flex" }}>
                          <div style={{ marginBottom: "10px" }}>
                            <span>{item?.partner?.name}</span>
                            {/* <p className="active-center-card-container-para">
                              {item?.partner?.id}
                            </p> */}
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <span>Status : </span>
                            <span
                              style={{
                                // paddingLeft: "20px",
                                color:
                                  item?.assign_active === "Active"
                                    ? "rgb(140, 203, 71)"
                                    : "red",
                              }}
                            >
                              <b>{item?.assign_active}</b>
                            </span>
                          </div>

                          <div
                            style={{ marginLeft: "15px" }}
                            onClick={() => {
                              setAbhyasiDetails(item);
                              setShowDelete(true);
                            }}
                            title={
                              item?.assign_active === "Active"
                                ? "Deactivate"
                                : "Activate"
                            }
                          >{}
                            {showCreation && (
                              <Button
                                style={{
                                  backgroundColor:
                                    item?.assign_active === "Active"
                                      ? "red"
                                      : "rgb(140, 203, 71)",
                                  border:
                                    item?.assign_active === "Active"
                                      ? "1px solid red"
                                      : "1px solid rgb(140, 203, 71)",
                                }}
                              >
                                {item?.assign_active === "Active"
                                  ? "Deactivate"
                                  : "Activate"}
                              </Button>
                            )}
                          </div>
                        </div>

                        <div className="active-center-card-container-wrapper-first">
                          <div className="active-center-card-icon-container">
                            <img
                              src={Home}
                              alt="home"
                              className="card-icon-new"
                            />
                          </div>

                          <div style={{ color: "#808688" }}>
                            <p style={{ color: "black" }}>Coordinator - {item?.srcm_group?.name} - {item?.partner?.name}</p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: isMobile && "wrap",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "5px" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-envelope"
                                    viewBox="0 0 16 16"
                                    style={{ color: "black" }}
                                  >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                  </svg>
                                </div>
                                <div>
                                  <p>{item?.partner_email}</p>
                                </div>
                              </div>
                              {item?.partner_contact_number && (
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      marginRight: "5px",
                                      marginLeft: !isMobile && "30px",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-telephone"
                                      viewBox="0 0 16 16"
                                      style={{ color: "black" }}
                                    >
                                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                    </svg>
                                  </div>

                                  <div>
                                    <p> {item?.partner_contact_number}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div >
            <div className="mt-1 mb-3" style={{ textAlign:"center"}}>
              <b>Current Page : </b>
              <span className="ml-2">{currentPage}/{Math.ceil(totalRecords/recordPerPage)}</span>
            </div>

            <div className="mg-left-50" style={{display:"flex", justifyContent:"center"}}>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={currentPage}
                itemsCountPerPage={recordPerPage}
                totalItemsCount={totalRecords}
                pageRangeDisplayed={pageRange}
                onChange={handlePageChange}
              />
            </div>
          </div>
          {showCreation && (
            <div
              className="add-icon-container-center-lists"
              onClick={() => navigate(`/addCoordinator/${selectedId}`)}
              title="Add Coordinator"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="#2cb4ff"
                class="bi bi-plus-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
              </svg>
            </div>
          )}
          <Modal show={showDelete} centered>
            <Modal.Header>
              <Modal.Title>
                {abhyasiDetails?.assign_active === "Active"
                  ? "Deactivate Coordinator"
                  : "Activate Coordinator"}
              </Modal.Title>
              <Button variant="close" onClick={trackClose} aria-label="Close" />
            </Modal.Header>
            <Modal.Body>
              <div>
                <p className="impul-label">
                  {abhyasiDetails?.assign_active === "Active"
                    ? "Are you sure you want to deactivate ?"
                    : "Are you sure you want to activate ?"}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="whiteColor"
                style={{ color: "black" }}
                onClick={trackClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => updateAbhyasiDetails(abhyasiDetails)}
                disabled={disableButton}
              >
                {abhyasiDetails?.assign_active === "Active"
                  ? "Deactive"
                  : "Active"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ActiveCenter;
