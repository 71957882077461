import React, { useState } from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
import axios from "axios";
import { lStorage } from "../../../utils/storage";
import config from '../../../assets/config';
//config.apiCitySearch

const { Option } = Select;
let timeout;
let currentValue;

const fetch = (value, callback, setSearchResult) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const citySearchApiCall = () => {
    axios
      .get(
        `${config.apiCitySearch}cities/${currentValue?.toLowerCase()}.json`
      )
      .then((response) => {
        const d = response?.data?.results;
        const result = d;
        if (currentValue === value) {
          const data = result.map((item) => ({
            c_id: item?.id,
            country_id: item?.country_id,
            name: item?.name,
            state_id: item?.state_id,
            c_name: item?.c_name,
            state: item?.state,
          }));
          callback(data);
          setSearchResult(data);
        }
      });
  };
  timeout = setTimeout(citySearchApiCall, 300);
};

const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState();
  const { onCitySelect, onSearchValueChange, selectedCountryData } = props;
  const [searchResult, setSearchResult] = useState([]);
 


  const handleSearch = (newValue) => {
    onSearchValueChange(newValue);
    if (newValue?.length <= 4) {
      fetch(newValue, setData, setSearchResult);
    } else if (newValue?.length > 4) {
      const searchRegex = new RegExp(newValue.replace(/[^\w\s]/g, ""), "i");
      const data = searchResult.filter((_) => searchRegex.test(_.name));
      setData(data);
    } else {
      setData([]);
    }
  };

  const newData = data.filter((item) => {
    return item?.country_id === selectedCountryData?.value;
  });

  const handleChange = (newValue) => {
    setValue(newValue);
    onCitySelect(JSON.parse(newValue));
    lStorage.set("cityName", JSON.parse(newValue));
    const draftDataId = lStorage.get("draftId");
    const selectedActiveCenterId = lStorage.get("selectedActiveCenterId");
    if (draftDataId || selectedActiveCenterId) {
      lStorage.set("cityName", JSON.parse(newValue));
    }else{
    lStorage.set("tempCityName", JSON.parse(newValue));
    }
  };

  const options = newData?.map((d) => {
    if (d?.name?.length !== 0) {
      return <Option key={JSON.stringify(d)}>{d?.name} - {d?.state}</Option>;
    } else {
      return null;
    }
  });

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      autoFocus={true}
    >
      {options}
    </Select>
  );
};

const CitySearch = (props) => {
  const { onCitySelect, onSearchValueChange, selectedCountryData } = props;
  return (
    <SearchInput
      placeholder="Enter a city"
      style={{
        width: "100%",
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
      }}
      onSearchValueChange={onSearchValueChange}
      onCitySelect={onCitySelect}
      selectedCountryData={selectedCountryData}
    />
  );
};

export default CitySearch;
