import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFilteredZoneCenterList } from "../../services/meditationCenter/centerList";
import { lStorage } from "../../utils/storage";
import Spinner from "../spinner";
import "../../assets/styles/pages/home.scss";


const ZoneMediCenter = () => {
    const navigate = useNavigate();

    const [centerAttendanceData, setCenterAttendanceData] = useState();
    const [isEmpty, setIsEmpty] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState();
    const [centerList, setCenterList] = useState();

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id)
    }, [id, navigate])

  useEffect(() => {
    const id = selectedId?.id;
    getFilteredZoneCenterList(id).then((res) => {
        if (res?.status === 200 && res?.data?.results) {
          if (res.data.results.length === 0) {
            setCenterList(res?.data?.count);
          } else {
            setCenterAttendanceData(res.data.results);
          }
          setLoading(false);
        }
    });
  }, [selectedId]);


  const navigateToActiveCenter = (item) => {
    lStorage.set("centerName", item?.name);
    const centerId = item.id;
    lStorage.set("center-id", `${centerId}`);
    const positionType = lStorage.get("positionType")
    const posType = {
      id: item.id,
      position_type_code: item.position_type_code,
    };
    lStorage.set("position-data", posType);
    navigate(`/meditationCenter/${item.id}`, {
      state: { positionType: positionType },
    });
  };


  const navigateToCenterCoordinator = (item) => {
    const posType = {
      id: item.id,
      position_type_code: item.position_type_code,
    };
    lStorage.set("position-data", posType);
    lStorage.set("centerName", item?.name);
    const centerId = item.id;
    lStorage.set("center-id", `${centerId}`);
    navigate(`/coordinator/${item.id}`);
  };

  useEffect(() => {
    if ((centerAttendanceData?.count === 0 || undefined || null) && centerList === 0) {
      setIsEmpty(true);
    }else if(centerAttendanceData?.count !== 0){
      setIsEmpty(false);
    }
  }, [centerAttendanceData, centerList]);

  useEffect(() => {
    lStorage.remove("activeTab");
    lStorage.remove("isActiveCenter");
    lStorage.remove("meditationDay");
    lStorage.remove("selectedActiveCenterId");
    lStorage.remove("draftId");
    lStorage.remove("formDatadraft");
  }, []);


  return (
    <>
      {loading && <Spinner />}
      {isEmpty && !loading && (
        <div className="add-timing-main-container">
          <div className="add-timing-inner-container">
            <div className="add-timing-container">
              <p style={{ fontSize: "16px" }}>
                Your Meditation Center list is empty
              </p>
             
            </div>
          </div>
        </div>
      )}

      {!isEmpty && (
        <>
          <div className="main-wrapper-div">
            <div className="main-div">
              {centerAttendanceData?.map((item) => {
                return (
                  <div className="main-container">
                    <div className="inner-container">
                      <div className="first-line">
                        <p>{item?.name}</p>
                        <div
                          className="navigate-arrow"
                          onClick={() => 
                            navigateToActiveCenter(item)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-chevron-right"
                            viewBox="0 0 16 16"
                            style={{ cursor: "pointer", color: "white" }}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          onClick={() => {
                            navigateToCenterCoordinator(item);
                            lStorage.set("isCoordinatorActive", false);
                          }}
                          style={{ cursor: "pointer", color: "#2cb4ff" }}
                        >
                          Center Coordinators
                        </div>
                     
                        <div style={{ marginLeft: "auto", marginRight: "5px" }}>
                          <h6
                            style={{
                              color:
                                item.active === true ? "#8ccb47" : "#d41a33",
                            }}
                          >
                            <b>
                              {item.active === true ? "Active" : "Inactive"}
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
             
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ZoneMediCenter;
