import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input } from "antd";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/styles/pages/recordMeals.scss";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../../spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrencyList } from "../../../../services/meditationCenter/centerList";
import { lStorage } from "../../../../utils/storage";
import { updateMeditationCenter } from "../../../../services/meditationCenter/newMeditationCenter";

const RecordMonthlyExpanses = () => {
  const draftData = lStorage.get("formData");
  const draftVal = lStorage.get("formDatadraft");
  const navigate = useNavigate();

  const requiredFields = ["monthly_avg_expenses", "paid_by",
  //  "currency_id"
  ];

  const [loading, setLoading] = useState(false);

  const [draftExpenseTime, setDraftExpenseTime] = useState();
  const [expenseTime, setExpenseTime] = useState();

  const [currencyList, setCurrencyList] = useState([]);
  const [paidBy, setPaidBy] = useState();
  const [paidByCurrency, setPaidByCurrency] = useState();
  const [emptyError, setEmptyError] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  // const lcityName = lStorage.get("cityName");

  const paidByOptions = [
    { value: "mission", label: "By mission" },
    { value: "abhyasi_group", label: "By group of abhyasis" },
    { value: "abhyasi", label: "By particular abhyasis" },
  ]

  // eslint-disable-next-line
   const testPaidBySelected = paidByOptions.filter(item =>{
    if(item.value === draftData?.paid_by){
      return item
    }
   })
   const currentId = draftData?.currency_id?.id? draftData?.currency_id?.id:draftData?.currency_id?.value;
  // eslint-disable-next-line
   const testPaidBySelectedCurrency = currencyList.filter(item =>{
    if(item.value === currentId){
      return item
    }
   })

  // eslint-disable-next-line
  // const [fetchCityData, setFetchCityData] = useState({
  //   ...(Object.keys(lcityName)?.length
  //     ? {
  //         c_id: lcityName?.c_id,
  //         country_id: lcityName?.country_id,
  //         state_id: lcityName?.state_id,
  //       }
  //     : {
  //         c_id: draftData?.city_id?.id,
  //         country_id: draftData?.country_id?.id,
  //         state_id: draftData?.state_id?.id,
  //       }),
  // });

  useEffect(() => {
    setLoading(true);
    getCurrencyList().then((res) => {
      const listofcurrency = res?.data?.results?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setCurrencyList(listofcurrency);
      setLoading(false);
    });
  }, []);

  const getDefaultValue = () => {
    const updatedData =
      Object.keys(draftVal).length !== 0 ? draftVal : lStorage.get("formData");

    return {
      monthly_avg_expenses: updatedData
        ? updatedData?.monthly_avg_expenses
        : "",
      monthly_avg_expenses_dt: updatedData
        ? updatedData?.monthly_avg_expenses_dt
        : "",
      paid_by: updatedData ? updatedData?.paid_by : "",
      currency_id: updatedData ? updatedData?.currency_id?.id : "",
    };
  };

  const { control ,handleSubmit ,formState: { errors },} = useForm({
    defaultValues: getDefaultValue(),
  });

  useEffect(() => {
    const draftVal = lStorage.get("formDatadraft");
    const updatedData =
      Object.keys(draftVal).length !== 0 ? draftVal : lStorage.get("formData");
    setDraftExpenseTime(updatedData?.monthly_avg_expenses_dt);
  }, []);

  const draftFunctionCalled = (testVal) => {
    lStorage.set("formDatadraft", testVal);
  };

  const localStoreData = lStorage.get("formData");


  const validateData = (data) => {
    const emptyError = requiredFields?.filter(
      (reqField) => !Boolean(data[reqField])
    );
    setEmptyError(emptyError);
    return emptyError;
  };

  const onSubmit = (data) => {
    const testDataVal = {
      ...((paidBy || draftData) && { paid_by: paidBy ? paidBy?.value : draftData?.paid_by?.value ? draftData?.paid_by?.value : draftData?.paid_by }),
      // ...((paidByCurrency || draftData) && {currency_id: paidByCurrency?.value ? paidByCurrency?.value : draftData?.currency_id?.value ? draftData?.currency_id?.value : draftData?.currency_id?.id}),
    }
    const newData= {...data, ...testDataVal}
    setIsSubmit(true);
    if (validateData(newData)?.length === 0) {
      setDisableButton(true);
    const payloadToSend = {
      ...data,
      paid_by: paidBy ? paidBy?.value : draftData?.paid_by?.value ? draftData?.paid_by?.value : draftData?.paid_by,
      currency_id: paidByCurrency?.value ? paidByCurrency?.value : draftData?.currency_id?.value ? draftData?.currency_id?.value : draftData?.currency_id?.id ,
      name: draftData?.name,
      srcm_group_id: draftData?.srcm_group_id?.id,
      monthly_avg_expenses: data.monthly_avg_expenses,
      monthly_avg_expenses_dt: expenseTime?moment(expenseTime).format("YYYY-MM-DD"):draftData?.monthly_avg_expenses_dt,
    };

    const CombinedFormData = { ...localStoreData, ...payloadToSend };

    const CombinedFormDataTwo = { ...CombinedFormData, ...({paid_by : paidBy?.value ? paidBy.value :draftData?.paid_by})  };
    const CombinedFormDataThree = { ...CombinedFormDataTwo, ...({currency_id : paidByCurrency ? paidByCurrency :draftData?.currency_id})  };
  
    lStorage.set("formData", CombinedFormDataThree);
    draftFunctionCalled(CombinedFormDataThree);

    const selectedCenterid = draftData?.id;
    const draftCenterId = id?.id;
    updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
      .then((res) => {
        if (res) {
          toast.success("Details Updated");
          setLoading(false);
          setTimeout(() => {
            navigate(
              `/meditationPlace/${id?.id}/${id?.activeCenterId}`
            );
          }, 1000);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        toast.error("Something went wrong please try again later");
        setDisableButton(false);

      });
    }
  };
  const id = useParams();

  const dropdownStyle = {
    control: () => ({
      width: '100%',
      display: 'flex',
      padding: '6px 20px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#212529',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const inlineErrorValidation = (fieldName) => {
    setEmptyError(emptyError?.filter((errorField) => errorField !== fieldName));
  };

  return (
    <>
      <ToastContainer />

      {loading && <Spinner />}
      {!loading && (
        <div className="rec-meals-form-container">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="rec-meals-container"
            style={{overflowY:"hidden"}}
          >
            <div className="imput-field-container">
              <p className="impul-label">Monthly Average Expenses Date</p>
              <div className="date-picker-wrapper">
                <DatePicker
                  wrapperClassName="datepicker"
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  maxDate={new Date()}
                  onChange={(date) => {
                    setExpenseTime(new Date(date));
                  }}
                  selected={
                    expenseTime
                      ? expenseTime
                      : draftExpenseTime
                      ? new moment(
                          draftExpenseTime +
                            "T" +
                            moment(new Date()).format("HH:mm A"),
                          "YYYY-MM-DDTHH:mm"
                        ).utc()?._d
                      : new Date()
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>

            <div className="input-field-container">
              <p className="inpul-label" style={{ display: "inline-block" }}>Monthly Average Expense <span style={{ color: "red"}}>*</span></p>
              {emptyError?.indexOf("monthly_avg_expenses") > -1 && isSubmit && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {" "}
                    Field Required
                  </span>
                )}
                {errors?.monthly_avg_expenses && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {errors?.monthly_avg_expenses?.message}
                  </span>
                )}
              <Controller
                name="monthly_avg_expenses"
                control={control}
                rules={{
                  pattern: {
                    value: /^[1-9][0-9]*$/,
                    message: "Numeric values only",
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Monthly Average Expense"
                    className="form-input-field-border"
                    autoComplete="nope"
                    onKeyDown={() =>
                      inlineErrorValidation("monthly_avg_expenses")
                    }
                  />
                )}
              />
            </div>

            <div className="imput-field-container">
              <p className="impul-label" style={{ display: "inline-block" }}>Paid By <span style={{ color: "red"}}>*</span></p>
              {emptyError?.indexOf("paid_by") > -1 && isSubmit && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {" "}
                    Field Required
                  </span>
                )}
              <Controller
                name="paid_by"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={paidByOptions}
                    style={{ width: "100%" }}
                    value={paidBy ? paidBy :  testPaidBySelected?.[0]}
                    name={field.name}
                    inputRef={field.ref}
                    isSearchable={false}
                    onChange={(value) => {setPaidBy(value);
                      inlineErrorValidation("paid_by");
                    }}
                  />
                )}
              />
            </div>

            <div className="imput-field-container">
              <p className="impul-label" style={{ display: "inline-block" }}>Currency</p>
              {/* {emptyError?.indexOf("currency_id") > -1 && isSubmit && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {" "}
                    * Field Required
                  </span>
                )} */}
              <Controller
                name="currency_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={currencyList}
                    styles={dropdownStyle}
                    value={paidByCurrency ? paidByCurrency : testPaidBySelectedCurrency.length !== 0 ? testPaidBySelectedCurrency : {value: 193, label: 'INR'} }   
                    name={field.name}
                    inputRef={field.ref}
                    placeholder="Select Currency"
                    onChange={(value) => {
                     setPaidByCurrency(value);
                    //  inlineErrorValidation("currency_id");
                    }}
                    
                  />
                )}
              />
            </div>

            <button
              className="rec-meals-footer-save"
              style={{ border: "none" }}
              type="submit"
              disabled={disableButton} 
            >
              Save
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default RecordMonthlyExpanses;
