import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Input, Select } from "antd";
import "../../../assets/styles/pages/newMeditationCenter.scss";
import CitySearch from "../citySearch";
import { lStorage } from "../../../utils/storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateMeditationCenter } from "../../../services/meditationCenter/newMeditationCenter";
import CountrySearch from "../countrySearch";

const NewMeditationCenter = () => {
  const draftData = lStorage.get("formData");
  const isActiveCenter = lStorage.get("isActiveCenter");
  const [disableButton, setDisableButton] = useState(false);
  const lcityName = lStorage.get("cityName");
  lStorage.set("enableLocation", 0);
  const navigate = useNavigate();
  const [selectedCountryData, setSelectedCountryData] = useState();
  const [fetchCityData, setFetchCityData] = useState({
    ...(Object.keys(lcityName)?.length
      ? {
          c_id: lcityName?.c_id,
          country_id: selectedCountryData
            ? selectedCountryData?.value
            : lcityName?.country_id,
          state_id: lcityName?.state_id,
        }
      : {
          c_id: draftData?.city_id?.id,
          country_id: draftData?.country_id?.id,
          state_id: draftData?.state_id?.id,
        }),
  });

  useEffect(() =>{
    const cityDataFromLocalStorage = Object.keys(lStorage.get("cityName"));
    const lcityId = lStorage.get("cityName")
    if (cityDataFromLocalStorage?.length !== 0) {
      lStorage.set("cityName", {name: lcityId?.name,
        c_id: lcityId?.c_id})
      
    }else if(cityDataFromLocalStorage?.length === 0){
      lStorage.set("cityName", {name: draftData?.city_id?.name,
        c_id:draftData?.city_id?.id,})
    }
    
// eslint-disable-next-line
  },[])

  const [selectedId, setSelectedId] = useState();
  const [emptyError, setEmptyError] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [localStoreData, setLocalStoreData] = useState();
  const [cityName, setCityName] = useState(
    Object.keys(lcityName).length ? lcityName?.name : draftData?.city_id?.name
  );
  const [showCityField, setShowCityField] = useState(true);
  const [time, setTime] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const [draftTime, setDraftTime] = useState();
  const [citySearchVal, setCitySearchVal] = useState();
  const [isCountryEmpty, setIsCountryEmpty] = useState(false);
  const activeTabName = lStorage.get("tabName")
  const centerId = lStorage.get("center-id")
  const [srcmIdExists, setSrcmIdExists] = useState(false);

  useEffect(() => {
    const userRoleCenterId = JSON.parse(localStorage.getItem('userRoleCenterId')) || [];
    const exists = userRoleCenterId.some(item => item.srcm_group_id === Number(centerId));
    setSrcmIdExists(exists);
  }, [centerId]);

  const selectedCityData = {
    city_id: fetchCityData?.c_id,
    country_id: fetchCityData?.country_id,
    state_id: fetchCityData?.state_id,
  };

  useEffect(() => {
    const updatedData = lStorage.get("formData");
    const draftVal = lStorage.get("formDatadraft");
    const activeSinceDate =
      Object.keys(draftVal).length !== 0
        ? draftVal?.active_since
        : updatedData?.active_since;
    setDraftTime(activeSinceDate);
    setLocalStoreData(updatedData);
  }, []);

  const getDefaultValue = () => {
    const draftVal = lStorage.get("formDatadraft");
    const updatedData =
      Object.keys(draftVal).length !== 0 ? draftVal : draftData;

    return {
      center_type: updatedData ? updatedData?.center_type : "ashram",
      number_can_accommodate: updatedData
        ? updatedData?.number_can_accommodate
        : "",
      postal_code: updatedData ? updatedData?.postal_code : "600125",
      session_type: updatedData ? updatedData?.session_type : "regular",
      street: updatedData ? updatedData?.street : "",
      street2: updatedData ? updatedData?.street2 : "",
      street3: updatedData ? updatedData?.street3 : "",
      // total_meditation_area: updatedData ? updatedData?.total_meditation_area : "",
      total_meditation_area: updatedData && updatedData.total_meditation_area !== 0 && updatedData.total_meditation_area !== "" ? updatedData.total_meditation_area : "",
      property_type: updatedData ? updatedData?.property_type : "residential",
      ownership: updatedData ? updatedData?.ownership : "own",
      active_since: updatedData ? updatedData?.active_since : "",
      stay_facility: updatedData ? updatedData?.stay_facility : "",
    };
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValue(),
  });

  const requiredFields = ["street", "city_id"];

  const inlineErrorValidation = (fieldName) => {
    setEmptyError(emptyError?.filter((errorField) => errorField !== fieldName));
  };

  const validateData = (data) => {
    const emptyError = requiredFields?.filter(
      (reqField) => !Boolean(data[reqField])
    );
    setEmptyError(emptyError);
    return emptyError;
  };

  const draftDataId = lStorage.get("draftId");

  useEffect(() => {
    if (draftDataId) {
      setIsDraft(true);
    }
  }, [draftDataId]);

  const id = useParams();

  const draftFunctionCalled = (activeCenterDataVals) => {
    lStorage.set("formDatadraft", activeCenterDataVals);
  };

  const onSubmit = (data) => {
    setIsSubmit(true);
    setDisableButton(true);
     if(fetchCityData?.c_id === undefined){
       toast.error("Please select the City");
       setDisableButton(false);
       return false;
     }
    if (
      isDraft === true &&
      validateData({ ...data, city_id: fetchCityData?.c_id })?.length === 0
    ) {
      

      const payloadToSend = {
        ...data,
        active_since: time?moment(time).format("YYYY-MM-DD"):draftData.active_since,
        city_id: fetchCityData?.c_id,
        country_id: selectedCountryData?.value,
        state_id: fetchCityData?.state_id,
        street2: data?.street2 ? data?.street2 : null,
        street3: data?.street3 ? data?.street3 : null,
        number_can_accommodate: data?.number_can_accommodate
          ? data?.number_can_accommodate
          : null,
        stay_facility: data?.stay_facility ? data?.stay_facility : null,
        total_meditation_area: data?.total_meditation_area
          ? data?.total_meditation_area
          : null,
      };

      const newFormDataValues = lStorage.get("formDatadraft");

      const newlocalStoreData =
        Object.keys(newFormDataValues).length !== 0
          ? newFormDataValues
          : localStoreData;

      const CombinedFormData = { ...newlocalStoreData, ...payloadToSend };

      draftFunctionCalled(CombinedFormData);

      const selectedCenterid = draftData?.id;
      const draftCenterId = id?.id;

      updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
        .then((res) => {
          if (res) {
            setDisableButton(false);
            toast.success("Details Updated");
          }
        })
        .catch((err) => {
          setDisableButton(false);
          toast.error("Something went wrong please try again later");
        });
    }

    if (
      isActiveCenter === true &&
      validateData({ ...data, city_id: fetchCityData?.c_id })?.length === 0
    ) {
      const payloadToSend = {
        ...data,
        active_since: time?moment(time).format("YYYY-MM-DD"):draftData.active_since,
        city_id: fetchCityData?.c_id,
        country_id: selectedCountryData?.value,
        state_id: fetchCityData?.state_id,
        street2: data?.street2 ? data?.street2 : null,
        street3: data?.street3 ? data?.street3 : null,
        number_can_accommodate: data?.number_can_accommodate
          ? data?.number_can_accommodate
          : null,
        stay_facility: data?.stay_facility ? data?.stay_facility : null,
        total_meditation_area: data?.total_meditation_area
          ? data?.total_meditation_area
          : null,
      };

      const newFormDataValues = lStorage.get("formDatadraft");

      const newlocalStoreData =
        Object.keys(newFormDataValues).length !== 0
          ? newFormDataValues
          : localStoreData;

      const CombinedFormData = { ...newlocalStoreData, ...payloadToSend };

      draftFunctionCalled(CombinedFormData);

      const selectedCenterid = draftData?.id;
      const draftCenterId = id?.id;

      updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
        .then((res) => {
          if (res) {
            setDisableButton(false);
            lStorage.set("successMessage","Details Updated");
            setTimeout(() => {
              navigate(
                `/meditationPlace/${id?.id}/${draftData?.id}`
              );
            }, 1000);
          }
        })
        .catch((err) => {
          setDisableButton(false);
          toast.error("Something went wrong please try again later");
        });
    }

    if (validateData({ ...data, city_id: fetchCityData?.c_id })?.length === 0) {
      const jsonObj = {
        ...data,
        active_since: time?moment(time).format("YYYY-MM-DD"):draftData.active_since? draftData.active_since:moment(new Date()).format("YYYY-MM-DD"),
        city_id: null || selectedCityData?.city_id,
        country_id: selectedCountryData?.value,
        state_id: selectedCityData?.state_id,
        street2: data?.street2 ? data?.street2 : null,
        street3: data?.street3 ? data?.street3 : null,
        number_can_accommodate: data?.number_can_accommodate
          ? data?.number_can_accommodate
          : null,
        stay_facility: data?.stay_facility ? data?.stay_facility : null,
        total_meditation_area: data?.total_meditation_area
          ? data?.total_meditation_area
          : null,
      };
      const CombinedFormData = { ...localStoreData, ...jsonObj };     
      if(isDraft || isActiveCenter === true){
        lStorage.set("formData", CombinedFormData);
      }else{
        lStorage.set("formData", CombinedFormData);
        lStorage.set("tempFormData", CombinedFormData);
      }
      
      navigate(isActiveCenter ? `` : `/locationSelection/${selectedId}`, {
        state: { data: draftData },
      });
    }
  };

  useEffect(() => {
    setSelectedId(id?.id);
  }, [id]);

  const setCityField = () => {
    setFetchCityData({});
    setShowCityField(true);
    
  };

  useEffect(() => {
    if (cityName) {
      setShowCityField(false);
    }
  }, [cityName]);

  const handCountryFieldChange = (countryData, defaultCountryData) => {   
       setSelectedCountryData(countryData ? countryData : defaultCountryData);
    
  };

  // eslint-disable-next-line
  useEffect(() => {       
    if (selectedCountryData !== undefined) {
      setIsCountryEmpty(false);
    }
  });

  useEffect(() => {
    const isCityObjEmpty =
      Object.keys(lcityName).length === 0 && lcityName.constructor === Object;

    if ((citySearchVal !== undefined || null) && isCityObjEmpty === true) {
      setIsCountryEmpty(true);
    } else {
      setIsCountryEmpty(false);
    }
    // eslint-disable-next-line
  }, [citySearchVal]);

  return (
    <>
      <ToastContainer />

      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              COUNTRY <span style={{ color: "red"}}>*</span>
            </p>
            {isCountryEmpty && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
                Please Select Country
              </span>
            )}
            <CountrySearch
              handCountryFieldChange={handCountryFieldChange}
              lcityName={lcityName}
            />{" "}
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              CITY <span style={{ color: "red"}}>*</span>
            </p>

            {emptyError?.indexOf("city_id") > -1 && isSubmit && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "} Field Required
              </span>
            )}
            {showCityField && (
              <CitySearch
                onSearchValueChange={(ev) => {
                  setCitySearchVal(ev);
                  inlineErrorValidation("city_id");
                }}
                onCitySelect={(file) => {
                  setFetchCityData(file);
                  setCityName(file?.name);
                }}
                selectedCountryData={selectedCountryData}
              />
            )}

            {!showCityField && (
              <Input
                onClick={() => {
                  setCityField();
                }}
                value={cityName}
                className="form-input-field-border"
              />
            )}
          </div>

          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              HOUSE/FLAT NO <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.street && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.street?.message}
              </span>
            )}
              {errors?.street?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="street"
              control={control}
              rules={{
                pattern: /^\S.*(?:\r?\n\S.*)*$/,
                required: {
                  value: true,
                  message: " Field Required",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  onKeyDown={() => inlineErrorValidation("street")}
                  placeholder=""
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>

          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              STREET <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.street2 && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.street2?.message}
              </span>
            )}
              {errors?.street2?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="street2"
              control={control}
              rules={{
                pattern: /^\S.*(?:\r?\n\S.*)*$/,
                required: {
                  value: true,
                  message: " Field Required",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="street number"
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>

          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              LANDMARK
              {/* LANDMARK <span style={{ color: "red"}}>*</span> */}
            </p>
            {/* {errors?.street3 && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
                {errors?.street3?.message}
              </span>
            )}
             {errors?.street3?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )} */}
            <Controller
              name="street3"
              control={control}
              rules={{
                pattern: /^[^\s].*$/,
                // required: {
                //   value: true,
                //   message: " Field Required",
                // },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder=""
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>
          

          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              POSTAL CODE <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.postal_code && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
                {errors?.postal_code?.message}
              </span>
            )}
             {errors?.postal_code?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid postal code
              </span>
            )}
            <Controller
              name="postal_code"
              rules={{
                pattern: /^\S.*(?:\r?\n\S.*)*$/,
                required: {
                  value: true,
                  message: " Field Required",
                },
              }}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder=""
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>

          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              SITTING CAPACITY
            </p>
            {errors?.number_can_accommodate && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.number_can_accommodate?.message}
              </span>
            )}
            <Controller
              name="number_can_accommodate"
              control={control}
              rules={{
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Numeric values only",
                },
                min: {
                  value: 1,
                  message: "Value must be greater than zero",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Number of people Meditation Place can accomodate"
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>

          <div className="input-field-container">
            <p className="inpul-label" style={{ display: "inline-block" }}>
              STAY FACILITY
            </p>
            {errors?.stay_facility?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="stay_facility"
              control={control}
              rules={{
                pattern: /^[^\s].*$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Stay Facilities"
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label">
              REGISTRATION DATE OR ACTIVE SINCE DATE
            </p>
            <div className="date-picker-wrapper">
              <DatePicker
                wrapperClassName="datepicker"
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                onChange={(date) => {
                  setTime(new Date(date));
                }}
                selected={
                  time
                    ? time
                    : draftTime
                    ? new moment(
                        draftTime + "T" + moment(new Date()).format("HH:mm A"),
                        "YYYY-MM-DDTHH:mm"
                      ).utc()?._d
                    : new Date()
                }
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              MEDITATION SESSION TYPE <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.session_type && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
               Field Required
              </span>
            )}
            <Controller
              name="session_type"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    {
                      label: "Weekly / Sunday (+ Mid-week/Wed, if any)",
                      value: "weekly",
                    },
                    { label: "Mid-week / Wednesday only", value: "midweek" },
                    { value: "c-connect", label: "C-connect" },
                    { value: "g-connect", label: "G-connect" },
                    { value: "s-connect", label: "S-connect" },
                    { value: "u-connect", label: "U-connect" },
                    { value: "v-connect", label: "V-connect" },
                    { value: "other", label: "Other" },
                  ]}
                  style={{ width: "100%" }}
                />
              )}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              TOTAL MEDITATION AREA (sq. ft)
            </p>
            {errors?.total_meditation_area && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.total_meditation_area?.message}
              </span>
            )}
            <Controller
              name="total_meditation_area"
              control={control}
              rules={{
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Numeric values only",
                },
                min: {
                  value: 1,
                  message: "Value must be greater than zero",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Meditation Hall area in square feet"
                  autoComplete="nope"
                />
              )}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              LOCATION TYPE <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.center_type && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
                Field Required
              </span>
            )}
                <Controller
                  name="center_type"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => {
                    const options =  activeTabName === 'ListCenters' || srcmIdExists
                      ? [
                          { value: "ashram", label: "Ashram" },
                          { value: "retreat", label: "Retreat" },
                          { value: "residence", label: "Residence" },
                          { value: "public", label: "Rented" },
                          { value: "restricted", label: "Restricted" },
                          { value: "meditationhall", label: "Meditation Hall" },
                          { value: "crest", label: "Crest" },
                        ]
                      : [
                          { value: "ashram", label: "Ashram" },
                          { value: "residence", label: "Residence" },
                          { value: "public", label: "Rented" },
                          { value: "restricted", label: "Restricted" },
                          { value: "meditationhall", label: "Meditation Hall" },
                        ];

                    return (
                      <Select
                        {...field}
                        options={options}
                        style={{ width: "100%" }}
                      />
                    );
                  }}
                />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              PROPERTY TYPE <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.property_type && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
               Field Required
              </span>
            )}
            <Controller
              name="property_type"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    { value: "residential", label: "Residential" },
                    { value: "agricultural", label: "Agricultural" },
                    { value: "commercial", label: "Commercial" },
                    { value: "community", label: "Community" },
                  ]}
                  style={{ width: "100%" }}
                />
              )}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              OWNERSHIP <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.ownership && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {" "}
                Field Required
              </span>
            )}
            <Controller
              name="ownership"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    { value: "own", label: "Mission Owned" },
                    { value: "rent", label: "Rent" },
                    { value: "lease", label: "Long Lease" },
                    { value: "free", label: "Free" },
                    { value: "other", label: "Other" },
                  ]}
                  style={{ width: "100%" }}
                />
              )}
            />
            <span style={{ color: "#92928e", fontSize: "12px" }}>Relationship of the property to the mission</span>
          </div>
          <button
            className="new-med-center-next-btn"
            style={{ border: "none" }}
            type="submit"
            disabled={disableButton} 
          >
            {isDraft || isActiveCenter === true ? "Save " : "Next"}
          </button>
        </form>
      </div>
    </>
  );
};

export default NewMeditationCenter;
