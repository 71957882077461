import { lStorage } from '../utils/storage';
import configData from "../assets/config";
import { logout } from "../utils/login";

const interceptor = ax => {
      ax.interceptors.request.use(
        config => {
          const token = lStorage.get('authToken')
            ? lStorage.get('authToken')
            : null;
    
          const HeaderConfig = config;
    
          HeaderConfig.headers = {
            Authorization: `Bearer ${token}`,
            'x-client-id' : `${configData.xclientid}`,
          };
          return HeaderConfig;
        },
    
        error => {
          Promise.reject(error);
        }
      );

      ax.interceptors.response.use(
        (next) => Promise.resolve(next),
        (error) => {
          if (error.response && error.response.status === 401) {

            if(lStorage.get("heartsApp")!==1) {
              alert("Session expired");
              logout();
              setTimeout(() => {
                return window.location.href = '/';
              }, 1000);
            }else{

              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: "EXPIRED_TOKEN" }),
                );
              }    
              // eslint-disable-next-line consistent-return
              window.addEventListener("message", (message) => {
                if (message.data.token) {
                  lStorage.set("authToken", message.data.token);
                  // eslint-disable-next-line no-param-reassign
                  error.config.headers = {
                    Authorization: `Bearer ${message.data.token}`,
                    "x-client-id":  `${configData.xclientid}`,
                  };
                  return ax.request(error.config);
                }
              });

            }
        
          } 
          return Promise.reject(error);
        },
      );

    };
    
    export default interceptor;
    