import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../../assets/styles/pages/spinner.scss'

export default function CircularIndeterminate() {
  return (
    <Box className='sinner-container'
    >
      <CircularProgress />
    </Box>
  );
}
