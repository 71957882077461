import ax from '../../index';
import { lStorage } from "../../../utils/storage";

const centerId = lStorage.get('center-id');
const srcm_group_id = lStorage.get('srcm_group_id');

const newMeditationCenterService = {
  meditationCenterCreation: (payload ,selectedId)=> ax.post(`v2/groups/${selectedId !== undefined || null ? selectedId : srcm_group_id }/meditation-centers/`, payload),
 
  meditationTimeCreation : payload => ax.post(`v2/meditation-centers/meditation-timings/`, payload),

  rejectCenterCreation : (id,draftCenterId) => ax.post(`v2/groups/${id}/meditation-centers/${draftCenterId}/cancel/`),

  approveCenterCreation : (id,draftCenterId) => ax.post(`v2/groups/${id}/meditation-centers/${draftCenterId}/submit/`),

  approvePendingCenterCreation : (id,draftCenterId) => ax.post(`v2/groups/${id}/meditation-centers/${draftCenterId}/approve/`),

  rejectPendingCenterCreation: (payload, id, draftCenterId) => ax.post(`v2/groups/${id}/meditation-centers/${draftCenterId}/reject/`, payload),

  meditationCenterUpdation: (payload ,selectedId)=> ax.patch(`v2/groups/${centerId}/meditation-centers/${selectedId}/`, payload),

  meditationTimeUpdation : (payload ,selectedId) => ax.patch(`v2/meditation-centers/meditation-timings/${selectedId}/`, payload),

  getMeditationTimings : ( selectedId ) => ax.get(`v2/meditation-centers/meditation-timings/?meditation_center_id=${selectedId}`),

  updateMeditationCenter : (payload ,selectedId, draftCenterId)=> {
    return ax.patch(`v2/groups/${draftCenterId}/meditation-centers/${selectedId}/`, payload)},

  meditationIsDuplicate : (payload, id) => ax.post(`v2/groups/${id}/meditation-centers/is_duplicate/`, payload),

  checkingCoordinators : ( selectedId ) => ax.get(`v2/groups/${selectedId}/has-assignment/`),
};

export const { meditationCenterCreation, meditationTimeCreation ,rejectCenterCreation, approveCenterCreation, approvePendingCenterCreation, meditationCenterUpdation, meditationTimeUpdation , getMeditationTimings, updateMeditationCenter, rejectPendingCenterCreation, meditationIsDuplicate, checkingCoordinators} = newMeditationCenterService;
