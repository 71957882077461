import React, { useEffect, useState } from "react";
import AddIcon from "../../../../../assets/images/add-icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {
  meditationTimeCreation,
  meditationCenterCreation,
  approveCenterCreation,
  meditationTimeUpdation,
  getMeditationTimings,
  updateMeditationCenter,
} from "../../../../../services/meditationCenter/newMeditationCenter";
import { lStorage } from "../../../../../utils/storage";
import Spinner from '../../../../spinner'
import "../../../../../assets/styles/pages/timeList.scss"
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from "react-bootstrap";

const SelectedTimeList = () => {
  const { state } = useLocation();
  const draftData = state?.data

  const navigate = useNavigate();
  const centerId = lStorage.get("center-id");
  const activeMenuName = lStorage.get("tabName");
  const draftFormData = lStorage.get("formData")
  const [disableButton, setDisableButton] = useState(false);
  const [newMeditationCenterTime, setNewMeditationCenterTime] = useState();
  const [newMeditationCenterData, setNewMeditationCenterData] = useState();
  const [loading, setLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [showDraftSubmit, setShowDraftSubmit] = useState(false);

  const getLocalStorage = (key) => {
    return lStorage.get(key);
  };

  const draftMapData = draftData?.meditation_timings?.map((item => {
    return {
      id: item?.id,
      meditation_time: moment(item?.meditation_time).format("hh:mm:ss"),
      day_of_week: item?.day_of_week,
      meditation_center_id: item?.meditation_center_id,
      special_notes: item?.special_notes
    }
  }))

  let navigateTo = '';

  if(activeMenuName === "ListCenters"){
    navigateTo = `/meditationCenter/${centerId}`;
  }else{
    navigateTo = "/MymediCenters";
  }

  const submitData = (sendForApproval = false) => {
    setLoading(true)
    setDisableButton(true);
    const scrmGroupId =Number(lStorage.get('center-id'));
    if (isDraft === !true && (scrmGroupId !== undefined || null)) {
      meditationCenterCreation({
        ...newMeditationCenterData,
        srcm_group_id: Number(centerId),
      }, scrmGroupId).then((res) => {
      
        if (res?.statusText === "Created") {
          setDisableButton(false);
          const obj = {}
          lStorage.set("formData", obj);
          lStorage.set("medetationDateTime", []);
          lStorage.set("cityName", obj);
          lStorage.set("tempFormData", obj);       
          lStorage.set("tempCityName", obj);
          lStorage.set("tempMedetationDateTime", []); 
          const medTimeCreationData = newMeditationCenterTime?.map((data) => {
            return {
              day_of_week: data?.day_of_week,
              meditation_center_id: res?.data?.id,
              meditation_time: data?.meditation_time,
              special_notes: data?.special_notes,
            }
          })

          const myFunction = (item) => {
              meditationTimeCreation(item).then((res) => {
              //  setLoading(false)
                // toast.success("Meditation Time Added");
              }).catch((err) => {
                setLoading(false)
              //  toast.error("Something went wrong please try again later");
                navigate(navigateTo)
              });
          }
          medTimeCreationData.forEach(myFunction);

          if (sendForApproval) {
            approveCenterCreation(scrmGroupId, res?.data?.id)
              .then(() => {
                lStorage.set("newCenterCration", 2);
                lStorage.set("activeTab", "Pending");
                setLoading(false);
                setDisableButton(false);
                navigate(navigateTo);
              })
              .catch(() => {
                setLoading(false);
                setDisableButton(false);
                toast.error("Something went wrong please try again later");
              });
          }else{
              lStorage.set("newCenterCration", 1);
              lStorage.set("activeTab", "Draft");
              setLoading(false);
              setDisableButton(false);
              navigate(navigateTo);
          }
        }
      }).catch((err) => {
            setLoading(false)
            setDisableButton(false);
            const obj = {}
            lStorage.set("formData", obj);
            lStorage.set("medetationDateTime", []);        
            lStorage.set("cityName", obj);
        if (err?.response?.status === 401) {
            toast.error("Session expired");
        }else{
            toast.error("Something went wrong please try again later");
        }
        setTimeout(() => { navigate(navigateTo) }, 1000)
      });
    }
  };

  const closeDraftSubmit = () => {
    setShowDraftSubmit(false);
  };

  const draftDataId = lStorage.get("draftId")
  const selectedActiveCenterId = lStorage.get("selectedActiveCenterId")

  useEffect(() => {
    if (draftDataId || selectedActiveCenterId) {
      setIsDraft(true);
    }
  }, [draftDataId, selectedActiveCenterId])


  const editSelected = (selectedTime) => {
    if (isDraft === true) {
      lStorage.set("isDraftEdit", selectedTime?.active);
    }
    navigate(`/timeSelection/:id=${selectedTime.id}`, { state: { data: draftMapData, draftTiming: selectedTime } });
  };


  const deleteSelected = (selectedTime) => {
    const storedData = lStorage.get("medetationDateTime") || [];
    const updatedData = storedData.filter(item => item.id !== selectedTime.id);
    lStorage.set("medetationDateTime", updatedData);
    if (updatedData.length === 0) {
      navigate(`/addMeditationTiming/${centerId}`);
    }else{
      window.location.reload();
    }
  };

  const meditationTimeStatusUpdate = (selectedTime) => {
    setLoading(true)
    const updateTimePayLoad = {
      id:selectedTime.id,
      meditation_time:selectedTime.meditation_time,
      day_of_week:selectedTime.day_of_week,
      meditation_center_id:selectedTime.meditation_center_id,
      special_notes:selectedTime.special_notes,
      active : !selectedTime.active,
    }
    meditationTimeUpdation(updateTimePayLoad, selectedTime.id)
    .then((res) => {
      getMeditationTimings(selectedTime.meditation_center_id)
      .then((res) => {
        lStorage.set("medetationDateTime", res?.data?.results);
        lStorage.set("meditationDay", res?.data?.results);
      })
     
      const draftCenterId = draftFormData?.srcm_group_id.id;
      const payloadToSend = {
        contact_person_name: draftFormData?.contact_person_name ? draftFormData?.contact_person_name : null,
      };
        updateMeditationCenter(payloadToSend, selectedTime.meditation_center_id, draftCenterId)
          .then((res) => {
            if (res) {
                window.location.reload();
         //     toast.success("Details Updated");
            }
          })
          .catch((err) => {
         //   setDisableButton(false);
           // toast.error("Something went wrong please try again later");
          });
          toast.success("Meditation Time Status Updated", {
            autoClose: 4000, 
          });
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong please try again later");
    })
  };

  useEffect(() => {

    if(lStorage.get("errorMessage") !== undefined && lStorage.get("errorMessage")!==""){
      toast.error(lStorage.get("errorMessage"));
      lStorage.set("errorMessage","")
    }
    if(lStorage.get("successMessage") !== undefined && lStorage.get("successMessage")!==""){
      toast.success(lStorage.get("successMessage"));
      lStorage.set("successMessage","")
    }
    const CombinedFormData = lStorage.get("formData");
    setNewMeditationCenterData(CombinedFormData);
    const newMeditationTime = lStorage.get("medetationDateTime")
    setNewMeditationCenterTime(newMeditationTime);
  }, []);

  useEffect(() => {
    if (draftMapData) {
      lStorage.set("medetationDateTime", draftMapData);
    }
  }, [draftMapData])

  const draftSaveBtn = () => {
    toast.success("Record Updated");
    setTimeout(() => {
      navigate(navigateTo)
    }, 1000);
  }
  return (
    <>
      <ToastContainer />
  
      {loading && <Spinner />}
      {!loading &&
        <>
          <div className="timings-list-main-container">
            <div className="" style={{ width: "100%" }}>
            <div className="time-selection-main-container1" >
              {getLocalStorage("medetationDateTime")?.map((selectedTime) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textTransform: "capitalize",
                    }}
                  >
                    <div className="time-div">
                      {moment(
                        new moment(
                          moment(new Date()).format("YYYY:MM:DD") +
                            "T" +
                            selectedTime.meditation_time,
                          "YYYY-MM-DDThh:mm"
                        ).utc()?._d
                      ).format("hh:mm A")}{" "}
                      {selectedTime?.day_of_week}{" "}
                      {isDraft && (
                        <span
                        style={{
                           color:
                           selectedTime?.active === false
                               ? "red"
                               : "rgb(140, 203, 71)",                          
                         }}
                        >({selectedTime?.active ? "Active" : "Inactive"})</span>
                      )}
                      {selectedTime?.special_notes && (<><br/><span>Note : <span style={{fontWeight:"400"}}>{selectedTime?.special_notes}</span></span></>)}
                    </div>
                    <div className="img-edit" 
                        style={{
                          display: "flex",
                        }}
                    >
                      <div
                        onClick={() => editSelected(selectedTime)}
                        title="Edit"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-pencil-fill"
                          viewBox="0 0 16 16"
                          style={{
                            color: "#2cb4ff",
                            cursor: "pointer",
                            marginRight: "15px",
                          }}
                        >
                          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>
                      </div>
                      {!isDraft && (
                      <div
                        onClick={() => deleteSelected(selectedTime)}
                        title="Delete"
                      >
                      <svg 
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-x-lg"
                          viewBox="0 0 16 16"
                          style={{
                            color: "#ff4d4f",
                            cursor: "pointer",
                          }}
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                        </svg>
                      </div>
                      )}
                      {isDraft && (
                         <Button
                         onClick={() => meditationTimeStatusUpdate(selectedTime)}
                         style={{
                           backgroundColor:
                           selectedTime?.active === true
                               ? "red"
                               : "rgb(140, 203, 71)",
                           border:
                           selectedTime?.active === true
                               ? "1px solid red"
                               : "1px solid rgb(140, 203, 71)",
                          margin: "-10px",
                         }}
                       >
                         {selectedTime?.active === true
                           ? "Deactivate"
                           : "Activate"}
                       </Button>
                      )}
                    </div>
                  </div>
                );
              })}
              </div>
  
             
                {!isDraft ? (
                  <>
                  <div className="time-list-form-save-btn" type="submit" style={{background:"#fff"}} >
                    <button
                      // className="rec-done-btn"
                      disabled={disableButton}
                      onClick={() => submitData(false)}
                      style={{
                        background: "#2db5ff",
                        width: "100%",
                        margin: "10px",
                        height: "40px",
                        border: "none"
                      }}
                    >
                      Save as draft
                    </button>
                    <button
                      // className="rec-done-btn"
                      disabled={disableButton}
                      onClick={() => {
                        setShowDraftSubmit(true);
                      }}
                      style={{
                        background: "#2db5ff",
                        width: "100%",
                        margin: "10px",
                        height: "40px",
                        border: "none"
                      }}
                    >
                      Send for approval
                    </button>
                    </div>
                  </>
                ) : (
                  <>
                   <div className="time-list-form-save-btn" type="submit">
                    <button
                      className="rec-done-btn"
                      disabled={disableButton}
                      onClick={() => draftSaveBtn()}
                    >
                      Save
                    </button>
                    </div>
                  </>
                )}
             
  
              <br />
              <div>
                <img
                  className="add-img"
                  src={AddIcon}
                  alt="add-icon"
                  onClick={() => {
                    navigate("/timeSelection", { state: { data: draftData } });
                    lStorage.set("isDraftEdit", false);
                  }}
                  title="Add"
                />
              </div>
            </div>
            

           
          </div>

        </>
      }
  
      <Modal show={showDraftSubmit} centered>
        <Modal.Header>
          <Modal.Title>Submit Meditation Place</Modal.Title>
          <Button
            variant="close"
            onClick={() => {
              closeDraftSubmit();
            }}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to submit?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={closeDraftSubmit}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={disableButton}
            onClick={() => {
              closeDraftSubmit();
              submitData(true); // Call submitData with approval
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  
};

export default SelectedTimeList;
