import ax from '../../index';

const recordAttendeceListService = {
  getRecordAttendenceList: (page, page_size, selectedId) => ax.get(`v2/meditation-centers/meditation-attendance/?meditation_center_id=${selectedId}&page=${page}&page_size=${page_size}`),
 
  postrecordAttendenceData: payload => ax.post(`v2/meditation-centers/meditation-attendance/`, payload ),

  editSelectedAttendance: (payload , selectedId )=> ax.patch(`v2/meditation-centers/meditation-attendance/${selectedId}/`, payload )

};

export const { getRecordAttendenceList,postrecordAttendenceData,editSelectedAttendance } = recordAttendeceListService;
