import { signOut } from "@heartfulnessinstitute/react-hfn-profile";
import { lStorage } from "./storage";

export const getLoginID = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails && loginDetails.id ? loginDetails.id : null;
};

export const getLoginName = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails && loginDetails.name ? loginDetails.name : "";
};

export const getLoginRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails && loginDetails.role ? loginDetails.role : {};
};

export const getLoginUer = () => {
  const loginDetails = lStorage.get("authInfo");

  return loginDetails
    ? {
        id: loginDetails.id || null,
        name: loginDetails.name || "",
        role: loginDetails.role || {},
        email: loginDetails.email || "",
        phoneNo: loginDetails.phoneNo || "",
        avatar: loginDetails.photo_url || "",
      }
    : {};
};

export const isLoginAuth = () => {
  const loginDetails = lStorage.get("srcm_token");
  if (loginDetails) return true;

  return false;
};

export const logout = () => {
  const heartsApp = lStorage.get("heartsApp");  
  localStorage.clear();
  lStorage.set("heartsApp", heartsApp); ;
  signOut();
};
