import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import MealsList from '../components/meditationCenter/meditationPlace/recordMeals/mealsList'
import DashboardLayout from '../layout/layout'

const MealsListWrapper = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id)
    }, [id, navigate])

    return (
        <DashboardLayout title={"Meals Served"} routeTo={`/recordMeals/${selectedId?.id}/${selectedId?.activeCenterId}`}>
            <MealsList />
        </DashboardLayout>
    )
}

export default MealsListWrapper