import React, { useEffect, useState } from "react";
import AddTimeForm from '../components/meditationCenter/newMeditationCenter/timeSelection'
import DashboardLayout from '../layout/layout'
import { lStorage } from '../utils/storage';

const AddTime = () => {
    const selectedId = lStorage.get('center-id')
    const activeMenuName = lStorage.get('tabName')
    const [route, setRoute] = useState()
    const isActiveCenter = lStorage.get("isActiveCenter");

    useEffect(() => {
        // const centerId = lStorage.get("center-id")
        const existingTimeList = lStorage.get("medetationDateTime");
        if (existingTimeList?.length > 0) {
            setRoute("/selectedTimeList")
        }
    }, [])

    const getRouteTo = () => {
        if (activeMenuName === "MymediCenters") {
        //   return "/MymediCenters";
        return isActiveCenter ?  `/MymediCenters` : route ? route : `/addMeditationTiming/${selectedId}`;
        } 
        return isActiveCenter ?  `/meditationPlace/${selectedId}` : route ? route : `/addMeditationTiming/${selectedId}`
      };

    return (
        <DashboardLayout 
            title={isActiveCenter ? "Add Meditation Timing" : "Meditation Timings"} 

            routeTo={getRouteTo()}
            isSecondaryHeader={isActiveCenter ? false : true}
            isMedTimeActive={isActiveCenter ? false : true}
        >
            <AddTimeForm />
        </DashboardLayout>
    )
}

export default AddTime