import React, { useState, useEffect } from "react";
import { getRecordMealsList } from "../../../../../services/meditationCenter/recordMeals";
import { useNavigate, useParams } from "react-router-dom";
import '../../../../../assets/styles/pages/mealsList.scss'
import Spinner from '../../../../spinner'
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";

const MealsList = () => {
  const navigate = useNavigate();

  const page = 1;
  const page_size = 10;

  const [mealsList, setMealsList] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true)
  const [selectedId, setSelectedId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [listCount , setListCount] = useState();

  const recordPerPage = 10;

  const totalRecords = listCount;

  const pageRange = Math.ceil(totalRecords / 10);

  useEffect(() => {
    if (selectedId) {
      getRecordMealsList(page, page_size, selectedId?.activeCenterId).then((res) => {
        setLoading(false);
        setMealsList(res?.data?.results);
      setListCount(res?.data?.count);
        if (res?.data?.count === 0) {
         // toast.error("No Data Found");
          setIsEmpty(true);
        }
      }).catch((err) => {
        setLoading(false);
        toast.error("Something went wrong please try again later")
        setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
      });
    }
  }, [selectedId, navigate , currentPage]);

  const id = useParams()

  useEffect(() => {
    setSelectedId(id)
  }, [id])

  const editMeals = (item) => {
    navigate(`/recordMeals/${selectedId?.id}/${selectedId?.activeCenterId}`, { state: item })
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getRecordMealsList(pageNumber, page_size, selectedId?.activeCenterId).then((res) => {
      setLoading(false);
      setMealsList(res?.data?.results);
      setListCount(res?.data?.count);
      if (res?.data?.count === 0) {
      //toast.error("No Data Found");
        setIsEmpty(true);
      }
    }).catch((err) => {
      setLoading(false);
      toast.error("Something went wrong please try again later")
      setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
    });
  };

  return (
    <>
      <ToastContainer />

      {loading && <Spinner />}
      {!loading && (
        <>
          {isEmpty && (
            <div className="add-timing-main-container">
              <div className="add-timing-inner-container">
                <div className="add-timing-container">
                  <p style={{ fontSize: "16px" }}>No Records Found</p>
                </div>
              </div>
            </div>
          )}
          {!isEmpty && (
            <>
              <div className="meals-list-container">
                <h5 className="meals-list-title">Recorded</h5>

                {mealsList?.map((item) => {
                  const mealsTime = moment(item?.served_date).format(
                    "DD MMM YYYY"
                  );
                  return (
                    <div className="meals-list-inner-container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h6>{mealsTime}</h6>
                        </div>
                        <div>
                          <div
                            className="pencil-icon-container"
                            onClick={() => editMeals(item)}
                            title="Edit Meal"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="rgb(44, 180, 255)"
                                class="bi bi-pencil-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <p
                        style={{
                          color: "#a1a4a7",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {item.meal_type}: {item.meals_count}
                      </p>
                    </div>
                  );
                })}
                <div>
                  <div className="mt-1 mb-3" style={{ textAlign:"center"}}>
                    <b>Current Page : </b>
                    <span className="ml-2">{currentPage}/{Math.ceil(totalRecords/recordPerPage)}</span>
                  </div>

                  <div className="mg-left-50" style={{display:"flex", justifyContent:"center"}}>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
                <div
                  className="meals-list-container-add-icon"
                  onClick={() =>
                    navigate(
                      `/recordMeals/${selectedId?.id}/${selectedId?.activeCenterId}`
                    )
                  }
                  title="Add New Meal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="#2cb4ff"
                    class="bi bi-plus-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MealsList;
