import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, Select } from "antd";
import { postMealsServedData, editMealsServed } from "../../../../services/meditationCenter/recordMeals";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../../../assets/styles/pages/recordMeals.scss'
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Spinner from '../../../spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RecordMeals = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const requiredFields = ["meals_count"];
  const [time, setTime] = useState();
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false)
  // const [showDatePicker, setShowDatePicker] = useState(false);
  const [emptyError, setEmptyError] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const { control, handleSubmit, formState: { errors }, } = useForm({
    defaultValues: {
      meditation_center_id: state ? state?.meditation_center_id : selectedId?.activeCenterId,
      served_date: "",
      meal_type: state ? state?.meal_type : "breakfast",
      meal_expense_type: 1,
      meals_count: state ? state?.meals_count : 0,
      meals_funded_by: state ? state?.meals_funded_by : "mission",
    },
  });

  const validateData = (data) => {
    const emptyError = requiredFields?.filter(
      (reqField) => !Boolean(data[reqField])
    );
    setEmptyError(emptyError);
    return emptyError;
  };

  const onSubmit = (data) => {
    setIsSubmit(true);
    
    if (validateData(data)?.length === 0) {
      setDisableButton(true);
    if (state?.id !== undefined) {    
      editMealsServed({
        ...data,
        served_date: time?moment(time).format("YYYY-MM-DD"):state.served_date,
      }, state?.id).then((res) => {
        setLoading(false);        
        toast.success("Meal Record Updated")
        setTimeout(() => { navigate(`/mealsList/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
      }).catch((err) => {
        setLoading(false);
        setDisableButton(false);
        toast.error("Meals details already existed");
     //   setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
      });

    } else {
      postMealsServedData({
        ...data,
        served_date: moment(time).format("YYYY-MM-DD"),
        meditation_center_id: selectedId?.activeCenterId,
      }).then((res) => {
        setLoading(false);
        setDisableButton(false);
        toast.success("Meal Record Added")
        setTimeout(() => { navigate(`/mealsList/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
      }).catch((err) => {
        setLoading(false);
        setDisableButton(false);
        toast.error("Meals details already added");
       // setTimeout(() => { navigate(`/meditationPlace/${selectedId?.id}/${selectedId?.activeCenterId}`) }, 1000);
      });
    }
  }
  };
  const id = useParams()

  useEffect(() => {
    setSelectedId(id)
  }, [id])

  // const datePicker = () => {
  //   setShowDatePicker(true);
  // };

  const inlineErrorValidation = (fieldName) => {
    setEmptyError(emptyError?.filter((errorField) => errorField !== fieldName));
  };

  const editDate = new moment(moment(moment(state?.served_date).format("DD-MMM-YYYY")).format("YYYY:MM:DD") + "T" + new Date().toLocaleTimeString(),"YYYY-MM-DDTHH:mm").utc()?._d

  return (
    <>
      <ToastContainer />

      {
        loading && <Spinner />
      }
      {!loading &&
        <div className="rec-meals-form-container">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="rec-meals-container"
          >
            {/* {!showDatePicker && (
              <div
                onClick={() => datePicker()}
                style={{ borderBottom: "1px solid #ccc", marginBottom: '10px' }}
              >
                <p className="impul-label">RECORDING MEALS ON</p>
                {moment(
                  state?.served_date
                ).format("DD-MMM-YYYY")}
              </div>)} */}

            {/* {showDatePicker &&  */}
            <div className="rec-meals-input-field-container">
              <p className="impul-label">RECORDING MEALS ON</p>
              <div className="date-picker-wrapper">
                <DatePicker
                  wrapperClassName="datepicker"
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  maxDate={new Date()} 
                  onChange={(date) => setTime(new Date(date))}
                  selected={time ? time : editDate || new Date()}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            {/* } */}
            <div className="rec-meals-input-field-container">
              <p className="impul-label" style={{ display: "inline-block" }}>MEALS SERVED <span style={{ color: "red"}}>*</span></p>
              {emptyError?.indexOf("meals_count") > -1 && isSubmit && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {" "}
                    Field Required
                  </span>
                )}
                {errors?.meals_count && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {errors?.meals_count?.message}
                  </span>
                )}
              <Controller
                name="meals_count"
                rules={{
                  pattern: {
                    value: /^[1-9][0-9]*$/,
                    message: "Numeric values only",
                  },
                }}
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Type your meal served" autoComplete="nope" onKeyDown={() =>
                    inlineErrorValidation("meals_count")
                  }/>
                )}
              />
            </div>

            <div className="rec-meals-input-field-container">
              <p className="impul-label">TYPE <span style={{ color: "red"}}>*</span></p>
              <Controller
                name="meal_type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: "breakfast", label: "Breakfast" },
                      { value: "lunch", label: "Lunch" },
                      { value: "dinner", label: "Dinner" },
                    ]}
                    style={{ width: "100%" }}
                  />
                )}
              />
            </div>

            <div className="rec-meals-input-field-container">
              <p className="impul-label">MEALS<span style={{ color: "red"}}>*</span></p>
              <Controller
                name="meals_funded_by"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: "mission", label: "By mission" },
                      {
                        value: "abhyasi_group",
                        label: "By group of abhyasis",
                      },
                      { value: "abhyasi", label: "By particular abhyasis" },
                    ]}
                    style={{ width: "100%" }}
                  />
                )}
              />
            </div>

            {/* <div className="rec-meals-footer-save" type="submit"> */}
              <button className="rec-meals-footer-save" disabled={disableButton}  style={{border:"none"}} type="submit">Save</button>
            {/* </div> */}
          </form>
        </div>
      }
    </>

  );
};

export default RecordMeals;
