import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropertyDetailsForm from '../components/meditationCenter/newMeditationCenter/propertyDetails'
import DashboardLayout from '../layout/layout'

const AddPropertyDetails = () => {
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState();

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id?.id)
    }, [id, navigate])

    return (
        <DashboardLayout title={"Property Details"} routeTo={`/addNewMeditationCenter/${selectedId}`}>
            <PropertyDetailsForm />
        </DashboardLayout>
    )
}

export default AddPropertyDetails