import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PrivateProperty from "../../../assets/images/PrivatePlace.png";
// import DraftCardImg from "../../../assets/images/draft-card-img.png";
import ResidentialPropertyImg from "../../../assets/images/home-img.png";
import {
  getActiveCenters,
  getFilteredCenterList,
} from "../../../services/meditationCenter/centerList";
import {
  rejectCenterCreation,
  approveCenterCreation,
  approvePendingCenterCreation,
  getMeditationTimings,
  rejectPendingCenterCreation,
} from "../../../services/meditationCenter/newMeditationCenter";
import "../../../assets/styles/pages/meditationCenter.scss";
import { lStorage } from "../../../utils/storage";
import AddBtn from "../../../assets/images/addButton.svg";
import Spinner from "../../spinner";
import "antd/dist/antd.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMeditationCenterCoordinator } from "../../../services/meditationCenter/centerList";
import { Modal, Button } from "react-bootstrap";
import { Input } from "antd";
import CenterSearch from "../../myMeditationCenter/centerSearch";
import Pagination from "react-js-pagination";

const ActiveCenter = () => {
  const { state } = useLocation();
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [isTabChanged, setIsTabChanged] = useState(lStorage.get("activeTab"));
  const [disableButton, setDisableButton] = useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    const selectedActiveTab = lStorage.get("activeTab");
    if (selectedActiveTab !== undefined || null) {
      setIsTabChanged(selectedActiveTab);
    }
    if(lStorage.get("newCenterCration") !== undefined && lStorage.get("newCenterCration")===1){
      toast.success("Meditation place created");
      setActiveTab("Draft");
      lStorage.set("newCenterCration","")
    }else if(lStorage.get("newCenterCration") !== undefined && lStorage.get("newCenterCration")===2){
      toast.success("Meditation place created and Submitted For Approval");
      setActiveTab("Pending");
      lStorage.set("newCenterCration","")
    }
    if(lStorage.get("successMessage") !== undefined && lStorage.get("successMessage")!==""){
      toast.success(lStorage.get("successMessage"));
      lStorage.set("successMessage","")
    }
    if(lStorage.get("errorMessage") !== undefined && lStorage.get("errorMessage")!==""){
      toast.error(lStorage.get("errorMessage"));
      lStorage.set("errorMessage","")
    }

  });

  const [activeCenters, setActiveCenters] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [showEmpty, setShowEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPendingDraft, setShowPendingDraft] = useState(false);
  const [coordinatorName, setCoordinatorName] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [centerDraftId, setCenterDraftId] = useState();
  const [rejectReason, setRejectReason] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateMeditation, setIsCreateMeditation] = useState(false);
  const [activeTab, setActiveTab] = useState(
    isTabChanged ? isTabChanged : "Approved"
  );
  const [centerList, setCenterList] = useState();
  const [showApprove, setShowApprove] = useState(false);
  const [showDraftSubmit, setShowDraftSubmit] = useState(false);
  const [showDraftCancel, setShowDraftCancel] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [errorReject, setErrorReject] = useState("");
  const [centerCount, setCenterCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const trackApprove = () => {
    setShowApprove(false);
  };

  const handleMeditationCentersCancel = () => {
    setIsCreateMeditation(false);
}

  const trackDraftSubmit = () => {
    setShowDraftSubmit(false);
  };
  const trackDraftCancel = () => {
    setShowDraftCancel(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
    const obj = {};    
    lStorage.set("draftData", obj);
    lStorage.set("draftId", "");
    lStorage.set("isActiveCenter", false);
    lStorage.set("meditationDay", []);
    lStorage.set("selectedActiveCenterId", "");
    if (lStorage.get("tempFormData") === '' || lStorage.get("tempFormData") === undefined) {
      lStorage.set("formData", obj);       
      lStorage.set("cityName", obj);
      lStorage.set("medetationDateTime", []);  
      lStorage.set("tempCityName", obj);      
    }else{
      lStorage.set("formData", lStorage.get("tempFormData"));       
      lStorage.set("cityName", lStorage.get("tempCityName"));
      if (lStorage.get("tempMedetationDateTime") === '' || lStorage.get("tempMedetationDateTime") === undefined) {
        lStorage.set("medetationDateTime", []);  
      }else {
      lStorage.set("medetationDateTime", lStorage.get("tempMedetationDateTime"));
      }
    }
    lStorage.set("formDatadraft", obj);
    

   /* if (lStorage.get("draftId") === '' || lStorage.get("draftId") === undefined) {
    //lStorage.set("formData", obj);       
    // lStorage.set("cityName", obj);
    //lStorage.set("medetationDateTime", []);    
    }else{
      lStorage.set("formData", obj);       
      lStorage.set("cityName", obj);
      lStorage.set("medetationDateTime", []);
      lStorage.set("formDatadraft", obj);
    }
    if (lStorage.get("formData") === undefined) {
      lStorage.set("formData", obj);       
      lStorage.set("cityName", obj);
      lStorage.set("medetationDateTime", []);
      lStorage.set("formDatadraft", obj);
    }
   lStorage.set("draftData", obj);
    lStorage.set("draftId", "");
    lStorage.set("isActiveCenter", false);
    lStorage.set("meditationDay", []);
    lStorage.set("selectedActiveCenterId", "");
   */
  };

  const handleOk = () => {
    setIsModalVisible(false);
    lStorage.set("draftData", {});
    lStorage.set("formData", {});
    lStorage.set("cityName", {});
    navigate(`/newMeditationCenter/${selectedId}`);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCenterSelect = (city) => {
    setSelectedCity(city);
};

  const handleAdd = () => {
    if (selectedCity) {
        lStorage.set("draftData", {});
        lStorage.set("draftId", "");
        lStorage.set("isActiveCenter", false);
        lStorage.set("meditationDay", []);
        lStorage.set("selectedActiveCenterId","");
        lStorage.set("formData", {});
        lStorage.set("medetationDateTime", []);
        lStorage.set("tempCityName", {});
        lStorage.set("formDatadraft", {});
        lStorage.set("center-id", selectedId);
        navigate(`/newMeditationCenter/${selectedId}`);
        setIsModalVisible(false);
    }
};

  const page = 1;
  const page_size = 20;
  // const pageRange = Math.ceil(centerCount / page_size);

  const routToSelectedActiveCenter = (item) => {
    const cityData = {
      c_id: item?.city_id?.id,
      c_name: item?.city_id?.name,
      country_id: item?.country_id?.id,
      name: item?.city_id?.name,
      state_id: item?.state_id?.id,
    };

    const obj = {};    
    if (lStorage.get("draftId") === '' || lStorage.get("draftId") === undefined) {
    //lStorage.set("formData", obj);       
    // lStorage.set("cityName", obj);
    //lStorage.set("medetationDateTime", []);    
    }else{
      lStorage.set("formData", obj);       
      lStorage.set("cityName", obj);
      lStorage.set("medetationDateTime", []);
      lStorage.set("formDatadraft", obj);
    }
    
    lStorage.set("cityName", cityData);
    lStorage.set("formData", item);
    lStorage.set("draftId", "");
    lStorage.set("formDatadraft", {});

    const selectedDraftId = item?.id;

    getMeditationTimings(selectedDraftId)
      .then((res) => {
        lStorage.set("medetationDateTime", res?.data?.results);
        lStorage.set("meditationDay", res?.data?.results);
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later");
      });

    const selectedCenterLat = item?.latitude;
    const selectedCenterLong = item?.longitude;
    const selectedCenterCoordinates = { selectedCenterLat, selectedCenterLong };
    const jsonObj = JSON.stringify(selectedCenterCoordinates);
    lStorage.set("selectedCenterCoordinates", `${jsonObj}`);
    const selectedID = item?.id;
    lStorage.set("selectedActiveCenterId", `${selectedID}`);
    window.scrollTo(0, 0)
    navigate(`/meditationPlace/${selectedId}/${item?.id}`, {
      state: { activeCenterData: item },
    });
  };

  const cancelDraft = (draftCenterId) => {
    setLoading(true);
    trackDraftCancel();
    setDisableButton(true);
    rejectCenterCreation(Number(selectedId), draftCenterId)
      .then((res) => {
        setLoading(false);
        setShowDelete(false);
        setDisableButton(false);
        toast.success("Meditation Place Cancelled");
        setCenterDraftId("");
        trackDraftCancel();
        // centerListCall();
        setActiveTab("Draft");
        centerListCall();
      })
      .catch((err) => {
        setLoading(false);        
        setDisableButton(false);
        toast.error("Something went wrong please try again later");
      });
  };

  const cancelPending = (draftCenterId) => {
    const reason = rejectReason || '';
    if (!reason.trim()) {
      setErrorReject('Reject reason is required.');
      return; 
    }  
    setDisableButton(true);
    setShowDelete(false);
    const payloadToSend = {
      ...(rejectReason && { remark: rejectReason }),
    };
    setLoading(true);
    rejectPendingCenterCreation(
      payloadToSend,
      Number(selectedId),
      draftCenterId
    )
      .then((res) => {
        setLoading(false);
        toast.success("Meditation Place Rejected");
        setCenterDraftId("");
        trackDraftSubmit();
        setRejectReason("");
        // centerListCall();
        setActiveTab("Draft");
        setDisableButton(false);    
      })
      .catch((err) => {
        setLoading(false);
        setDisableButton(false);
        toast.error("Something went wrong please try again later");
      });
  };

  const approveDraft = (draftCenterId) => {
    setDisableButton(true);
    trackDraftSubmit();
    setLoading(true);
    approveCenterCreation(Number(selectedId), draftCenterId)
      .then((res) => {
        setLoading(false);
        toast.success("Meditation Place Submitted For Approval");
        setCenterDraftId("");
        trackDraftSubmit();
        // centerListCall();
        setActiveTab("Pending");
        setDisableButton(false);    
      })
      .catch((err) => {
        setLoading(false);        
        setDisableButton(false);
        toast.error("Something went wrong please try again later");
      });
  };

  const approvePending = (draftCenterId) => {
    setLoading(true);    
    setShowApprove(false);
    setDisableButton(true);
    approvePendingCenterCreation(Number(selectedId), draftCenterId)
      .then((res) => {
       setLoading(false);        
        setDisableButton(false);
        toast.success("Meditation Place Approved");
        setCenterDraftId("");
        trackApprove();
        // centerListCall();
        setActiveTab("Approved");        
      })
      .catch((err) => {
        setLoading(false);        
        setDisableButton(false);
        toast.error("Something went wrong please try again later");
      });
  };

  const id = useParams();

  useEffect(() => {
    setSelectedId(id?.id);
  }, [id]);

  useEffect(() => {
    const positionType = lStorage.get("positionType");
    if ((state?.positionType === "CC" || positionType === "CC") || 
        (state?.positionType === "MCC" || positionType === "MCC") || 
        (state?.positionType === "ZC" || positionType === "ZC")
  ) {
      setShowPendingDraft(true);
    }
  }, [state]);

  const editDraft = (item) => {
    lStorage.set("selectedActiveCenterId", "");
    lStorage.set("isActiveCenter", false);

    const selectedDraftId = item?.id;
    const obj = {};
    lStorage.set("cityName", obj);

    lStorage.set("draftId", item?.id);
    lStorage.set("formData", item);
    lStorage.set("formDatadraft", obj);

    getMeditationTimings(selectedDraftId)
      .then((res) => {
        lStorage.set("medetationDateTime", res?.data?.results);
        // const activeMeditations = res?.data?.results?.filter(item => item.active === true);
        // lStorage.set("medetationDateTime", activeMeditations);
        navigate(`/newMeditationCenter/${selectedId}`, {
          state: { data: item },
        });
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later");
      });
  };

  const ViewPending = (item) => {
    navigate(`/ViewPendingCenterDetails/${selectedId}/${item.id}`, {
      state: { data: item },
    });
  };

  useEffect(() => {
    getMeditationCenterCoordinator(
      page,
      page_size,
      lStorage.get("center-id")
    ).then((res) => {
      setCoordinatorName(res?.data?.results?.[0]?.partner?.name);
    });
  }, []);

  
  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    const centerType = activeTab?.toLowerCase();
    getFilteredCenterList(pageNumber, page_size, selectedId, centerType)
      .then((res) => {
        setLoading(false);
        setActiveCenters(res?.data?.results);
        setCenterCount(res?.data?.count);
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later");
        navigate("/home");
      });
  }

  const trackClose = () => {
    setShowDelete(false);
  };

  useEffect(() => {
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    if (selectedId) {
      setLoading(true);
      const centerType = activeTab?.toLowerCase();
      getFilteredCenterList(page, page_size, selectedId, centerType)
        .then((res) => {
          setLoading(false);
          setActiveCenters(res?.data?.results);
          setCenterCount(res?.data?.count);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something went wrong please try again later");
          navigate("/home");
        });
    }
  }, [selectedId, activeTab, navigate]);

  useEffect(() => {
    if (selectedId) {
      getActiveCenters(page, page_size, selectedId)
        .then((res) => {
          setLoading(false);
          setCenterList(res?.data?.count);
        })
        .catch((err) => {
          toast.error("Something went wrong please try again later");
          navigate("/home");
        });
    }
  }, [selectedId, navigate]);

  useEffect(() => {
    if (activeCenters.length === 0 && centerList === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
  }, [activeCenters, centerList]);

  const centerListCall = () => {
    if (selectedId) {
      const centerType = activeTab?.toLowerCase();
      getFilteredCenterList(page, page_size, selectedId, centerType)
        .then((res) => {
          setLoading(false);
          setActiveCenters(res?.data?.results);
          setCenterCount(res?.data?.count);
        })
        .catch((err) => {
          toast.error("Something went wrong please try again later");
          navigate("/home");
        });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <>
      <ToastContainer />
      {loading && <Spinner />}
      {!loading && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: isMobile ? "10px 10px" : "16px 30px",
            }}
          >
            <div
              style={{
                padding: isMobile ? "0.5em" : "0.5em",
                borderRadius: "5px",
                border:
                  activeTab === "Approved"
                    ? "3px solid rgb(44, 180, 255)"
                    : "3px solid #808688",
                color: activeTab === "Approved" ? "rgb(44, 180, 255)" : "black",
                cursor: "pointer",
                margin:"0px 10px 0px 0px"
              }}
              onClick={() => {
                setActiveTab("Approved");
                lStorage.set("activeTab", "Approved");
              }}
            >
              <span>
                <b>Active</b>
              </span>
            </div>
            {showPendingDraft && (
              <div
                style={{
                  padding: isMobile ? "0.5em" : "0.5em",
                  borderRadius: "5px",
                  border:
                    activeTab === "Pending"
                      ? "3px solid rgb(44, 180, 255)"
                      : "3px solid #808688",
                  color:
                    activeTab === "Pending" ? "rgb(44, 180, 255)" : "black",
                  cursor: "pointer",
                  margin:"0px 10px 0px 0px"
                }}
                onClick={() => {
                  setActiveTab("Pending");
                  lStorage.set("activeTab", "Pending");
                }}
              >
                {" "}
                <span>
                  <b>Pending Approval</b>
                </span>
              </div>
            )}
            <div
              style={{
                padding: isMobile ? "0.5em" : "0.5em",
                borderRadius: "5px",
                border:
                  activeTab === "Draft"
                    ? "3px solid rgb(44, 180, 255)"
                    : "3px solid #808688",
                color: activeTab === "Draft" ? "rgb(44, 180, 255)" : "black",
                cursor: "pointer",
              }}
              onClick={() => {
                setActiveTab("Draft");
                lStorage.set("activeTab", "Draft");
              }}
            >
              <span>
                <b>Draft</b>
              </span>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            style={{
                                padding: "0.5em",
                                borderRadius: "5px",
                                border: "3px solid rgb(44, 180, 255)",
                                color: "#fff",
                                cursor: "pointer",
                                margin: "0px 10px 0px 0px",
                                fontWeight: 700,
                                backgroundColor:"#2cb4ff"
                            }}
                             onClick={showModal}
                            >
                            Create Meditation Place
                        </button>
            </div>
          {showEmpty && (
            <div className="add-timing-main-container">
              <div className="add-timing-inner-container">
                <div className="add-timing-container">
                  <p style={{ fontSize: "16px" }}>
                    Your Meditation place list is empty
                  </p>
                  <img
                    src={AddBtn}
                    alt="add"
                    className="add-btn"
                    onClick={showModal}
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      color: "#28b3ff",
                    }}
                  >
                    Add Meditation Place
                  </p>
                </div>
              </div>
            </div>
          )}
          {!showEmpty && (
            <div className="main-wrapper-div" style={{ paddingTop: "20px" }}>
              {activeCenters.length === 0 && (
                <div className="add-timing-main-container">
                  <div className="add-timing-inner-container">
                    <div className="add-timing-container">
                      <p style={{ fontSize: "16px" }}>No Result Found</p>
                      <img
                        src={AddBtn}
                        alt="add"
                        className="add-btn"
                        onClick={showModal}
                      />
                      <p
                        style={{
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#28b3ff",
                        }}
                      >
                        Add New Meditation Place
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="outer-container">
                <div>
                  {activeCenters?.map((item) => {
                    if (
                      item?.state === "approved" &&
                      activeTab === "Approved"
                    ) {
                      return (
                        <>
                          <div className="active-center-card-container">
                            <div className="active-center-card-inner-container">
                              <div className="active-center-card-container-wrapper">
                                <div style={{ display: "flex" }}>
                                  <div className="active-center-card-container-para-width" style={{ marginBottom: "10px" }}>
                                    <span>{item?.name}</span>
                                    <p className="active-center-card-container-para">
                                    {item?.street} {item?.street2 && `, ${item?.street2}`}{item?.street3 && `, ${item?.street3}`}{item?.postal_code && `, ${item?.postal_code}`}
                                    </p>
                                  </div>
                                  <div
                                    className="active-center-navigate-arrow"
                                    onClick={() =>
                                      routToSelectedActiveCenter(item)
                                    }
                                    style={{ marginLeft: "auto" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-chevron-right"
                                      viewBox="0 0 16 16"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </div>
                                </div>

                                <div className="active-center-card-container-wrapper-first">
                                  <div className="active-center-card-icon-container">
                                    <img
                                      src={
                                        item.property_type === "residential"
                                          ? ResidentialPropertyImg
                                          : PrivateProperty
                                      }
                                      alt="PrivateProperty"
                                      className="card-icon-new"
                                    />
                                  </div>

                                  <div style={{ color: "#808688" }}>
                                    <p style={{marginTop: "1em", marginBottom: "0em" }}>Contact Person : {item.contact_person_name}</p>
                                    <p>Create Date : {formatDate(item.create_date)}</p>
                                  </div>

                                  <div className="status-container">
                                    <h6
                                      style={{
                                        color:
                                          item.active === true
                                            ? "#8ccb47"
                                            : "#d41a33",
                                      }}
                                    >
                                      <b>
                                        {item.active === true
                                          ? "Active"
                                          : "Inactive"}
                                      </b>{" "}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}

                  {activeCenters?.map((item) => {
                    if (
                      item?.state === "pending" &&
                      showPendingDraft &&
                      activeTab === "Pending"
                    ) {
                      const draftCenterId = item?.id;
                      return (
                        <>
                          <div className="draft-main-container">
                            <div className="draft-card-container">
                              <div className="active-center-card-container-para-width">
                                <span>{item?.name}</span>
                                <p className="active-center-card-container-para">
                                {item?.street} {item?.street2 && `, ${item?.street2}`}{item?.street3 && `, ${item?.street3}`}{item?.postal_code && `, ${item?.postal_code}`}
                                </p>
                              </div>
                              <div className="draft-card-inner-container-box">
                                <div className="draft-inner-card-img-container">
                                  <div>
                                    <img
                                      // src={DraftCardImg}
                                      src={
                                        item.property_type === "residential"
                                          ? ResidentialPropertyImg
                                          : PrivateProperty
                                      }
                                      alt="draft"
                                      className="draft-card-png"
                                    />
                                  <div style={{ color: "#808688" }}>
                                    <p style={{ margin: "0px"}}>Contact Person : {item.contact_person_name}</p>
                                    <p style={{ margin: "0px"}}>Create Date : {formatDate(item.create_date)}</p>
                                  </div>
                                  </div>
                                </div>
                                <div className="draft-cancel-approve-container">
                                  <div
                                    title="View"
                                    onClick={() => ViewPending(item)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="35"
                                      height="35"
                                      fill="currentColor"
                                      className="bi bi-eye-fill"
                                      viewBox="0 0 16 16"
                                      style={{
                                        color: "#2cb4ff",
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                    </svg>
                                  </div>
                                  <div title="Approve">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      className="bi bi-check-circle"
                                      viewBox="0 0 16 16"
                                      style={{
                                        color: "#69f493",
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCenterDraftId(draftCenterId);
                                        setShowApprove(true);
                                      }}
                                    >
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                  </div>
                                  <div title="Reject">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      className="bi bi-x-lg"
                                      viewBox="0 0 16 16"
                                      style={{
                                        color: "#f24d40",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCenterDraftId(draftCenterId);
                                        setShowDelete(true);
                                      }}
                                    >
                                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {activeCenters?.map((item) => {
                    if (item?.state === "draft" && activeTab === "Draft") {
                      const draftCenterId = item?.id;
                      return (
                        <div className="draft-main-container">
                          <div className="draft-card-container">
                            <div  className="active-center-card-container-para-width" style={{ marginTop: "10px" }}>
                              <span>{item?.name}</span>
                              <p className="active-center-card-container-para">
                              {item?.street} {item?.street2 && `, ${item?.street2}`}{item?.street3 && `, ${item?.street3}`}{item?.postal_code && `, ${item?.postal_code}`} 
                              </p>
                            </div>
                            <div className="draft-card-inner-container-box">
                              <div className="draft-inner-card-img-container">
                                <div>
                                  <img
                                    src={
                                      item.property_type === "residential"
                                        ? ResidentialPropertyImg
                                        : PrivateProperty
                                    }
                                    alt="draft"
                                    className="draft-card-png"
                                  />
                                <div style={{ color: "#808688" }}>
                                  <p style={{ margin: "0px"}}>Contact Person : {item.contact_person_name}</p>
                                  <p style={{ margin: "0px"}}>Create Date : {formatDate(item.create_date)}</p>
                                </div>
                                </div>
                              </div>
                              <div className="draft-cancel-approve-container">
                                <div
                                  title="Edit"
                                  onClick={() => editDraft(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    className="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                    style={{
                                      color: "#2cb4ff",
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                                </div>
                                <div title="Submit Draft">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    className="bi bi-check-circle"
                                    viewBox="0 0 16 16"
                                    style={{
                                      color: "#69f493",
                                      marginRight: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setCenterDraftId(draftCenterId);
                                      setShowDraftSubmit(true);
                                    }}
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                  </svg>
                                </div>
                                <div title="Cancel Draft">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    className="bi bi-x-lg"
                                    viewBox="0 0 16 16"
                                    style={{
                                      color: "#f24d40",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setCenterDraftId(draftCenterId);
                                      setShowDraftCancel(true);
                                    }}
                                  >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                  </svg>
                                </div>
                              </div>
                           
                            </div>
                          </div>

                          {item?.remark && (
                            <p>
                              <b>Reason of Rejection : {item?.remark}</b>
                            </p>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              {/* <div
                className="add-icon-container-center-lists"
                onClick={showModal}
                title="Add New Meditation Place"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="#2cb4ff"
                  className="bi bi-plus-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                </svg>
              </div> */}
              ``
            </div>
          )}
          {activeCenters?.length !== 0 && !loading && (
                <div>
                  <div className="mt-1 mb-3" style={{ textAlign:"center"}}>
                    <b>Current Page : </b>
                    <span className="ml-2">{currentPage}/{Math.ceil(centerCount/page_size)}</span>
                  </div>

                  <div className="mg-left-50" style={{display:"flex", justifyContent:"center"}}>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={page_size}
                      totalItemsCount={centerCount}
                      pageRangeDisplayed={6}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
        </>
      )}
      <Modal show={showDelete} centered>
        <Modal.Header>
          <Modal.Title>Reject Meditation Place</Modal.Title>
          <Button variant="close" onClick={trackClose} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to Reject ?</p>
          </div>
          <div>
            <p className="impul-label">Reason<span style={{ color: "red"}}>*</span></p>
            <TextArea
            onChange={(e) => {
              setRejectReason(e.target.value);
              setErrorReject("");
            }}
            value={rejectReason}
          />
          {errorReject && <p style={{ color: 'red' }}>{errorReject}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => cancelPending(centerDraftId)}
             disabled={disableButton}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApprove} centered>
        <Modal.Header>
          <Modal.Title>Approve Meditation Place</Modal.Title>
          <Button variant="close" onClick={trackApprove} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to Approve ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackApprove}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => approvePending(centerDraftId)}
            disabled={disableButton}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDraftSubmit} centered>
        <Modal.Header>
          <Modal.Title>Submit Meditation Place</Modal.Title>
          <Button variant="close" onClick={trackDraftSubmit} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to Submit ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackDraftSubmit}
          >
            Cancel
          </Button>
          <Button variant="primary"  disabled={disableButton} onClick={() => approveDraft(centerDraftId)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDraftCancel} centered>
        <Modal.Header>
          <Modal.Title>Cancel Meditation Place</Modal.Title>
          <Button variant="close" onClick={trackDraftCancel} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to Cancel ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackDraftCancel}
          >
            Cancel
          </Button>
          <Button variant="primary" 
            disabled={disableButton} onClick={() => cancelDraft(centerDraftId)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isModalVisible} centered>
        <Modal.Header>
          <Modal.Title>New Meditation Place Creation</Modal.Title>
          <Button variant="close" onClick={handleCancel} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <h6>Fields Mentioned below are mandatory for creation of Meditation Place</h6>
          <ul>
            <li>Country</li>
            <li>City</li>
            <li>House Number</li>
            <li>Street</li>
            <li>Pincode</li>
            <li>Meditation Session Type</li>
            <li>Location Type</li>
            <li>Property Type</li>
            <li>Ownership</li>
            <li>Meditation Place Name</li>
            <li>Contact Person Name</li>
            <li>Mobile Number</li>
            <li>Email Id</li>
            <li>Active Date</li>
            <li>Direction To Reach</li>
            <li>Facilities</li>
            <li>Meditation Timings</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleOk()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isCreateMeditation} centered>
          <Modal.Header>
              <Modal.Title>Search Meditation Center</Modal.Title>
              <Button variant="close" onClick={handleMeditationCentersCancel} aria-label="Close" />
           </Modal.Header>
                <Modal.Body className="modal-body">
                    <CenterSearch
                        className="center-search"
                        onCitySelect={handleCenterSelect}
                        onSearchValueChange={(ev) => {
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="whiteColor" style={{ color: "black" }} onClick={handleMeditationCentersCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAdd} >Proceed</Button>
                </Modal.Footer>
            </Modal>
    </>
  );
};

export default ActiveCenter;
