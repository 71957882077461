import React from 'react'
import ZoneCenters from '../components/home/zoneMeditationCenter'
import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";

const ActiveZoneCenters = () => {


    const pageTitle = lStorage.get("centerName")

    return (
        <DashboardLayout
            title={`Zone Meditation Center - ${pageTitle}`}
            routeTo={`/home`}
            secondaryHeader={"List of Meditation Centers"}
        >
            <ZoneCenters />
        </DashboardLayout>
    )
}

export default ActiveZoneCenters;