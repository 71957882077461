import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { searchAbhyasi, addAbhyasi } from "../../../services/meditationCenter/centerList";
import { Input } from 'antd';
import "../../../assets/styles/pages/abhyasi.scss";
import { useEffect } from 'react';
import 'antd/dist/antd.css';
import moment from "moment";
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../spinner'
import { lStorage } from "../../../utils/storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Search } = Input;

const AbhyasiSelect = () => {
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [selectedAbhyasi, setSelectedAbhyasi] = useState();
    const [abhyasiIdLength, setAbhyasiIdLength] = useState();
    const [isShowDetails, setShowDetails] = useState(false);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState();
    const [show, setShow] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    const page = 1
    const page_size = 10

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Coordinator Found');
            navigate('/home')
        }
    }, [id, navigate])

    const onSearch = (value) => {

        setAbhyasiIdLength(value?.length)
        if (value.length === 9) {
            setIsError(false)
            setLoading(true)
            searchAbhyasi(page, page_size, value.toUpperCase()).then((response) => {
                if (response?.data?.count === 1) {
                    setShow(true);

                    setLoading(false)
                    toast.success("Abhyasi Details Found");

                    setSelectedAbhyasi(response?.data?.results?.[0])
                    setShowDetails(true);
                } else if (response?.data?.count === 0) {
                    setShow(false);
                    setLoading(false)
                    setIsError(true);
                    // isShowDetails(false);
                    setErrorMessage("Abhyasi Id Not Found");
                    toast.error("No Data Found");

                }

            }).catch((err) => {
                toast.error("Something went wrong please try again later");
            });
        }
        if (value.length < 9) {
            setIsError(true);
            setErrorMessage("* Abhyasi id must be 9 digits long");
        }
        if (value.length > 9) {
            setIsError(true);
            setErrorMessage("* Abhyasi id must be 9 digits long");
        }
    }

    const addCoordinator = () => {
        setDisableButton(true);
        const centersId = lStorage.get('center-id');
        const positionId = lStorage.get('position-data')
        setLoading(true)
        const addAbhyasiObj = {
            start_date: moment(new Date()).format("YYYY-MM-DD"),
            end_date: "2099-09-01",
            assign_active: true,
            position: Number(positionId.id),
            partner: selectedAbhyasi?.id,
            position_type_code: "MCC",
        };

        addAbhyasi(addAbhyasiObj, centersId).then((res) => {
            setDisableButton(false);
            setLoading(false)
            // toast.success(res?.statusText);
           // toast.success("Coordinator Added");
            lStorage.set("successMessage","Coordinator Added");
            setTimeout(() => {navigate(`/coordinator/${id?.id}`)}, 1000);
        }).catch((err) => {
            setDisableButton(false);
            toast.error("Something went wrong please try again later");
            setLoading(false)

        });
    }

    useEffect(() => {
        if (abhyasiIdLength < 9) {
            setIsError(true);
        }else if(abhyasiIdLength > 9) {
            setIsError(true);
        }
    }, [abhyasiIdLength])

    useEffect(() => {
        if (selectedAbhyasi === undefined) {
            setShowDetails(false);
        }
    }, [selectedAbhyasi])

    return (
        <>
        <ToastContainer />
            {
                loading && <Spinner />
            }


            {!loading &&
                <div className="abhyasi-details-wrapper">
                    <div className="search-input-wrapper">
                        <h6>Abhyasi ID</h6>
                        <Search placeholder="Enter the 9 digit Abhyasi ID" onSearch={onSearch} enterButton />
                        {isError && <p style={{ marginTop: '5px', color: 'red' }}>{errorMessage}</p>}
                    </div>

                    {isShowDetails && !isError &&
                        <div>
                            <div className='section-container' style={{ marginTop: '25px', }}>
                                {show && <div style={{ paddingLeft: "20px" }}>
                                    <h5 style={{ color: '#2cb4ff' }}>Abhyasi Details</h5>

                                   <div className='flex-container'>
                                        <div className='item-title'>Name</div><div>{selectedAbhyasi?.name}</div>
                                    </div>
                                    {/* <div className='flex-container'>
                                        <div className='item-title'>First Name</div><div>{selectedAbhyasi?.first_name}</div>
                                    </div>
                                    <div className='flex-container'>
                                        <div className='item-title'>Last Name</div><div>{selectedAbhyasi?.last_name}</div>
                                    </div>
                                    <div className='flex-container'>
                                        <div className='item-title'>Age</div><div>{selectedAbhyasi?.age}</div>
                                    </div>
                                    <div className='flex-container'>
                                        <div className='item-title'>City</div><div>{selectedAbhyasi?.city}</div>
                                    </div>
                                    <div className='flex-container'>
                                        <div className='item-title'>Country</div><div>{selectedAbhyasi?.country?.name}</div>
                    </div>*/}
                                    <div className='flex-container'>
                                        <div className='item-title'>Gender</div><div>{selectedAbhyasi?.gender === 'M' ? "Male" : "Female"}</div>
                                    </div>
                                    <div className='flex-container'>
                                        <div className='item-title'>Id</div><div>{selectedAbhyasi?.id}</div>
                                    </div>
                                    {/* <div className='flex-container'>
                          <div className='item-title'>Photo</div><div>{selectedAbhyasi?.photo_url}</div>
                      </div> */}

                                </div>}
                                <div className="rec-attendance-footer-save-coordinator" onClick={() => addCoordinator()}>
                                    <button className="rec-done-btn" disabled={disableButton} >Save</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>}
        </>
    )
}

// INHNAE042

export default AbhyasiSelect;