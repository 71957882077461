import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../../assets/styles/pages/addMeditationTimings.scss";
import { lStorage } from "../../../../utils/storage";

const AddMeditationTiming = () => {
  const { state } = useLocation();
  const draftData = state?.data

  const navigate = useNavigate();

  useEffect(() => {
    const existingTimeList = lStorage.get("medetationDateTime")
    if (existingTimeList?.length > 0) {
      navigate("/selectedTimeList", { state: { data: draftData } })
    }
  }, [draftData, navigate])

  return (
    <>
      <div className="add-timing-main-container">
        <div className="add-timing-inner-container">
          <div className="add-timing-container">
            <div onClick={() => navigate("/timeSelection")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16"
                style={{ color: 'rgb(44, 180, 255)' }}
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
              </svg>

            </div>
            <p
              style={{
                fontSize: "20px",
                textAlign: "center",
                color: 'rgb(44, 180, 255)',
                marginTop: '10px'
              }}
            >
              Add Meditation Timings
            </p>
          </div>
        </div>
      </div>
    </>

  );
};

export default AddMeditationTiming;
