import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { lStorage } from "../utils/storage";
import {getMeDetails, getCenterId} from "../services/meditationCenter/centerList"
import Spinner from "../components/spinner";
import { isLoginAuth } from "../utils/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Launch = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "REQUEST_TOKEN" })
      );
    }

    window.addEventListener("message", async (message) => {
      if (message.data.token) {
        lStorage.set("authToken", `${message.data.token}`);
        setLoading(true);
        await getMeDetails().then((res) => {
          const user = {};
          if (res?.data?.results.length > 0) {
            const myInfo = res?.data?.results[0];
            user.srcmToken = message.data.token;
            user.name = myInfo.name;
            user.email = myInfo.user_email;
            user.loginUser = myInfo;
            lStorage.set("authInfo", user);
            lStorage.set("heartsApp", 1);
            getCenterId(1, 100)
              .then((res) => {
                const results = res?.data?.results || [];
                const matchCount = results.filter(item => item.srcm_group_detail?.group_type === "center").length;
                lStorage.set("centersCount", matchCount);
                setTimeout(() => {
                  setLoading(false);
                  navigate(matchCount === 0 ? "/MymediCenters" : "/home");
                }, 2000);
              })            
          }
        }).catch((err) => {
          toast.error(err?.response?.data?.detail);
        });
      }else if (isLoginAuth()) {
        navigate("/home");
      }
    });
  }, [navigate]);

  return (
    <>
    <ToastContainer />
    {loading && <Spinner />}
      {
        <div className="lds-roller-wrapper">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      }
    </>
  );
};

export default Launch;
