import React, { useEffect } from 'react'
import Coordinator from '../components/home/coordinators/coordinator'

import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";
import { useParams, useNavigate } from "react-router-dom";

const CoordinatorWrapper = () => {
  const navigate = useNavigate();

  const pageTitle = lStorage.get("centerName")
  const navigateRoute = lStorage.get("isCoordinatorActive")
  const formData = lStorage.get("formData");
  const activeCenterId = formData?.id;
  const id = useParams()

  useEffect(() => {
    if (id?.id === undefined) {
      alert('No Coordinator Found');
      navigate('/home')
    }
  }, [id, navigate])

  return (
    <DashboardLayout title={`${pageTitle} - Coordinators`} 
    routeTo={navigateRoute === true ? `/meditationPlace/${id?.id}/${activeCenterId}` : "/home" }
    >
      <Coordinator />
    </DashboardLayout>
  )
}

export default CoordinatorWrapper