const configData = process.env;

const config = {};

try {
  config.apiURL =
    configData.REACT_APP_API_BASE_URI ||
    'https://profile.srcm.net/api/';
  config.basicMailURL = '';
  config.xclientid = configData.REACT_APP_XCLIENT_ID || 'TuYUWFOTG13uu5xnYlqSUtc65gcxopLiy4VQ3QL5'
  config.gMapKey = configData.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyBsT8BoWIPL2fCb-uVVsiIXS1JVmTB9c48'
 // config.lib = ["places"]
  config.apiCitySearch = configData.REACT_APP_CITY_SEARCH_API || 'https://static-gatsby-qa.web.app/srcmapi/'
} catch {
  config.apiURL = 'https://profile.srcm.net/api/';
  config.basicMailURL = '';
  config.xclientid = configData.REACT_APP_XCLIENT_ID || 'TuYUWFOTG13uu5xnYlqSUtc65gcxopLiy4VQ3QL5'
  config.gMapKey = configData.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyBsT8BoWIPL2fCb-uVVsiIXS1JVmTB9c48'
  //config.lib = ["places"]
  config.apiCitySearch = configData.REACT_APP_CITY_SEARCH_API || 'https://static-gatsby-qa.web.app/srcmapi/'
}

export default config;
