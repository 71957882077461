import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LocationSelectionForm from '../components/meditationCenter/newMeditationCenter/mapLocationSelection'
import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";

const AddLocation = () => {
  const navigate = useNavigate();
  const activeMenuName = lStorage.get('tabName');

  const [selectedId, setSelectedId] = useState();
  const [route, setRoute] = useState()

  const id = useParams()

  useEffect(() => {
    if (id?.id === undefined) {
      alert('No Data Found');
      navigate('/home')
    }
    setSelectedId(id?.id)
  }, [id, navigate])


  useEffect(() => {
    const centerId = lStorage.get("center-id")
    
    const existingTimeList = lStorage.get("medetationDateTime");
    if (existingTimeList?.length > 0) {
      if (activeMenuName === "MymediCenters") {
        setRoute(`/MymediCenters`)
      }else{
        setRoute(`/meditationCenter/${centerId}`)
      } 
      
    }
  }, [])

  const getRouteTo = () => {
    return route ? route: `/newMeditationCenter/${selectedId}`;
  };


  return (
    <DashboardLayout
      title={"New Meditation Place"}
      routeTo={getRouteTo()}
      isSecondaryHeader={true}
      isMapActive={true}
    >
      <LocationSelectionForm />
    </DashboardLayout>
  )
}

export default AddLocation