import React
, { useState, useEffect }
  from "react";
import { lStorage } from "../../utils/storage";
import '../../assets/styles/pages/header.scss';
import { useNavigate, useParams } from "react-router-dom";
import SideBarDrawer from "../../components/sideBar";
import { Link } from "react-router-dom";

const HeaderLayout = ({
  title,
  sideBarMenu,
  routeTo,
  showListIcon,
  listRoute,
  secondaryHeader,
  isSecondaryHeader,
  isLocationActive,
  isMapActive,
  isContactActive,
  isMedTimeActive,
  isReload,
  isBorder
}) => {
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState();
  const [selectedCenterId, setSelectedCenterId] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const centerCount = lStorage.get("centersCount")

  const id = useParams()

  useEffect(() => {
    if (isSecondaryHeader) {
      setSelectedId(id?.id)
    }
  }, [id, isSecondaryHeader])

  useEffect(() => {
    setSelectedCenterId(lStorage.get("center-id"))
  }, [])

  const draftDataId = lStorage.get("draftId")

  useEffect(() => {
    if (draftDataId) {
      setIsDraft(true);
    }
  }, [draftDataId])

  const navigateFunction = () => {
    if (isReload === true) {
      window.location.reload();
    } else {
      navigate(routeTo ? `${routeTo}` : -1)

    }
  }

  return (
    <>
      <div className="layout-header-container">

        <div className="layout-header">
          <div>
            {sideBarMenu ? (
              <SideBarDrawer />
            ) : (
              <div onClick={() => navigateFunction()}>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            )}
          </div>

          <div style={{ textAlign: "center" }}>
            <h5 className="header-title">
              {title}
            </h5>
          </div>

          <div>
            {showListIcon && (
              <div onClick={() => navigate(listRoute)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-list-ul"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>

        {!isSecondaryHeader && <div className="secondary-header">
          <div style={{ marginLeft: 'auto' }}>
            {secondaryHeader && (secondaryHeader === "List of Centers" || secondaryHeader === "My Meditation Places") ? (
              <p>
                {centerCount !==0 &&
                  <b>
                    <Link 
                      to="/home" 
                      style={{ 
                        borderBottom: secondaryHeader === "List of Centers" && isBorder !== false ? '3px solid #2cb4ff' : '3px solid rgb(175 188 200)', 
                        marginRight: '10px',
                        paddingBottom: '1em'
                      }}
                    >
                      List of Centers
                    </Link>
                  </b>
                }
                  <b>
                    <Link 
                      to="/MymediCenters" 
                      style={{ 
                        borderBottom: secondaryHeader === "My Meditation Places" && isBorder !== false ? '3px solid #2cb4ff' : '3px solid rgb(175 188 200)', 
                        paddingBottom: '1em'
                      }}
                    >
                      My Meditation Places
                    </Link>
                  </b>
              </p>
            ) : (
              <p>
                <b style={{ borderBottom: isBorder !== false && '2px solid #2cb4ff' }}>{secondaryHeader}</b>
              </p>
            )}


          </div>
          <div style={{ marginLeft: 'auto' }}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sliders" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
            </svg> */}
          </div>
        </div>}

        {isSecondaryHeader && <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto' }}>

          {isDraft ? <div
            style={{ borderBottom: isLocationActive ? '2px solid #2cb4ff' : '0px solid white', marginRight: '15px', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => navigate(`/newMeditationCenter/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isLocationActive ? '#2cb4ff' : '0px solid black' }}>
              <b>Location</b>
            </p>
          </div> :
            <div
              style={{ borderBottom: isLocationActive ? '2px solid #2cb4ff' : '0px solid white', marginRight: '15px', textAlign: 'center', cursor: 'pointer' }}
            // onClick={() => navigate(`/newMeditationCenter/${selectedId ? selectedId : selectedCenterId}`)}
            >
              <p style={{ color: isLocationActive ? '#2cb4ff' : '0px solid black' }}>
                <b>Location</b>
              </p>
            </div>}
          {isDraft ? <div
            style={{ borderBottom: isMapActive ? '2px solid #2cb4ff' : '0px solid white', marginRight: '15px', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => navigate(`/locationSelection/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isMapActive ? '#2cb4ff' : '0px solid black' }} >
              <b>Map</b>
            </p>
          </div> : <div
            style={{ borderBottom: isMapActive ? '2px solid #2cb4ff' : '0px solid white', marginRight: '15px', textAlign: 'center', cursor: 'pointer' }}
          // onClick={() => navigate(`/locationSelection/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isMapActive ? '#2cb4ff' : '0px solid black' }} >
              <b>Map</b>
            </p>
          </div>}

          {isDraft ? <div
            style={{ borderBottom: isContactActive ? '2px solid #2cb4ff' : '0px solid white', marginRight: '15px', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => navigate(`/addNewMeditationCenter/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isContactActive ? '#2cb4ff' : '0px solid black' }}>
              <b>Contact</b>
            </p>
          </div> : <div
            style={{ borderBottom: isContactActive ? '2px solid #2cb4ff' : '0px solid white', marginRight: '15px', textAlign: 'center', cursor: 'pointer' }}
          // onClick={() => navigate(`/addNewMeditationCenter/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isContactActive ? '#2cb4ff' : '0px solid black' }}>
              <b>Contact</b>
            </p>
          </div>}
          {isDraft ? <div
            style={{ borderBottom: isMedTimeActive ? '2px solid #2cb4ff' : '0px solid white', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => navigate(`/addMeditationTiming/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isMedTimeActive ? '#2cb4ff' : '0px solid black' }}>
              <b>Timings</b>
            </p>
          </div> : <div
            style={{ borderBottom: isMedTimeActive ? '2px solid #2cb4ff' : '0px solid white', textAlign: 'center', cursor: 'pointer' }}
          // onClick={() => navigate(`/addMeditationTiming/${selectedId ? selectedId : selectedCenterId}`)}
          >
            <p style={{ color: isMedTimeActive ? '#2cb4ff' : '0px solid black' }}>
              <b>Timings</b>
            </p>
          </div>}
        </div>}

      </div>
    </>
  );
};

export default HeaderLayout;
