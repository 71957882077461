import React, { useEffect, useState } from 'react'
import Preview from '../components/meditationCenter/meditationCenter/viewPendingCenterDetails'
import DashboardLayout from '../layout/layout'
import { useParams, useNavigate } from "react-router-dom";

const ViewPendingCenterDetails = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id)
    }, [id, navigate])

    return (
        <DashboardLayout
            title={"Meditation Place"}
            routeTo={`/meditationCenter/${selectedId?.id}`}
            secondaryHeader={"Preview"}
        >
            <Preview />
        </DashboardLayout>
    )
}

export default ViewPendingCenterDetails