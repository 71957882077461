import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AddMeditationTimings from '../components/meditationCenter/newMeditationCenter/addMeditationTimings'
import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";
 
const AddNewMeditationTiming = () => {
    const navigate = useNavigate();
    const isActiveCenter = lStorage.get("isActiveCenter");
    const [selectedId, setSelectedId] = useState();
    const activeMenuName = lStorage.get('tabName');

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id?.id)
    }, [id, navigate])

    const getRouteTo = () => {
        if (activeMenuName === "MymediCenters") {
        return isActiveCenter ? `/MymediCenters` : `/addNewMeditationCenter/${selectedId}`;
        } 
        return isActiveCenter ? `/meditationPlace/${selectedId}` : `/addNewMeditationCenter/${selectedId}`
      };

    return (
        <DashboardLayout
            title={isActiveCenter ? "Add Meditation Timing" : "New Meditation Place"}
            routeTo={getRouteTo()}
            isSecondaryHeader={isActiveCenter ? false : true}
            isMedTimeActive={isActiveCenter ? false : true}
        >
            <AddMeditationTimings />
        </DashboardLayout>
    )
}

export default AddNewMeditationTiming