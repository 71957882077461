import React, { useState,useRef } from "react";
import { GoogleMap,  Marker } from "@react-google-maps/api";


export default function Map({ mapCoordinates }) {

  
const markers = [
  {
    lat: mapCoordinates?.selectedCenterLat
    ? mapCoordinates?.selectedCenterLat
    : "", 
    lng: mapCoordinates?.selectedCenterLong
      ? mapCoordinates?.selectedCenterLong
      : ""
  }];

 
  
  const mapRef = useRef(null);
  const [position, setPosition] = useState({
      lat: mapCoordinates?.selectedCenterLat
      ? mapCoordinates?.selectedCenterLat
      : "", 
      lng: mapCoordinates?.selectedCenterLong
      ? mapCoordinates?.selectedCenterLong
      : ""
  });

  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  return (
    <GoogleMap
      zoom={14}
      onLoad={handleLoad}
      onDragEnd={handleCenter}
      center={position}
      id="map"
      options={{
        fullscreenControl:true,
        zoomControl: true,
        streetViewControl:false,
        mapTypeControl: false

      }}
      mapContainerStyle={{
        height: '31vh',
        width: '100%'
      }}
      
    >   
    {markers.map((marker) => (
            <Marker
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
              // onClick={() => {
              //   setSelected(marker);
              // }}
              icon={{
                url: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                    style={{ color: "red" }}
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                ),
              }}
            />
          ))}

   </GoogleMap>
  );
}

