import React from "react";
import Header from "./header/header";
// import Footer from './footer'

const DashboardLayout = (props) => {
  const {
    children,
    title,
    sideBarMenu,
    routeTo,
    showListIcon,
    listRoute,
    secondaryHeader,
    isSecondaryHeader,
    isLocationActive,
    isMapActive,
    isContactActive,
    isMedTimeActive,
    clearDraft,
    isReload,
    // showFooter,
    isBorder,
  } = props;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Header
        title={title}
        sideBarMenu={sideBarMenu}
        routeTo={routeTo}
        showListIcon={showListIcon}
        listRoute={listRoute}
        secondaryHeader={secondaryHeader}
        isSecondaryHeader={isSecondaryHeader}
        isLocationActive={isLocationActive}
        isMapActive={isMapActive}
        isContactActive={isContactActive}
        isMedTimeActive={isMedTimeActive}
        clearDraft={clearDraft}
        isReload={isReload}
        isBorder={isBorder}
      />
      <div style={{ height: "100%", width: "100%" }} className="children-div">
        {children}
      </div>
      {/* {showFooter === false ? "" : <Footer/>} */}
    </div>
  );
};

export default DashboardLayout;
