import React, { useEffect } from 'react'
import ActiveCenter from '../components/meditationCenter/meditationCenter'
import DashboardLayout from '../layout/layout'
import { lStorage } from "../utils/storage";
import { useParams, useNavigate } from "react-router-dom";

const ActiveCenterWrapper = () => {
  const navigate = useNavigate();

  const pageTitle = lStorage.get("centerName")
  const id = useParams()

  useEffect(() => {
    if (id?.id === undefined) {
      alert('No Center Found');
      navigate('/home')
    }
  }, [id, navigate])

  return (
    <DashboardLayout title={`Centers - ${pageTitle}`} routeTo={"/home"} secondaryHeader={"List of Meditation Places"} isBorder={false}>
      <ActiveCenter />
    </DashboardLayout>
  )
}

export default ActiveCenterWrapper