import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Input, Select } from "antd";
import '../../../../assets/styles/pages/propertyDetails.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { lStorage } from "../../../../utils/storage";

const AddPropertyDetails = () => {
  const navigate = useNavigate();

  const [time, setTime] = useState();

  const getDefaultValue = () => {
    const updatedData = lStorage.get('formData');
    return {
      total_area: updatedData ? updatedData?.total_area : "",
      property_type: updatedData ? updatedData?.property_type : "residential",
      ownership: updatedData ? updatedData?.ownership : "own",
      active_since: updatedData ? updatedData?.active_since : "",
    }
  }

  const { control, handleSubmit } = useForm({
    defaultValues: getDefaultValue()
  });

  const onSubmit = (data) => {
    const newData = {
      ...data,
      active_since: moment(time).format("YYYY-MM-DD"),
    };
    const localStoreData = lStorage.get("formData");
    const CombinedFormData = { ...newData, ...localStoreData };
    const jsonObj = CombinedFormData;
    lStorage.set("formData", jsonObj);
    navigate(`/addWebsiteDetails/${selectedId}`);
  };
  const [selectedId, setSelectedId] = useState();

  const id = useParams()

  useEffect(() => {
    setSelectedId(id?.id)
  }, [id])

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="imput-field-container">
          <p className="impul-label">TOTAL AREA</p>
          <Controller
            name="total_area"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Meditation Hall area in square feet"
              />
            )}
          />
        </div>
        <div className="imput-field-container">
          <p className="impul-label">PROPERTY TYPE</p>
          <Controller
            name="property_type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: "residential", label: "Residential" },
                  { value: "agricultural", label: "Agricultural" },
                  { value: "commercial", label: "Commercial" },
                ]}
                style={{ width: "100%" }}
              />
            )}
          />
        </div>
        <div className="imput-field-container">
          <p className="impul-label">OWNERSHIP</p>
          <Controller
            name="ownership"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: "own", label: "own" },
                  { value: "rent", label: "Rent" },
                  { value: "lease", label: "Lease" },
                  { value: "other", label: "Other" },
                ]}
                style={{ width: "100%" }}
              />
            )}
          />
        </div>

        <div className="imput-field-container">
          <p className="impul-label">
            REGISTRATION DATE OR ACTIVE SINCE DATE
          </p>
          <div className="date-picker-wrapper">
            <DatePicker
              wrapperClassName="datepicker"
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
              onChange={(date) => setTime(new Date(date))}
              selected={time || new Date()}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>

        <div className="button-div-container-prop button-div-container-property-details">
          <div onClick={() => navigate(`/addNewMeditationCenter/${selectedId}`)}>
            <button className="next-button">
              <b>Previous</b>
            </button>
          </div>
          <div type="submit">
            <button className="next-button">
              <b>Next</b>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPropertyDetails;
