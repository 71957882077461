import React, { useEffect, useState } from 'react'
import MeditationPlace from '../components/meditationCenter/meditationPlace'

import DashboardLayout from '../layout/layout'
import { useParams, useNavigate } from "react-router-dom";
import { lStorage } from "../utils/storage";

const MeditationPlaceWrapper = () => {
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState();

  const id = useParams()

  useEffect(() => {
    if (id?.id === undefined) {
      alert('No Data Found');
      navigate('/home')
    }
    setSelectedId(id?.id)
  }, [id, navigate])

  const activeMenuName = lStorage.get('tabName');
  

  return (
    <DashboardLayout
      title={"Meditation Place"}
      routeTo={activeMenuName === "ListCenters" ? `/meditationCenter/${selectedId}` : '/MymediCenters'}
    >
      <MeditationPlace />
    </DashboardLayout>
  )
}

export default MeditationPlaceWrapper