import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Switch } from "antd";
import "../../../../assets/styles/pages/addMeditation.scss";
import { lStorage } from "../../../../utils/storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateMeditationCenter, meditationIsDuplicate } from "../../../../services/meditationCenter/newMeditationCenter";

const { TextArea } = Input;

const AddNewMeditationCenter = () => {
  const draftData = lStorage.get("draftData")
  const draftFormData = lStorage.get("formData")
  const isActiveCenter = lStorage.get("isActiveCenter");
  const [disableButton, setDisableButton] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const nameInputRef = useRef(null);

  lStorage.set("enableLocation", 0);
  const navigate = useNavigate();

  const id = useParams()

  useEffect(() => {
    setSelectedId(id?.id)
  }, [id])

  const [emptyError, setEmptyError] = useState([])
 // const [isSubmit, setIsSubmit] = useState(false)
  const [isDraft, setIsDraft] = useState(false);
  const [sameNumber, setSameNumber] = useState(false);

  const getDefaultValue = () => {
    const draftVal = lStorage.get("formDatadraft");
    const updatedData = Object.keys(draftVal).length !== 0 ? draftVal : lStorage.get('formData');
    return {
      name: updatedData ? updatedData?.name : "",
      mobile: updatedData ? updatedData?.mobile : "",
      phone: updatedData ? updatedData?.phone : "",
      email: updatedData ? updatedData?.email : "",
      public_display: updatedData ? updatedData?.public_display : true,
      web_about: updatedData ? updatedData?.web_about : "",
      directions: updatedData ? updatedData?.directions : "",
      facilities: updatedData ? updatedData?.facilities : "facilities",
      contact_person_name: updatedData ? updatedData?.contact_person_name : "",
    }
  }

  const { control, handleSubmit ,
    formState: { errors },} = useForm({
    defaultValues: getDefaultValue()
  });

  const requiredFields = [
    "name",
  ]

  const inlineErrorValidation = (fieldName) => {
    setEmptyError(emptyError?.filter((errorField) => errorField !== fieldName))
  }
  const validateData = (data) => {
    const emptyError = requiredFields?.filter(reqField => !Boolean(data[reqField]))
    setEmptyError(emptyError)
    return emptyError
  }

  const draftDataId = lStorage.get("draftId")
  const localStoreData = lStorage.get("formData");
  const activeMenuName = lStorage.get("tabName");

  useEffect(() => {
    if (draftDataId) {
      setIsDraft(true);
    }
  }, [draftDataId])

  const draftFunctionCalled = (activeCenterDataVals) =>{
    lStorage.set('formDatadraft', activeCenterDataVals);
    }

    const checkDuplicateMeditationCenter = async (payloadDuplicate, selectedId) => {
      try {
        const res = await meditationIsDuplicate(payloadDuplicate, selectedId);
        if (res && res?.status === 200 && res?.data?.duplicate === true) {
          toast.error("Meditation Place Name already exists", {
            autoClose: 2000,
          });
          setTimeout(() => {
            nameInputRef.current?.focus();
            setDisableButton(false);
          }, 2000);
          return false;
        } else if (res.status === 400 && res?.data?.duplicate === false) {
          return true;
        }
      } catch (error) {
        console.log("Ignoring Axios error: ", error.message);
        // Returning true to proceed with the next step, despite the error
        return true;
      }
    };
    
    

  const onSubmit = async (data) => {
        setDisableButton(true);
        /*if((data?.mobile !== undefined) && (data?.phone !== undefined) && data?.mobile === data?.phone){
          setSameNumber(true);
          setDisableButton(false);
        }else{*/
        
        if (isDraft === true && validateData(data)?.length === 0) {
          const payloadDuplicate = {
            name :data?.name,
            id : draftFormData?.id,
          }
          const isNotDuplicate = await checkDuplicateMeditationCenter(payloadDuplicate, selectedId);
          console.log("isNotDuplicate", isNotDuplicate)
          if (!isNotDuplicate) {
            return; 
          }

          const payloadToSend = {
            ...data,
          //  city_id: draftFormData?.city_id?.id,
            mobile: data?.mobile ? data?.mobile : null,
            phone: data?.phone ? data?.phone : null,
            email: data?.email ? data?.email : null,
            web_about: data?.web_about ? data?.web_about : null,
            directions: data?.directions ? data?.directions : null,
            facilities: data?.facilities ? data?.facilities : null,
            contact_person_name: data?.contact_person_name ? data?.contact_person_name : null,
          };

          const newFormDataValues = lStorage.get("formDatadraft");
          const newlocalStoreData = Object.keys(newFormDataValues).length !== 0 ? newFormDataValues : localStoreData
          const CombinedFormData = { ...newlocalStoreData ,...payloadToSend};

          draftFunctionCalled(CombinedFormData)
    
          const selectedCenterid = draftFormData?.id;
          const draftCenterId = id?.id
    
          updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
            .then((res) => {
              if (res) {
                setDisableButton(false);
                toast.success("Details Updated");
              }
            })
            .catch((err) => {
              setDisableButton(false);
              toast.error("Something went wrong please try again later");
            });
        }
    
        if (isActiveCenter === true && validateData(data)?.length === 0) {
          const payloadDuplicate = {
            name :data?.name,
            id : draftFormData?.id,
          }
          const isNotDuplicate = await checkDuplicateMeditationCenter(payloadDuplicate, selectedId);
          console.log("isNotDuplicate", isNotDuplicate)
          if (!isNotDuplicate) {
            return; 
          }
          const payloadToSend = {
            ...data,
          // city_id: draftFormData?.city_id?.id,
            mobile: data?.mobile ? data?.mobile : null,
            phone: data?.phone ? data?.phone : null,
            email: data?.email ? data?.email : null,
            web_about: data?.web_about ? data?.web_about : null,
            directions: data?.directions ? data?.directions : null,
            facilities: data?.facilities ? data?.facilities : null,
            contact_person_name: data?.contact_person_name ? data?.contact_person_name : null,
          };
          const newFormDataValues = lStorage.get("formDatadraft");
          const newlocalStoreData = Object.keys(newFormDataValues).length !== 0 ? newFormDataValues : localStoreData
          const CombinedFormData = { ...newlocalStoreData ,...payloadToSend};

          draftFunctionCalled(CombinedFormData)
          const selectedCenterid = draftFormData?.id;
          const draftCenterId = id?.id
    
          updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
            .then((res) => {
              if (res) {
              //  toast.success("Details Updated");
              lStorage.set("successMessage","Details Updated");
                setTimeout(() => { navigate(`/meditationPlace/${id?.id}/${draftFormData?.id}`) }, 1000);
              }
            })
            .catch((err) => {
              setDisableButton(false);
              toast.error("Something went wrong please try again later");
            });
        }
    
        // setIsSubmit(true);
        if (validateData(data)?.length === 0 ) {
        if(data?.public_display===undefined){
            data.public_display = true;
        }
          const CombinedFormData = { ...localStoreData, ...data };
          const jsonObj = CombinedFormData;
          if(isDraft || isActiveCenter === true){
            lStorage.set("formData", jsonObj);
          }else{
            const payloadDuplicate = {
              name :data?.name,
            }
            const isNotDuplicate = await checkDuplicateMeditationCenter(payloadDuplicate, selectedId);
            console.log("isNotDuplicate", isNotDuplicate)
            if (!isNotDuplicate) {
              return; 
            }
          lStorage.set("formData", jsonObj);
          lStorage.set("tempFormData", jsonObj);
          }
          // navigate(isActiveCenter ? `/meditationPlace/${id?.id}/${selectedActiveCenterId}` :`/addMeditationTiming/${selectedId}`, { state: { data: draftData } });
          navigate(isActiveCenter ? `` :`/addMeditationTiming/${selectedId}`, { state: { data: draftData } });
        }

  };




  return (
    <>
      <ToastContainer />

      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-field-container">
            <p className="input-label" style={{ display: "inline-block" }}>
             Meditation Place Name
              <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.name && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.name?.message}
              </span>
            )}
             {errors?.name?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="name"
              control={control}
              rules={{
                pattern: /^[^\s].*[^\s]$/,
                required: {
                  value: true,
                  message: "Field Required",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={nameInputRef}
                  onKeyDown={() => inlineErrorValidation("name")}
                  placeholder="Meditation Place Name"
                  className="form-input-field-border"
                  autoComplete="nope"
                  onChange={(e) => {
                    const value = e.target.value;
                    const capitalizedValue = value.replace(/\b\w/g, (char) => char.toUpperCase());
                    field.onChange(capitalizedValue);
                  }}
                />
              )}
            />
            <span style={{ color: "#92928e", fontSize: "12px" }}>Area Name of the city</span>
          </div>
          <div className="input-field-container">
            <p className="input-label" style={{ display: "inline-block" }}>
              Contact Person Name <span style={{ color: "red"}}>*</span>
            </p>
            {/* {emptyError?.indexOf('name') > -1 && isSubmit && <span style={{ color: 'red', paddingLeft: "5px" }}> * Field Required</span>} */}
            {errors?.contact_person_name?.type === "required" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.contact_person_name?.message}
              </span>
            )}
            {errors?.contact_person_name?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid name
              </span>
            )}
            <Controller
              name="contact_person_name"
              control={control}
              rules={{
                pattern: /^\S[a-zA-Z ]*$/,
                required: {
                  value: true,
                  message: " Field Required",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  // onKeyDown={() => inlineErrorValidation('name')}
                  placeholder="Contact Person Name"
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>

          <div className="input-field-container">
            <p className="input-label" style={{ display: "inline-block" }}>
              Mobile Number <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.mobile && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.mobile?.message}
              </span>
            )}
            <Controller
              name="mobile"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: " Field Required",
                },
                pattern: {
                  value: /^\+?[0-9\s-+()]{10,16}$/,
                  message: "Please enter valid contact number",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Contact Number of Meditation Place"
                  className="form-input-field-border"
                  autoComplete="nope"
                  onKeyDown={() => {
                    if (sameNumber === true) {
                      setSameNumber(false);
                    }
                  }}
                />
              )}
            />
            <span style={{ color: "#92928e", fontSize: "12px" }}>Example +91 9999999999</span>
          </div>

          <div className="input-field-container">
            <p className="input-label" style={{ display: "inline-block" }}>
              Phone Number {/*  <span style={{ color: "red"}}>*</span> */}
            </p>
            {errors?.phone && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.phone?.message}
              </span>
            )}
            {sameNumber && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Mobile number and Phone number should not be same
              </span>
            )}
            <Controller
              name="phone"
              control={control}
              rules={{
                // required: {
                //   value: true,
                //   message: " Field Required",
                // },
                pattern: {
                  value: /^\+?[0-9\s-+()]{10,16}$/,
                  message: "Please enter valid phone number",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Landline Number or Phone Number"
                  className="form-input-field-border"
                  autoComplete="nope"
                /*  onKeyDown={() => {
                    if (sameNumber === true) {
                      setSameNumber(false);
                    }
                  }}*/
                />
              )}
            />
            <span style={{ color: "#92928e", fontSize: "12px" }}>Example : 044 34344XXX</span>
          </div>

          <div className="input-field-container">
            <p className="input-label" style={{ display: "inline-block" }}>
              Email Address <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.email && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.email?.message}
              </span>
            )}
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: " Field Required",
                },
                pattern: {
                  value:
                    /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|test|testing)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                  message: "Please enter valid email",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Contact Email Address"
                  className="form-input-field-border"
                  autoComplete="nope"
                />
              )}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>About the place</p>
            {errors?.web_about?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="web_about"
              rules={{
                pattern: /^\S.*(?:\r?\n\S.*)*$/,
              }}
              control={control}
              render={({ field }) => <TextArea {...field} placeholder="" />}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              Direction To Reach<span style={{ color: "red"}}>*</span>
            </p>
            {errors?.directions && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.directions?.message}
              </span>
            )}
            {errors?.directions?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="directions"
              rules={{
                required: {
                  value: true,
                  message: "Field Required",
                },
                pattern: /^\S.*(?:\r?\n\S.*)*$/,
              }}
              control={control}
              render={({ field }) => (
                <TextArea {...field} placeholder="Direction To Reach" />
              )}
            />
          </div>
          <div className="imput-field-container">
            <p className="impul-label" style={{ display: "inline-block" }}>
              Facilities <span style={{ color: "red"}}>*</span>
            </p>
            {errors?.facilities && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {errors?.facilities?.message}
              </span>
            )}
              {errors?.facilities?.type === "pattern" && (
              <span style={{ color: "red", paddingLeft: "5px" }}>
                Please enter valid details
              </span>
            )}
            <Controller
              name="facilities"
              rules={{
                pattern: /^\S.*(?:\r?\n\S.*)*$/,
                required: {
                  value: true,
                  message: " Field Required",
                },
              }}
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  placeholder="Food, Transport, Accessibility"
                />
              )}
            />
          </div>
          <div>
            {/* <p>Display to public</p> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p>Display to Public On Heartspots and other sites</p>
              </div>
              <div>
                <Controller
                  name="public_display"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked={
                        draftFormData?.public_display !== undefined || null
                          ? draftFormData?.public_display
                          : true
                      }
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {/* <div className="contact-details-form-save-btn" type="submit" > */}
          <button
            className="contact-details-form-save-btn"
            style={{ border: "none" }}
            type="submit"
            disabled={disableButton} 
          >
            {isDraft || isActiveCenter === true ? "Save" : "Next"}
          </button>
          {/* </div> */}
        </form>
      </div>
    </>
  );
};

export default AddNewMeditationCenter;
