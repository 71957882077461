import  { useState, useEffect ,useCallback,useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../assets/styles/pages/mapLocationSelection.scss";
import { lStorage } from "../../../../utils/storage";
import { ToastContainer, toast } from "react-toastify";
import { updateMeditationCenter } from "../../../../services/meditationCenter/newMeditationCenter";
import config from "../../../../assets/config";

import { GoogleMap, Marker } from "@react-google-maps/api";
//useLoadScript, 
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { LoadScript } from "@react-google-maps/api";

//const libraries = ["places"];
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

function Addlocations(props) {
  const [defaultLoc, setDefaultLoc] = useState();
 /* const {  loadError } = useLoadScript({
    googleMapsApiKey: config.gMapKey,
    libraries,
  });*/

  useEffect(() =>{
    
    
      const getDefaultloc = () => {
        let pos
        if (navigator?.geolocation) {
          navigator?.geolocation?.getCurrentPosition(function(position) {
             pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            setLocationView(pos);
            setMarkers((current) => [
              // ...current,
              {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                time: new Date(),
              },
            ]);
            props.saveLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            // pos to get location
           setDefaultLoc(pos)
          }, function() {
            // handle errors here if any
           if(lStorage.get("enableLocation")===undefined || lStorage.get("enableLocation")===0){
            lStorage.set("enableLocation", 1);
            toast.error("Please enable the APP location permission");
           }
            
          });
        }else{
          if(lStorage.get("enableLocation")===undefined || lStorage.get("enableLocation")===0){
            lStorage.set("enableLocation", 1);
            toast.error("Please enable the APP location permission");
           }
        }
      }
   
    
      if (props.data[0].latitude === "" || props.data[0].latitude === undefined) {
      getDefaultloc()
      const interval = setTimeout(() => {
        getDefaultloc()
      },5*1000);
    return () => clearInterval(interval);
    }

  },[props])

  const [markers, setMarkers] = useState([]);
  // const [selected, setSelected] = useState(null);
  const [locationView, setLocationView] = useState(defaultLoc);

  const onMapClick = useCallback((e) => {
   
    setMarkers((current) => [
      // ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
    props.saveLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
 
    // eslint-disable-next-line
  }, []);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    if (props.data[0].latitude !== "") {
      let lng = parseFloat(props.data[0].longitude);
      let lat = parseFloat(props.data[0].latitude);
      const pos = { lat, lng };
      setMarkers((current) => [
        // ...current,
        {
          lat: lat,
          lng: lng,
          time: new Date(),
        },
      ]);
      mapRef.current = map;
      setLocationView(pos);
    } else {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = { lat, lng };
          props.saveLocation({ lat, lng });
          setLocationView(pos);
          setMarkers((current) => [
            // ...current,
            {
              lat: lat,
              lng: lng,
              time: new Date(),
            },
          ]);
        }
      );
      mapRef.current = map;
    }
    // eslint-disable-next-line
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
    setMarkers((current) => [
      // ...current,
      {
        lat,
        lng,
        time: new Date(),
      },
    ]);
  }, []);

  //if (loadError) return "Error";
  //if (!isLoaded) return "Loading...";

  return (
    <div className="location-wrapper">
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          margin: "10px 0px 0px 10px",
        }}
      >
        <Search panTo={panTo} props={props} />
      </div>
      <div
        style={{
          zIndex: 0,
        }}
        className="location-container"
      >

{props.data && props.data[0].latitude!==undefined && ( 
        <GoogleMap
          id="map"
          mapContainerStyle={{ width: "auto" }}
          zoom={14}
          center={locationView}
          options={options}
          onClick={onMapClick}
          onLoad={onMapLoad}
        >
          {markers.map((marker) => (
            <Marker
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
              // onClick={() => {
              //   setSelected(marker);
              // }}
              icon={{
                url: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                    style={{ color: "red" }}
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                ),
              }}
            />
          ))}
        </GoogleMap>
        )}
      </div>
    </div>
  );
}

function Search({ panTo, props }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 28.6448, lng: () => 77.216721 },
      radius: 100 * 1000,
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      props.saveLocation({ lat, lng });
      panTo({ lat, lng });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="search" style={{ backgroundColor: "white" }}>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          className="form-control "
          onChange={handleInput}
          disabled={!ready}
          placeholder="Search your location"
        />
        <>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </>
      </Combobox>
    </div>
  );
}

const lib = ["places"];

export default function LocationSelection() {
  const draftData = lStorage.get("formData");
  const [isDraftLocation, setIsDraftLocation] = useState(false);

  useEffect(() => {
    if (draftData?.latitude !== undefined || null) {
      setLatLongs({
        lat: draftData?.latitude,
        lng: draftData?.longitude,
      });
    } else if (draftData?.latitude === undefined || null) {
      const showPosition = (position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        setLatLongs({
          lat: lat,
          lng: lng,
        });
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }
    // eslint-disable-next-line
  }, [isDraftLocation]);

  const navigate = useNavigate();
  const [localStoreData, setLocalStoreData] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const [latLongs, setLatLongs] = useState();

  const saveLocation = (loc) => {
    if(loc.lng!==undefined) {
    setLatLongs(loc);
    }
  };

  useEffect(() => {
    setLocalStoreData(lStorage.get("formData"));
    // eslint-disable-next-line
  }, []);

  const id = useParams();

  const submitLatLong = () => {
    if(latLongs?.lat===undefined){
      toast.error("Please choose the location");
      return false;
    }
    const coordinates = {
      latitude: latLongs?.lat,
      longitude: latLongs?.lng,
    };

    if (isDraft === true) {
      const payloadToSend = {
        ...coordinates,
       // city_id: draftData?.id,
        name: draftData?.name,
       // postal_code: draftData.postal_code,
        srcm_group_id: draftData?.srcm_group_id?.id,
      };

      const selectedCenterid = draftData?.id;
      const draftCenterId = id?.id;

      updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
        .then((res) => {
          if (res) {
            toast.success("Details Updated");
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try again later");
        });
      const CombinedFormData = { ...localStoreData, ...coordinates };
      const jsonObj = CombinedFormData;
      lStorage.set("formData", jsonObj);
    }
    const CombinedFormData = { ...localStoreData, ...coordinates };
    const jsonObj = CombinedFormData;
    if(isDraft ){
      lStorage.set("formData", jsonObj);
    }else{
    lStorage.set("formData", jsonObj);
    lStorage.set("tempFormData", jsonObj);
    }
    navigate(`/addNewMeditationCenter/${selectedId}`, {
      state: { data: draftData },
    });
  };

  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    setSelectedId(id?.id);
  }, [id]);

  useEffect(() => {
    if (draftData?.latitude !== undefined || null) {
      setIsDraftLocation(true);
    }
  }, [draftData]);

  const draftDataId = lStorage.get("draftId");

  useEffect(() => {
    if (draftDataId) {
      setIsDraft(true);
    }
  }, [draftDataId]);

  return (
    <>
      <ToastContainer />
      <div
        className="new-med-cen-form-map-outer-container"
        style={{ marginTop: "3px" }}
      >
        <LoadScript googleMapsApiKey={config.gMapKey} libraries={lib}>
          <Addlocations
            saveLocation={saveLocation}
            data={[{ latitude: latLongs?.lat, longitude: latLongs?.lng }]}
          />
        </LoadScript>
      </div>
      {latLongs && latLongs?.lat!=='' && (
      <div
        className="map-location-selection-save"
        onClick={() => submitLatLong()}
      >
        <button className="rec-done-btn">
          {isDraft === true ? "Save" : "Select"}
        </button>
      </div>
      )}
      <div style={{position:"absolute", bottom:"41px", }}>
        <div>
        <span style={{fontWeight:"bold"}}>Latitude : </span>
        <input
          type="text"
          id="latitude"
          name="latitude"
          value={latLongs?.lat}
          readonly
          style={{border:"1px solid #ced4da", marginLeft:"0.9em"}}
        />
        </div>
        <div>
        <span style={{  fontWeight:"bold" }}>Longitude : </span>
        <input
          type="text"
          id="longitude"
          name="longitude"
          value={latLongs?.lng}
          readonly
          style={{border:"1px solid #ced4da"}}
        />
        </div>
      </div>
    </>
  );
}
