import React from "react";
import '../../assets/styles/pages/help.scss'

const Help = () => {
  return (
    <>
    <div style={{ backgroundColor: "#f5f5f5", height: "89vh" }}>
        <div className="MuiContainer-root">
          <div className="call-card-container">
            <div className="call-icon-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-telephone-fill"
                viewBox="0 0 16 16"
                className="call-icon"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
            </div>
            <div className="call-card-p-container">
              <p>India: 1 800 121 DHYANA (3492)</p>
              <p> US/Canada: 1 844 879 4327</p>
              <p> WhatsApp: +91 78710 77888</p>
            </div>
          </div>

          <div className="call-card-container">
            <div className="call-icon-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-envelope-fill"
                viewBox="0 0 16 16"
                className="call-icon"
              >
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
              </svg>
            </div>
            <div className="call-card-p-container">
              <p
                style={{
                  cursor: "pointer",
                  color: "rgba(0,0,0,0.54) !important",
                  marginTop: "5px",
                }}
              >
                <a
                  href="mailto:it.help@heartfulness.org"
                  className="help-contact-anchor"
                >
                  it.help@heartfulness.org
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
