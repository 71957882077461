import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NewMeditationCenter from "../components/meditationCenter/newMeditationCenter/index";
import DashboardLayout from "../layout/layout";
import { lStorage } from "../utils/storage";

const NewMeditationCenterForm = () => {
  const navigate = useNavigate();
  const isActiveCenter = lStorage.get("isActiveCenter");
  const activeCenterId = lStorage.get("selectedActiveCenterId");
  const activeMenuName = lStorage.get('tabName');

  const [selectedId, setSelectedId] = useState();

  const id = useParams();

  useEffect(() => {
    if (id?.id === undefined) {
      alert("No Data Found");
      navigate("/home");
    }
    setSelectedId(id?.id);
  }, [id, navigate]);

  const getRouteTo = () => {
    if (activeMenuName === "MymediCenters") {
      return isActiveCenter
        ? `/meditationPlace/${selectedId}/${activeCenterId}`
        : `/MymediCenters`;
    } 
    return isActiveCenter
      ? `/meditationPlace/${selectedId}/${activeCenterId}`
      : `/meditationCenter/${selectedId}`;
  };

  return (
    <DashboardLayout
      title={isActiveCenter ? "Edit Location Details" : "New Meditation Place"}
      routeTo={getRouteTo()}
      isSecondaryHeader={isActiveCenter ? false : true}
      isLocationActive={true}
      clearDraft={true}
    >
      <NewMeditationCenter />
    </DashboardLayout>
  );
};

export default NewMeditationCenterForm;
