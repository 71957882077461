import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { lStorage } from "../../../utils/storage";
import config from '../../../assets/config';

const CountrySearch = (props) => {
  // const defaultCountryValue = lStorage.get("hfn-profile-me");
  const defaultCountryValue =  {
            "id": 358,
            "name": "India",
            "code": "IN",
            "active": true
        }
  const launchDefaultValues = lStorage.get("authInfo");

  const draftData = lStorage.get("formData");
  const draftVal = lStorage.get("formDatadraft");
  const draftId = lStorage.get("draftId");
  const isActiveCenter = lStorage.get("isActiveCenter");


  const updatedData = Object.keys(draftVal).length !== 0 ? draftVal : draftData;
  const draftIdBasedCountryId =
    !draftId && !isActiveCenter
      ? draftData?.country_id?.id?draftData?.country_id?.id:draftData?.country_id
      : draftData?.country_id?.id?draftData?.country_id.id:draftData?.country_id;
   const counrtyIdToBeUsed =
    Object.keys(draftVal).length !== 0 ? draftVal?.country_id?.id?draftVal?.country_id?.id:draftVal?.country_id : draftIdBasedCountryId;
  const modifiedCountryId = counrtyIdToBeUsed
    ? counrtyIdToBeUsed
    : defaultCountryValue ? defaultCountryValue?.id : launchDefaultValues?.loginUser?.country?.id ;
  const defaultCountryIdToBeUsed =
    Object.keys(updatedData).length !== 0
      ? modifiedCountryId
      : defaultCountryValue ? defaultCountryValue?.id : launchDefaultValues?.loginUser?.country?.id;

  const { handCountryFieldChange } = props;
  const [countryList, setCountryList] = useState();
  const [countryData, setCountryData] = useState();

  const defaultCountryData = countryList?.filter((item) => {
    return item?.value === defaultCountryIdToBeUsed;
  });


  const dropdownStyle = {
    control: () => ({
      width: "100%",
      display: "flex",
      padding: "6px 20px",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#212529",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  useEffect(() => {
    axios
      .get(`${config.apiCitySearch}countries/all.json`)
      .then((res) => {
        const countries = res?.data?.results?.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        setCountryList(countries);
      });
  }, []);

  useEffect(() => {
    (async () => {
      handCountryFieldChange(countryData, defaultCountryData?.[0]);
    })();
  }, [props, handCountryFieldChange, countryData, defaultCountryData]);

  const [defaultCountry, setDefaultCountry] = useState();

  return (
    <>
      <Select
        className={"cc-select"}
        styles={dropdownStyle}
        options={countryList}
        placeholder="Select Country"
        value={
          defaultCountry
            ? defaultCountry
            : defaultCountryData
            ? defaultCountryData
            : ""
        }
        onChange={(ev) => {
          setDefaultCountry(ev);
          setCountryData(ev);
        }}
      />
    </>
  );
};

export default CountrySearch;
