import React
, { useState, useEffect }
  from "react";
import { lStorage } from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import HeartfulnessLogo from "../../assets/images/heartfulness-logo.svg";
import { signOut } from "@heartfulnessinstitute/react-hfn-profile"; 
import { Drawer, Space } from "antd";
import "antd/dist/antd.min.css";
import "../../assets/styles/pages/sidebar.scss";


const SideBarDrawer = () => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [heartsApp, setHeartsApp] = useState();
  const centerCount = lStorage.get("centersCount");  

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const logout = () => {
    signOut();
    const heartsApp = lStorage.get("heartsApp");  
    localStorage.clear();
    lStorage.set("heartsApp", heartsApp); 
    navigate("/");
  };

  const goToHome = () => {
    setVisible(false);
    navigate("/home");
  };

  const goToMyHome = () => {
    setVisible(false);
    navigate("/MymediCenters");
  };
  
  useEffect(() => {
    setHeartsApp(lStorage.get("heartsApp"))
    
  }, [])

  return (
    <>
      <Space>
      {heartsApp !==1 &&
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
          onClick={showDrawer}
          style={{ cursor: "pointer" }}
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
}
      </Space>
      <Drawer
        placement={"left"}
        closable={false}
        onClose={onClose}
        visible={visible}
        key={"left"}
        width={"250"}
      >
        {heartsApp !==1 &&
        <div>
        <div className="sidebar-logo-area">
          <img
            src={HeartfulnessLogo}
            alt="hf-logo"
            className="heartfulness-logo"
          />
          {/* <p className="meditations-text">Meditation</p> */}
          <p className="center-text">Center</p>
        </div>
        <div>
        {centerCount !==0 &&
          <div
            onClick={() => goToHome()}
            className="sidebar-option-list"
          >
            <p className="menuItem-style">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>{" "}
              <p className="sidebar-options">Centers</p>
            </p>
          </div>
        }
          <div
            onClick={() => goToMyHome()}
            className="sidebar-option-list"
          >
            <p className="menuItem-style">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>{" "}
              <p className="sidebar-options">My Meditation Places</p>
            </p>
          </div>
          <div
            className="sidebar-option-list"
            onClick={() => {
              navigate("/help");
            }}
          >
            <p className="menuItem-style">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-question-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
              </svg>
              <p className="sidebar-options">Help</p>
            </p>
          </div>
          <div className="sidebar-option-list" onClick={() => logout()}>
            <p className="menuItem-style">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                />
                <path
                  fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                />
              </svg>
              <p className="sidebar-options">Logout</p>
            </p>
          </div>
        </div>
        </div>
        }
      </Drawer>
    </>
  );
};

export default SideBarDrawer;
