import React from 'react'
import MeditationCenter from '../components/myMeditationCenter'
import DashboardLayout from '../layout/layout'

const MyMeditationCenters = () => {
  return (
    <DashboardLayout title={"Manage Meditation Place"} sideBarMenu={true} secondaryHeader={"My Meditation Places"}>
        <MeditationCenter />
    </DashboardLayout>
  )
}

export default MyMeditationCenters;