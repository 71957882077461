import React from 'react'
import Home from '../components/home'
import DashboardLayout from '../layout/layout'

const HomePage = () => {
  return (
    <DashboardLayout title={"Manage Meditation Place"} sideBarMenu={true} secondaryHeader={"List of Centers"}>
        <Home/>
    </DashboardLayout>
  )
}

export default HomePage