import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "antd";
import DatePicker from "react-datepicker";
import { postrecordAttendenceData } from "../../../../services/meditationCenter/recordAttendence";
import "../../../../assets/styles/pages/recordAttendance.scss";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../../spinner";
import { lStorage } from "../../../../utils/storage";
import { Select } from "antd";
import "antd/dist/antd.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import "../../../../assets/styles/pages/timeSelection.scss";

const RecordAttendence = () => {
  const { Option } = Select;

  const requiredFields = ["count_not_scanned"];
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(
    moment(new Date()).format("ddd").toLocaleLowerCase()
  );
  const [SelectedTime, setSelectedTime] = useState();
  const [time, setTime] = useState();
  const [meditationTimings, setMeditationTimings] = useState();
  const [dropdownOption, setDropdownOption] = useState();
  const [isDropDownSelected, setIsDropDownSelected] = useState(false);
  const [attendanceTimeSlected, setAttendanceTimeSlected] = useState();
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [emptyError, setEmptyError] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [manualValue, setManualValue] = useState(moment(new Date()));
  const [manualSelection, setManualSelection] = useState(false);
  const [changeHandler, setChangeHandler] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      meditation_datetime: "",
      count_not_scanned: "",
    },
  });

  useEffect(() => {
    setDay(moment(new Date()).format("ddd").toLocaleLowerCase());
  }, []);



  const validateData = (data) => {
    const emptyError = requiredFields?.filter(
      (reqField) => !Boolean(data[reqField])
    );
    setEmptyError(emptyError);
    return emptyError;
  };

  const onSubmit = (data) => {
    setIsSubmit(true);
    const utcStart = attendanceTimeSlected;
    if (validateData(data)?.length === 0) {
      setDisableButton(true);
      setLoading(true);
      postrecordAttendenceData({
        ...data,
        meditation_datetime: utcStart,
        meditation_center_id: selectedId?.activeCenterId,
      })
        .then((res) => {
          //setLoading(false);
          toast.success("Attendance Record Added");
          setTimeout(() => {
            navigate(
              `/attendenceList/${selectedId?.id}/${selectedId?.activeCenterId}`
            );
          }, 2000);
        })
        .catch((err) => {
          toast.error("Attendance details already existed");
          setDisableButton(false);
          setLoading(false);
        });
    }
  };

  const id = useParams();

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    setMeditationTimings(lStorage.get("medetationDateTime"));
  }, []);

  const handleChange = (value) => {
    setIsDropDownSelected(true);
    setChangeHandler(value);
  };

  const options = dropdownOption?.map((d) => {
    return (
      <>
        <Option key={d.meditation_time}>
          {`${
            d?.day_of_week +
            " " +
            moment(
              new moment(
                moment(new Date()).format("YYYY:MM:DD") +
                  "T" +
                  d?.meditation_time,
                "YYYY-MM-DDThh:mm"
              ).utc()?._d
            ).format("HH:mm A")
          }`}
        </Option>
      </>
    );
  });

  useEffect(() => {
    var sorted = {};
    for (var i = 0, max = meditationTimings?.length; i < max; i++) {
      if (sorted[meditationTimings[i]?.day_of_week] === undefined) {
        sorted[meditationTimings[i]?.day_of_week] = [];
      }
      sorted[meditationTimings[i]?.day_of_week]?.push(meditationTimings[i]);
    }
    setDropdownOption(sorted[day]);
  }, [day, meditationTimings]);

  const inlineErrorValidation = (fieldName) => {
    setEmptyError(emptyError?.filter((errorField) => errorField !== fieldName));
  };

  useEffect(() => {
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    const timedDropdownValue = changeHandler?.value;
    const manualTimeValue = moment(manualValue).format("HH:mm:ss");
    var selectedTimeValue =
      timedDropdownValue !== "other" ? manualTimeValue : timedDropdownValue;
    if (timedDropdownValue === "other") {
      selectedTimeValue = manualTimeValue;
    } else if (timedDropdownValue !== "other") {
      selectedTimeValue = timedDropdownValue;
    }

    if (changeHandler?.key === "other") {
      setManualSelection(true);
    } else if (changeHandler?.key !== "other") {
      setManualSelection(false);
    }
    // setIsDropDownSelected(true);
    setSelectedTime(
      moment(
        new moment(
          moment(new Date()).format("YYYY:MM:DD") + "T" + selectedTimeValue,
          "YYYY-MM-DDTHH:mm"
        ).utc()?._d
      ).format("HH:mm A")
    );
  }, [changeHandler, manualValue]);

  useEffect(() => {
    if (isDropDownSelected === false) {
      setAttendanceTimeSlected(new moment(moment(new Date()).format("YYYY:MM:DD") + "T" + moment(new Date()).format("HH:mm:ss"),"YYYY-MM-DDTHH:mm").utc()?._d);
    }
    if (isDropDownSelected === true) {
      setAttendanceTimeSlected(
        new moment(
          moment(time).format("YYYY:MM:DD") + "T" + SelectedTime,
          "YYYY-MM-DDTHH:mm"
        ).utc()?._d
      );
    }
    if (isDateSelected === true && isDropDownSelected === false) {
      setAttendanceTimeSlected(time);
    }
  }, [isDropDownSelected, SelectedTime, time, isDateSelected]);

  return (
    <>
      <ToastContainer />

      {loading && <Spinner />}
      {!loading && (
        <div>
          <div className="record-attendence-main-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-field-container">
                <p className="input-label" style={{ marginTop: "10px" }}>
                  RECORDING ATTENDANCE ON
                </p>
                <div className="date-picker-wrapper">
                  <DatePicker
                    wrapperClassName="datepicker"
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    maxDate={new Date()}
                    onChange={(date) => {
                      setIsDateSelected(true);
                      setTime(new Date(date));
                      setDay(moment(date).format("ddd").toLocaleLowerCase());
                    }}
                    selected={time || new Date()}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>

              <div className="input-field-container">
                <p className="input-label" style={{ display: "inline-block" }}>
                  ATTENDANCE COUNT <span style={{ color: "red"}}>*</span>
                </p>
                {emptyError?.indexOf("count_not_scanned") > -1 && isSubmit && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {" "}
                    Field Required
                  </span>
                )}
                {errors?.count_not_scanned && (
                  <span style={{ color: "red", paddingLeft: "5px" }}>
                    {errors?.count_not_scanned?.message}
                  </span>
                )}
                <Controller
                  name="count_not_scanned"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[1-9][0-9]*$/,
                      message: "Numeric values only",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder=""
                      autoComplete="nope"
                      onKeyDown={() =>
                        inlineErrorValidation("count_not_scanned")
                      }
                    />
                  )}
                />
              </div>

              <div className="input-field-container">
                <p className="input-label">SELECT TIMING</p>
                <Select
                  labelInValue
                  placeholder="Select Timing"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                >
                  {options}
                  <Option key="other">
                    {options !== undefined
                      ? "Other"
                      : "No Timings found, Add new"}
                  </Option>
                </Select>
              </div>
              {manualSelection && (
                <div className="time-selection-main-container-attendance">
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticTimePicker
                        ampm
                        orientation={isMobile ? "portrait" : "landscape"}
                        value={manualValue}
                        onChange={(e) => {
                          setManualValue(e);
                          setIsDropDownSelected(true);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}

              <button
                className="rec-attendance-footer-save"
                style={{ border: "none" }}
                type="submit"
                disabled={disableButton} 
              >
                Save
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default RecordAttendence;
