import React, { useEffect, useState } from "react";
import AddCoordinator from "../components/home/coordinators/abhyasiSelect";
import DashboardLayout from "../layout/layout";
import { useParams, useNavigate } from "react-router-dom";
import { lStorage } from "../utils/storage";

const CoordinatorWrapper = () => {
  const navigate = useNavigate();
  const activeCenterId = lStorage.get("selectedActiveCenterId");
  const [selectedId, setSelectedId] = useState();

  const id = useParams();
  const isCoordinatorActive = lStorage.get("isCoordinatorActive");

  useEffect(() => {
    if (id?.id === undefined) {
      alert("No Data Found");
      navigate("/home");
    }
    setSelectedId(id?.id);
  }, [id, navigate]);

  return (
    <DashboardLayout
      title={"Add Coordinators"}
      routeTo={
        isCoordinatorActive
          ? `/meditationPlace/${selectedId}/${activeCenterId}`
          : `/coordinator/${id?.id}`
      }
    >
      <AddCoordinator />
    </DashboardLayout>
  );
};

export default CoordinatorWrapper;
