import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Home from "./pages/medCenterHomePage";
import Help from "./pages/help";
import ActiveCenters from "./pages/activeCenterList";
import MeditationPlace from "./pages/meditationPlace";
import RecordAttendence from "./pages/recordAttendanceForm";
import AttendenceList from "./pages/attendanceList";
import RecordMeals from "./pages/recordMealsForm";
import MealsList from "./pages/meals-list";
import NewMeditationCenter from "./pages/newMeditationCenterForm";
import AddNewMeditationCenter from "./pages/newMeditationCenterDetails";
import AddMeditationTiming from "./pages/addMeditationTiming";
import AddWebsiteDetails from "./pages/addWebDetails";
import AddPropertyDetails from "./pages/addPropertyDetails";
import LocationSelection from "./pages/locationSelection";
import TimeSelection from "./pages/timeSelection";
import SelectedTimeList from "./pages/timeList";
import ViewPendingCenterDetails from "./pages/viewPendingCenterDetails";
import Coordinator from "./pages/coordinatorPage";
import AddCoordinator from "./pages/abhyasiSelect";
import RecordMonthlyExpansesWrapper from "./pages/recordMonthlyExpanses";
import LaunchPage from "./pages/launch";
import LaunchDevPage from "./pages/launchdev";
import MymediCenters from "./pages/myMeditationCenters";
import ViewMyMeditationCenter from "./pages/viewMyMeditationCenter";
import ActiveZoneCenters from "./pages/activeZoneCenters";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/ViewPendingCenterDetails/:id/:pendingId"
          element={<ViewPendingCenterDetails />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/meditationCenter" element={<ActiveCenters />} />
        <Route path="/meditationCenter/:id" element={<ActiveCenters />} />
        <Route path="/meditationPlace" element={<MeditationPlace />} />
        <Route path="/meditationPlace/:id" element={<MeditationPlace />} />
        <Route
          path="/meditationPlace/:id/:activeCenterId"
          element={<MeditationPlace />}
        />
        <Route path="/recordAttendence" element={<RecordAttendence />} />
        <Route
          path="/recordAttendence/:id/:activeCenterId"
          element={<RecordAttendence />}
        />
        <Route path="/newMeditationCenter" element={<NewMeditationCenter />} />
        <Route
          path="/newMeditationCenter/:id"
          element={<NewMeditationCenter />}
        />
        <Route
          path="/addNewMeditationCenter"
          element={<AddNewMeditationCenter />}
        />
        <Route
          path="/addNewMeditationCenter/:id"
          element={<AddNewMeditationCenter />}
        />
        <Route path="/addPropertyDetails" element={<AddPropertyDetails />} />
        <Route
          path="/addPropertyDetails/:id"
          element={<AddPropertyDetails />}
        />
        <Route path="/addWebsiteDetails" element={<AddWebsiteDetails />} />
        <Route path="/addWebsiteDetails/:id" element={<AddWebsiteDetails />} />
        <Route path="/addMeditationTiming" element={<AddMeditationTiming />} />
        <Route
          path="/addMeditationTiming/:id"
          element={<AddMeditationTiming />}
        />
        <Route path="/locationSelection" element={<LocationSelection />} />
        <Route path="/locationSelection/:id" element={<LocationSelection />} />
        <Route path="/timeSelection/:id" element={<TimeSelection />} />
        <Route path="/timeSelection" element={<TimeSelection />} />
        <Route path="/attendenceList" element={<AttendenceList />} />
        <Route
          path="/attendenceList/:id/:activeCenterId"
          element={<AttendenceList />}
        />
        <Route path="/recordMeals" element={<RecordMeals />} />
        <Route
          path="/recordMeals/:id/:activeCenterId"
          element={<RecordMeals />}
        />
        <Route path="/mealsList" element={<MealsList />} />
        <Route path="/mealsList/:id/:activeCenterId" element={<MealsList />} />
        <Route path="/help" element={<Help />} />
        <Route path="/selectedTimeList" element={<SelectedTimeList />} />
        <Route path="/coordinator" element={<Coordinator />} />
        <Route path="/coordinator/:id" element={<Coordinator />} />
        <Route path="/addCoordinator" element={<AddCoordinator />} />
        <Route path="/addCoordinator/:id" element={<AddCoordinator />} />
        <Route
          path="/recordMonthlyExpenses"
          element={<RecordMonthlyExpansesWrapper />}
        />
        <Route
          path="/recordMonthlyExpenses/:id/:activeCenterId"
          element={<RecordMonthlyExpansesWrapper />}
        />
      <Route path="/launch" element={<LaunchPage />} />
      <Route path="/launch/launchdev" element={<LaunchDevPage />} />
      <Route path="/MymediCenters" element={<MymediCenters />} />
      <Route
          path="/ViewMyMeditationCenter/:id"
          element={<ViewMyMeditationCenter />}
        />
       <Route
          path="/ZoneMeditationCenter/:id"
          element={<ActiveZoneCenters />}
        />
      </Routes>
    </Router>
  );
}

export default App;
