import React, { useEffect, useState } from 'react'
import Preview from '../components/myMeditationCenter/viewMyMeditationCenter'
import DashboardLayout from '../layout/layout'
import { useParams, useNavigate } from "react-router-dom";

const ViewMyMeditationCenter = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();

    const id = useParams()
    console.log("Id Details", id);

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id)
    }, [id, navigate])

    return (
        <DashboardLayout
            title={"Meditation Place"}
            routeTo={'/MymediCenters'}
            secondaryHeader={"Preview"}
        >
            <Preview />
        </DashboardLayout>
    )
}

export default ViewMyMeditationCenter;