import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../assets/styles/pages/preview.scss";
import Map from "../map";
import {
  approvePendingCenterCreation,
  getMeditationTimings,
  rejectPendingCenterCreation,
} from "../../../services/meditationCenter/newMeditationCenter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { Input } from "antd";
import { lStorage } from "../../../utils/storage";
import { LoadScript } from "@react-google-maps/api";
import config from "../../../assets/config";


const ViewPendingCenterDetails = () => {
  const { state } = useLocation();
  const previewData = state?.data;
  const { TextArea } = Input;

  const [disableButton, setDisableButton] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [meditationTimings, setMeditationTimings] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [rejectReason, setRejectReason] = useState();
  const [errorReject, setErrorReject] = useState();

  const navigate = useNavigate();

  const id = useParams();

  useEffect(() => {
    if (id?.id === undefined) {
      alert("No Data Found");
      navigate("/home");
    }
    setSelectedId(id);
  }, [id, navigate]);

  const mapCoordinates = {
    selectedCenterLat: previewData?.latitude,
    selectedCenterLong: previewData?.longitude,
  };

  const approvePending = (id) => {
    setDisableButton(true);
    trackApprove();
    approvePendingCenterCreation(selectedId?.id, id)
      .then((res) => {
        setDisableButton(false);
        lStorage.set("successMessage","Meditation Place Approved")
        lStorage.set("activeTab", "Approved");
     //   toast.success("Meditation Center Approved");
      //  window.scrollTo(0, 0)
        navigate(`/meditationCenter/${selectedId?.id}`);
      })
      .catch((err) => {
        setDisableButton(false);
        lStorage.set("errorMessage","Something went wrong please try again later")
        navigate(`/meditationCenter/${selectedId?.id}`);
      });
  };

  const cancelPending = (id) => {
    const reason = rejectReason || '';
    if (!reason.trim()) {
      setErrorReject('Reject reason is required.');
      return; 
    }  
    setDisableButton(true);
    trackClose();
    const payloadToSend = {
      ...(rejectReason && { remark: rejectReason }),
    };
    rejectPendingCenterCreation(payloadToSend, selectedId?.id, id)
      .then((res) => {
        setDisableButton(false);
        lStorage.set("errorMessage","Meditation Place Rejected")
       // toast.success("Meditation Center Rejected");
       // window.scrollTo(0, 0)
       lStorage.set("activeTab", "Draft");
       navigate(`/meditationCenter/${selectedId?.id}`);
      })
      .catch((err) => {
        setDisableButton(false);
        lStorage.set("errorMessage","Something went wrong please try again later")
        navigate(`/meditationCenter/${selectedId?.id}`);
      });
  };

  useEffect(() => {
    const id = previewData?.id;
    getMeditationTimings(id).then((res) => {
      const activeMeditations = res?.data?.results?.filter(item => item.active === true);
      setMeditationTimings(activeMeditations);
    });
  }, [previewData]);

  const trackClose = () => {
    setShowDelete(false);
  };

  const trackApprove = () => {
    setShowApprove(false);
  };

  const formatCenterType = (type) => {
    if (type.toLowerCase() === 'meditationhall') {
      return 'Meditation Hall';
    }else if(type.toLowerCase() === 'public'){
      return 'Rented';
    }
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
};
  return (
    <>
      <ToastContainer />
      <div className="preview-container">
        <div className="map-container">
        <LoadScript googleMapsApiKey={config.gMapKey} libraries={config.lib}>
          <Map mapCoordinates={mapCoordinates} />
          </LoadScript>
        </div>

        <div className="section-container">
          <h5 style={{ color: "#2cb4ff", marginTop: "20px" }}>
            Location Details
          </h5>
          <div className="flex-container">
            <div className="item-title">City</div>
            <div>{previewData?.city_id?.name}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Country</div>
            <div>{previewData?.country_id?.name}</div>
          </div>

          <div className="flex-container">
            <div className="item-title">House/Flat No.</div>
            <div>{previewData?.street}</div>
          </div>

          <div className="flex-container">
            <div className="item-title">Street</div>
            <div>{previewData?.street2}</div>
          </div>

          <div className="flex-container">
            <div className="item-title">Landmark</div>
            <div>{previewData?.street3}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Postal Code</div>
            <div>{previewData?.postal_code}</div>
          </div>

          <div className="flex-container">
            <div className="item-title">Sitting Capacity</div>
            <div>{previewData?.number_can_accommodate}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Stay Facility</div>
            <div>{previewData?.stay_facility}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Total Meditation Area (sq. ft)</div>
            <div>{previewData && previewData.total_meditation_area !== 0 ? previewData.total_meditation_area : ""}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Meditation Session Type</div>
            <div>{previewData?.session_type}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Location Type</div>
            <div>{formatCenterType(previewData?.center_type)}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Property Type</div>
            <div>{previewData?.property_type}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Type Of Ownership</div>
            <div>
                {previewData?.ownership === 'own' 
                  ? 'Mission Owned' 
                  : previewData?.ownership === 'lease' 
                    ? 'Long Lease' 
                    : previewData?.ownership}
            </div>
          </div>
          <div className="flex-container">
            <div className="item-title">Active Since</div>
            <div>{formatDate(previewData?.active_since)}</div>
          </div>
        </div>

        <div className="section-container">
          <h5 style={{ color: "#2cb4ff" }}>Contact Details</h5>
          <div className="flex-container">
            <div className="item-title">Place Name</div>
            <div>{previewData?.name}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Contact Person Name</div>
            <div>{previewData?.contact_person_name}</div>
          </div>

          <div className="flex-container">
            <div className="item-title">Mobile Number</div>
            <div>{previewData?.mobile}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Phone Number</div>
            <div>{previewData?.phone}</div>
          </div>
         
          <div className="flex-container">
            <div className="item-title">About</div>
            <div>{previewData?.web_about}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Directions</div>
            <div>{previewData?.directions}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Facilities</div>
            <div>{previewData?.facilities}</div>
          </div>
            <div className="flex-container">
            <div className="item-title">Display to public</div>
            <div>{previewData?.public_display === true ? "Yes" : "No"}</div>
          </div>
          <div className="flex-container">
            <div className="item-title">Email</div>
          </div>
          <div className="flex-container">
            <div>{previewData?.email}</div>
          </div>
        </div>

        <div className="section-container">
          <h5 style={{ color: "#2cb4ff" }}>Meditation Timings</h5>
          <div
            className="meditation-time-flex-container"
            style={{ marginBottom: "5px" }}
          >
            <div className="item-title">Day Of Meditation</div>
            <div className="item-title">Meditation Time</div>
         
          </div>
          {meditationTimings.map((item) => {
            const meditationTime = moment(
              new moment(
                moment(new Date()).format("YYYY:MM:DD") +
                  "T" +
                  item?.meditation_time,
                "YYYY-MM-DDTHH:mm"
              ).utc()?._d
            ).format("HH:mm A");

            return (
              <>
                <div className="meditation-time-flex-container">
                  <div style={{ textTransform: "capitalize" }}>
                    {item?.day_of_week}
                  </div>
                  <div>{meditationTime}</div>                
                </div>
                {item?.special_notes && (
                <div className="meditation-time-flex-container">
                <b>Special Note:</b> {item?.special_notes}
                </div>
                )}
              </>
            );
          })}
        </div>

        <div className="other-details-container ">
          <div className="section-container">
            <h5 style={{ color: "#2cb4ff" }}>Other Details</h5>
            {/* <div className="flex-container">
              <div className="item-title">Id</div>
              <div>{previewData?.id}</div>
            </div>
            <div className="flex-container">
              <div className="item-title">SRCM group Id</div>
              <div>{previewData?.srcm_group_id?.id}</div>
            </div> */}
            <div className="flex-container">
              <div className="item-title">SRCM Center Name</div>
              <div>{previewData?.srcm_group_id.name}</div>
            </div>
          </div>
          </div>
          <div className="other-details-container ">
          <div className="draft-cancel-approve-container-preview">
            <div title="Approve">
              <span style={{ marginRight: "10px" }}>Approve</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-check-circle"
                viewBox="0 0 16 16"
                style={{
                  color: "#69f493",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowApprove(true);
                }}
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
            </div>
            <div title="Reject">
              <span style={{ marginRight: "10px" }}>Reject</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
                style={{ color: "#f24d40", cursor: "pointer" }}
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} centered >
        <Modal.Header>
          <Modal.Title>Reject Meditation Place</Modal.Title>
          <Button variant="close" onClick={trackClose} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to Reject ?</p>
          </div>
          <div>
            <p className="impul-label">Reason<span style={{ color: "red"}}>*</span></p>
            <TextArea onChange={(e) => {
                setRejectReason(e.target.value);
                setErrorReject(''); 
              }} />
              {errorReject && <p style={{ color: 'red' }}>{errorReject}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => cancelPending(previewData?.id)}
            disabled={disableButton}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApprove} centered>
        <Modal.Header>
          <Modal.Title>Approve Meditation Place</Modal.Title>
          <Button variant="close" onClick={trackApprove} aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="impul-label">Are you sure, you want to Approve ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackApprove}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => approvePending(previewData?.id)}
            disabled={disableButton}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewPendingCenterDetails;
