import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import AddWebsiteDetails from '../components/meditationCenter/newMeditationCenter/addWebsiteDetails'
import DashboardLayout from '../layout/layout'

const WebDetails = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();

    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id?.id)
    }, [id, navigate])

    return (
        <DashboardLayout title={"Website Details"} routeTo={`/addPropertyDetails/${selectedId}`}>
            <AddWebsiteDetails />
        </DashboardLayout>
    )
}

export default WebDetails