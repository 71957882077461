import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/styles/pages/timeSelection.scss";
import { lStorage } from "../../../../utils/storage";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import {
  meditationTimeCreation,
  meditationTimeUpdation,
  getMeditationTimings,
  updateMeditationCenter,
} from "../../../../services/meditationCenter/newMeditationCenter";
import Spinner from "../../../spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const TimeSelection = () => {
  const { state } = useLocation();
  const draftData = state?.data;
  const isActiveCenter = lStorage.get("isActiveCenter");

  const navigate = useNavigate();

  const [selectedDay, setSelectedDay] = useState("sun");
  const [isDone, setIsDone] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [isDraft, setIsDraft] = useState(false);
  const [loading, setLoading] = useState(false);
 // const [IsActiveCenter, setIsActiveCenter] = useState(false);
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [specialNote, setSpecialNote] = useState();
  const [value, setValue] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const draftFormData = lStorage.get("formData")
  const [disableButton, setDisableButton] = useState(false);

  const listDate = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const setLocalStorage = (key, value) => {
    const result = value?.map((d) => {
      return { id: Math.floor(Math.random() * Date.now()), ...d };
    });
    localStorage.setItem(key, `${JSON.stringify(result)}`);
    navigate("/selectedTimeList", { state: { data: draftData } });
  };

  const getLocalStorage = (key) => {
    return lStorage.get(key);
  };

  useEffect(() => {
    if(state?.draftTiming?.day_of_week){
      setSelectedDay(state?.draftTiming?.day_of_week)
    }
    if (isDone === true) {
      setLocalStorage("medetationDateTime", [getUpdatedDayTime()]);
      if(!isDraft ){
      setLocalStorage("tempMedetationDateTime", [getUpdatedDayTime()]);
      }
    }
    // eslint-disable-next-line
  }, [isDone]);

  // const centerId = lStorage.get("center-id")
  //const isDraftEdit = lStorage.get("isDraftEdit");

  useEffect(() => {
    const existingTimeList = lStorage.get("medetationDateTime");
    if (existingTimeList?.length === 0) {
      //setIsActiveCenter(true);
    }
  }, []);

  const saveEdit = () => {
    setDisableButton(true);
  if (isDraft === true || isActiveCenter === true) {
    if(moment(value).format("HH")==='00' && moment(value).format("mm")==='00'){
      setLoading(false);
      lStorage.set("errorMessage","Please select valid time");
      navigate("/selectedTimeList");
    }else {


    if (!isNaN(selectedId) && selectedId>0 ) {
      setLoading(false);
      meditationTimeUpdation(getUpdatedDayTime(), getUpdatedDayTime()?.id)
        .then((res) => {
          //toast.success("Record Updated");
          lStorage.set("successMessage","Record Update");
          const selectedCenterid = selectedActiveCenterId
            ? selectedActiveCenterId
            : lStorage.get("draftId");

            
   //    const selectedCenterid = draftFormData?.id;
      const draftCenterId = lStorage.get("center-id")
      const payloadToSend = {
        contact_person_name: draftFormData?.contact_person_name ? draftFormData?.contact_person_name : null,

      };
        updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
          .then((res) => {
            if (res) {
         //     toast.success("Details Updated");
            }
          })
          .catch((err) => {
         //   setDisableButton(false);
           // toast.error("Something went wrong please try again later");
          });

          getMeditationTimings(selectedCenterid)
            .then((res) => {
              lStorage.set("medetationDateTime", res?.data?.results);
              navigate("/selectedTimeList");
            })
            .catch((err) => {
              toast.error("Something went wrong please try again later");
            });
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
      
  lStorage.set("errorMessage","Meditation Timing already exist");
          const selectedCenterid = selectedActiveCenterId
            ? selectedActiveCenterId
            : lStorage.get("draftId");
          getMeditationTimings(selectedCenterid)
            .then((res) => {
              // const activeMeditations = res?.data?.results?.filter(item => item.active === true);
              lStorage.set("medetationDateTime", res?.data?.results);
              navigate("/selectedTimeList");
            })
            .catch((err) => {
              toast.error("Something went wrong please try again later");
            });

        });
    } else {
      meditationTimeCreation(getUpdatedDayTime())
        .then((res) => {
        //  toast.success("Meditation Time Added");
          lStorage.set("successMessage","Meditation Time Added");
          const selectedCenterid = selectedActiveCenterId
            ? selectedActiveCenterId
            : lStorage.get("draftId");

            const draftCenterId = lStorage.get("center-id")
            const payloadToSend = {
              contact_person_name: draftFormData?.contact_person_name ? draftFormData?.contact_person_name : null,
      
            };
              updateMeditationCenter(payloadToSend, selectedCenterid, draftCenterId)
                .then((res) => {
                  if (res) {
               //     toast.success("Details Updated");
                  }
                })
                .catch((err) => {
               //   setDisableButton(false);
                 // toast.error("Something went wrong please try again later");
                });

          getMeditationTimings(selectedCenterid)
            .then((res) => {
              // const activeMeditations = res?.data?.results?.filter(item => item.active === true);
              lStorage.set("medetationDateTime", res?.data?.results);
              navigate("/selectedTimeList");
            })
            .catch((err) => {
          //    toast.error("Meditation Timing Already exist");
        //  lStorage.set("errorMessage","Meditation Timing already exist");
            });

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          lStorage.set("errorMessage","Meditation Timing already exist");
        //  navigate("/selectedTimeList");
        //  toast.error("Meditation Timing Already exist");
       
          const selectedCenterid = selectedActiveCenterId
            ? selectedActiveCenterId
            : lStorage.get("draftId");
          getMeditationTimings(selectedCenterid)
            .then((res) => {
              lStorage.set("medetationDateTime", res?.data?.results);
              navigate("/selectedTimeList");
            })
            .catch((err) => {
              toast.error("Something went wrong please try again later");
            });
        });
    }
  }
  }

  var match = 0
  getLocalStorage("medetationDateTime").forEach((employee, index) => {
   // if(employee.day_of_week===selectedDay ){
   if(employee.id !== selectedId && employee.day_of_week===selectedDay && employee.meditation_time.substring(0, 5)===moment(value).format("HH:mm")){
       match = 1;
    }
  });
 
  if (isDraft === true || isActiveCenter === true) {
    match = 1;
   // navigate("/selectedTimeList");
  }else{

    if(match ===1 ){
      setLoading(false);
      lStorage.set("errorMessage","Meditation Timing already exist");
    
    }
    if(moment(value).format("HH")==='00' && moment(value).format("mm")==='00'){
      setLoading(false);
      lStorage.set("errorMessage","Please select valid time");
      match = 1;
    }
    
  }
  if(match ===0 ){
      setIsDone(true);
  setLocalStorage(
    "medetationDateTime",
    selectedId
      ? [
          ...getLocalStorage("medetationDateTime").filter(
            (value) => value.id !== selectedId
          ),
          getUpdatedDayTime(),
        ]
      : [...getLocalStorage("medetationDateTime"), getUpdatedDayTime()]
  );
      }
  if(!isDraft ){
        setLocalStorage("tempMedetationDateTime",lStorage.get("medetationDateTime"));
      }
  

  setSelectedId();
  };

  const getUpdatedDayTime = () => {   
    const appendIdObj = selectedId ? { id: selectedId } : {};
    return {
      ...appendIdObj,
      meditation_time: moment(value).format("HH:mm")+":00"|| "",
      day_of_week: selectedDay || "",
      meditation_center_id:
        draftDataId || selectedActiveCenterId
          ? draftDataId || selectedActiveCenterId
          : "",
      special_notes: specialNote=== undefined ? state?.draftTiming?.special_notes: specialNote ? specialNote : null ,
      active: state?.draftTiming?.active,
    };
  };

  const id = useParams();

  useEffect(() => {
    setSelectedId(Number(id?.id?.split("=")?.[1]));
  }, [id]);

  const draftDataId = lStorage.get("draftId");
  const selectedActiveCenterId = lStorage.get("selectedActiveCenterId");

  useEffect(() => {
    if (draftDataId || selectedActiveCenterId) {
      setIsDraft(true);
    }
  }, [draftDataId, selectedActiveCenterId]);

  useEffect(() => {
    if (
      isTimeSelected === false &&
      (state?.draftTiming?.meditation_time === undefined || null)
    ) {
      setValue(moment(new Date())?._i);
      var hour = moment(new Date()).format("HH");
      var minute = moment(new Date()).format("mm");
      if(minute>=56){
        minute = 55;
      }
      minute = Math.round(minute / 5) * 5;
      hour = hour+":"+minute;
      setValue(
        new moment(
          moment(new Date()).format("YYYY:MM:DD") +
            "T" +
            ""+hour+"",
          "YYYY-MM-DDThh:mm"
        ).utc()?._d
      );
    }
    if (
      isTimeSelected === false &&
      (state?.draftTiming?.meditation_time !== undefined || null)
    ) {
      setValue(
        new moment(
          moment(new Date()).format("YYYY:MM:DD") +
            "T" +
            state?.draftTiming?.meditation_time,
          "YYYY-MM-DDThh:mm"
        ).utc()?._d
      );
    }
  }, [isTimeSelected, state]);

  return (
    <>
      <ToastContainer />
      {loading && <Spinner />}
      {!loading && (
        <>
          <div className="time-selection-main-container">
            <div className="day-selection-outer-container">
              <div className="day-selection-inner-container">
                {listDate.map((day) => {
                  return (
                    <div className="day-container">
                      <div
                        className={`day-div ${
                          selectedDay === day && "selected-day"
                        }`}
                        onClick={() => setSelectedDay(day)}
                      >
                        {day}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
            
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticTimePicker
                  ampm
                  orientation="portrait"
                  minutesStep="5"
                  // value={state?.draftTiming?.meditation_time ? new moment(moment(new Date()).format("YYYY:MM:DD" + "T" + state?.draftTiming?.meditation_time), "YYYY-MM-DDThh:mm").utc() : value}
                  value={
                    state?.draftTiming?.meditation_time &&
                    isTimeSelected === false
                      ? new moment(
                          moment(new Date()).format("YYYY:MM:DD") +
                            "T" +
                            state?.draftTiming?.meditation_time,
                          "YYYY-MM-DDThh:mm"
                        ).utc()
                      : value
                  }
                  onChange={(newValue) => {
                    setIsTimeSelected(true);
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>

            <div style={{ padding: "0px 20px" }}>
              <label
                htmlFor="test"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <b>SPECIAL NOTE </b>
              </label>
              <input
                type="text"
                className="mb-4"
                onChange={(ev) => {setSpecialNote(ev.target.value)}}
                defaultValue={state?.draftTiming?.special_notes}
                style={{ height:"40px",border: "1px solid #d9d9d9", width: "100%" }}
              />
            </div>

         
           
          </div>
          <button
                className="selected-time-list-form-save-btn"
                style={{ border: "none" }}
                type="submit"
                onClick={() => saveEdit()}
                disabled={disableButton}
              >
                  {isDraft ? "Save" : "Done"}
              </button>

        </>
      )}
    </>
  );
};

export default TimeSelection;
