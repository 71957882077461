import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import AttendanceList from '../components/meditationCenter/meditationPlace/recordAttendence/attendenceList'
import DashboardLayout from '../layout/layout'

const AttendanceListWrapper = () => {
    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState();
    const [isReload, setIsReload] = useState(false)


    const id = useParams()

    useEffect(() => {
        if (id?.id === undefined) {
            alert('No Data Found');
            navigate('/home')
        }
        setSelectedId(id)
    }, [id, navigate])

    useEffect(() => {
        if (isEdit === true) {
            setTitle("Edit Attendance");
            setIsReload(true)
        } else {
            setTitle("Attendance List");
        }
    }, [isEdit])

    return (
        <DashboardLayout title={title} routeTo={`/recordAttendence/${selectedId?.id}/${selectedId?.activeCenterId}`} isReload={isReload}>
            <AttendanceList setIsEdit={setIsEdit} />
        </DashboardLayout>
    )
}

export default AttendanceListWrapper