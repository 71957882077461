import React, { useEffect ,useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { lStorage } from "../utils/storage";
import {getMeDetails} from "../services/meditationCenter/centerList"
import Spinner from "../components/spinner";
import "react-toastify/dist/ReactToastify.css";
import { isLoginAuth } from "../utils/login";

const LaunchDev = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect( () => {
    if (searchParams.get("token")) {
      lStorage.set("authToken", `${searchParams.get("token")}`);
      setLoading(true);
       getMeDetails().then((res) => {
        const user = {};
        if (res?.data?.results.length > 0) {
          const myInfo = res?.data?.results[0];
          user.srcmToken = searchParams.get("token");
          user.name = myInfo.name;
          user.email = myInfo.user_email;
          user.loginUser = myInfo;
          lStorage.set("authInfo", user);
          lStorage.set("heartsApp", 1);
          setLoading(false);
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        }
      }).catch((err) => {
        toast.error(err?.response?.data?.detail);
      });
    } else if (isLoginAuth()) {
      navigate("/home");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <ToastContainer />
     {loading && <Spinner />}
      {
        <div className="lds-roller-wrapper">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      }
    </>
  );
};

export default LaunchDev;
