import ax from '../../index';

const recordAttendeceListService = {
  getRecordMealsList: (page, page_size, selectedId) => ax.get(`v2/meditation-centers/meal-served/?meditation_center_id=${selectedId}&page=${page}&page_size=${page_size}`),

  postMealsServedData: (payload) => ax.post(`v2/meditation-centers/meal-served/`, payload ),

  editMealsServed: (payload , selectedId )=> ax.patch(`v2/meditation-centers/meal-served/${selectedId}/`, payload )
};

export const { getRecordMealsList,postMealsServedData , editMealsServed} = recordAttendeceListService;
